import { Injectable } from "@angular/core";
import { FuseConfigService } from "@fuse/services/config";
import { CurrentUser } from "@gruuls-core/models/user/current-user";
import { GruulsAuthService } from "@gruuls-fe/services/gruuls-auth.service";

interface Resource {
  id: string;
  companyId: string;
  ownerId?: string;
  type: string;
  attributes?: Record<string, any>;
}

type ActionType = 'create' | 'read' | 'update' | 'delete';

interface PolicyRule {
  resource: string;
  action: ActionType;
  conditions: (user: CurrentUser, resource: Resource) => boolean;
}

@Injectable()
export class GruulsAbacService {
  private policies: PolicyRule[] = [];

  constructor(
    private authService: GruulsAuthService,
    private fuseConfigService: FuseConfigService
  ) {
    this.initializePolicies();
  }

  private initializePolicies(): void {
    this.fuseConfigService.config$.subscribe({
      next: (config) => {
        this.resetPolicies();
        config.abacPolicies?.forEach(policy => {
          this.addPolicy(policy);
        });      
      }
    });
  }

  addPolicy(policy: PolicyRule): void {
    this.policies.push(policy);
  }

  hasPermission(
    action: ActionType,
    resource: Resource
  ): boolean {
    const currentUser: CurrentUser = this.authService.getCurrentLoggedUser();
    const matchingPolicies = this.policies.filter(policy =>
      (policy.resource === '*' || policy.resource === resource.type) &&
      policy.action === action
    );

    // Check if any matching policy allows the action
    return matchingPolicies.some(policy => policy.conditions(currentUser, resource));
  }

  private resetPolicies(): void {
    this.policies = [];
  }
}