<ng-container *ngIf="!sent; else formSent">
    <div *ngIf="!isLoadingError; else loadingerror" class="flex flex-col flex-auto min-w-0">

        <!-- Header -->
        <div class="flex flex-col shadow bg-card">

            <!-- Cover image -->
            <div>
                <img class="h-40 lg:h-80 object-cover"
                    src="https://get.beautycians.datacream.com/resources/images/dna.jpg" alt="Cover image">
            </div>

            <!-- User info -->
            <div class="flex flex-col flex-0 md:flex-row items-center max-w-5xl w-full mx-auto px-8 bg-card p-4">

                <div class="-mt-26 lg:-mt-22 rounded-full ">
                    <img class="w-32 h-32 bg-white ring-primary-50 rounded-full ring-4 ring-bg-card"
                        src="assets/images/logo/logo.svg" alt="User avatar">
                </div>

                <div class="flex flex-col items-center md:items-start mt-4 md:mt-0 md:ml-8">
                    <div class="text-lg font-bold leading-none">{{translateStrings.medicalHistory.welcomeToSurvey}}
                    </div>
                </div>

            </div>

        </div>
        <!-- Loader -->
        <div class="inset-x-0" *ngIf="isLoading">
            <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
        </div>

        <!-- Main -->
        <div class="flex flex-auto justify-center w-full max-w-5xl mx-auto p-6 sm:p-8">

            <!-- Column -->
            <ng-container *ngIf="clientId; else noSession">
                <div class="flex-auto pt-6 sm:px-4 sm:pb-4">

                    <!-- TAB CONTENT GOES HERE -->
                    <div class="flex-col sm:flex-row md:flex-col flex-auto justify-between">
                        <form id="anamnesiForm" [formGroup]="anamnesiFormGroup" (ngSubmit)="submitAnamnesi($event)"
                            #anamnesiForm="ngForm" *ngIf="anamnesiFormGroup">
                            <div class="flex flex-col gt-xs:flex-row py-4 md:p-10">
                                <ng-container *ngFor="let field of newSurvey" [ngSwitch]="field.type.toLowerCase()">

                                    <ng-container *ngSwitchCase="'string'">
                                        <mat-form-field class="flex-auto gt-xs:pr-3 pb-4"
                                            *ngIf="!anamnesiFormGroup.get(field.key).disabled">
                                            <mat-label
                                                class="text-black">{{translateAnamnesiQuestions(field.key)}}</mat-label>
                                            <input class="text-black" matInput formControlName="{{field.key}}">
                                        </mat-form-field>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'long'">
                                        <mat-form-field class="flex-auto gt-xs:pr-3 pb-4"
                                            *ngIf="!anamnesiFormGroup.get(field.key).disabled">
                                            <mat-label
                                                class="text-black">{{translateAnamnesiQuestions(field.key)}}</mat-label>
                                            <input class="text-black" matInput formControlName="{{field.key}}">
                                        </mat-form-field>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'double'">
                                        <mat-form-field class="flex-auto gt-xs:pr-3 pb-4"
                                            *ngIf="!anamnesiFormGroup.get(field.key).disabled">
                                            <mat-label
                                                class="text-black">{{translateAnamnesiQuestions(field.key)}}</mat-label>
                                            <input class="text-black" matInput formControlName="{{field.key}}">
                                        </mat-form-field>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'enum'">
                                        <mat-form-field class="flex-auto gt-xs:pl-3 pb-4"
                                            *ngIf="!anamnesiFormGroup.get(field.key).disabled">
                                            <mat-label
                                                class="text-black">{{translateAnamnesiQuestions(field.key)}}</mat-label>
                                            <mat-select formControlName="{{field.key}}">
                                                <mat-option class="text-black" *ngFor="let option of field.values"
                                                    [value]="option">{{translateAnamnesiAnswers(field.key,
                                                    option)}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'bool'">
                                        <div class="mat-form-field gt-xs:pr-3 mb-4 flex flex-col gap-1 pb-4"
                                            *ngIf="!anamnesiFormGroup.get(field.key).disabled">
                                            <mat-label
                                                class="font-medium text-black mb-1">{{translateAnamnesiQuestions(field.key)}}</mat-label>
                                            <mat-radio-group class="flex flex-col gap-3 gt-xs:pl-3"
                                                formControlName="{{field.key}}">
                                                <mat-radio-button class="text-black" [value]="true">Sì
                                                </mat-radio-button>
                                                <mat-radio-button class="text-black" [value]="false">No
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </ng-container>

                                </ng-container>
                            </div>

                            <!-- Modal footer -->
                            <div
                                class="flex flex-row-reverse md:px-10 py-6 space-x-2 border-gray-200 rounded-b dark:border-gray-600">
                                <button type="submit" mat-flat-button [color]="'primary'" class="rounded-lg"
                                    [disabled]="!anamnesiFormGroup.valid">
                                    <span *ngIf="!specificLoading['saveAnamnesi']">{{translateStrings.generic.send}}</span>
                                    <mat-spinner *ngIf="specificLoading['saveAnamnesi']" diameter="20"></mat-spinner>
                                </button>
                            </div>

                        </form>
                    </div>


                </div>
            </ng-container>
            <ng-template #noSession>
                {{translateStrings.generic.noSession}}
            </ng-template>
        </div>
    </div>
</ng-container>

<ng-template #formSent>
    <div class="flex flex-col flex-auto items-center sm:justify-center min-w-0 mt-8">
        <div class="w-full sm:w-auto py-8 px-4 sm:p-12 sm:rounded-2xl sm:shadow sm:bg-card  bg-primary-50">
            <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
                <!-- Logo -->
                <div class="w-12">
                    <img src="assets/images/logo/logo.svg">
                </div>

                <!-- Title -->
                <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">
                    {{translateStrings.generic.thanks}}!</div>
                <div class="mt-0.5">
                    {{translateStrings.medicalHistory.thanksForFilling}}
                </div>

            </div>
        </div>
    </div>

</ng-template>
<ng-template #loadingerror>
    <img src="/assets/images/ui/beautycians/error_503.jpg" alt="Error" class="object-contain h-48 w-96 mx-auto">
    <div class="text-2xl font-semibold tracking-tight text-center w-4/6 sm:w-full mx-auto">
        {{translateStrings.generic.error503}}</div>
</ng-template>