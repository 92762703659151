import { Organization } from "./organization.type";

export interface Person
{
    id?: string;
    personId?: string;
    avatar?: string | null;
    sex?: string;
    background?: string | null;
    firstName?: string;
    lastName?: string;
    birthdate?: string;
    mobilePhoneNumber?: string;
    age?: number;
    address?: string | null;
    notes?: string | null;
    roles?: AttachedRole[];
    account?: {
        email?: string;
        username?: string;
        password?: string;
        passwordVerify?: string;
    },
    privacyConsent?: {
        versione: string;
        consenso: boolean;
    }
}

export interface AttachedRole {
  hasRole: any[];
  inOrganization: Organization;
}

export const ACCOUNT_ASSEMBLE_SM = {
  firstName: true,
  lastName: true,
}

export const ACCOUNT_ASSEMBLE = {
  ...ACCOUNT_ASSEMBLE_SM,
  _referenceOrganizationId: true,
  birthdate: true,
  account: true,
  roles: true,
  address: true,
  sex: true,
  pictureUrl: true
}

export const PERSON_SM_ASSEMBLE = {
  firstName: true,
  lastName: true,
  address: true,
  sex: true,
  birthdate: true
}

export const PERSON_MD_ASSEMBLE = {
  ...PERSON_SM_ASSEMBLE,
  _creationTime: true,
  pictureUrl: true,
  roles: true
}
export const PERSON_XL_ASSEMBLE = {
  ...PERSON_MD_ASSEMBLE
}