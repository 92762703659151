import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, tap, map, throwError, take, switchMap, filter, mergeMap, from, concatMap, catchError, generate } from 'rxjs';
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { GruulsAngularHttpProxyService } from '@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { GruulsAuthService } from '@gruuls-fe/services/gruuls-auth.service';
import { Person } from '@gruuls-core/types/person.type';
import { BEAUTY_ACCOUNT_ASSEMBLE } from 'app/beautycians/utils/assemble';
import { Organization } from '../../../../@gruuls-core/types/organization.type';
import { OWNERSHIP_ROLES } from 'app/beautycians/utils/constants';
import { GruulsAngularBrowserCacheService } from '@gruuls-fe/services/gruuls-angular-browser-cache-service';

const PAGES_TO_RETRIEVE = 12;
const CACHE_TTL_IN_MIN = 30;

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  private _accounts: BehaviorSubject<Person[] | null> = new BehaviorSubject(null);
  private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);
  private _lastSubscriptionTime: number = null;
  private _isCompleted$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // private _localCache: GruulsAngularBrowserCacheService;
  /**
   * Constructor
   */
  constructor(
    private _httpClient: GruulsAngularHttpProxyService,
    private _authService: GruulsAuthService, 
    private _browserCache: GruulsAngularBrowserCacheService
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get accounts$(): Observable<Person[]> {
    return this._accounts.asObservable();
  }

  get isCompleted$(): Observable<boolean> {
    return this._isCompleted$.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  updateObjectCache() {
    if (!this._browserCache)
      return;
  
    const cacheObject = this._accounts.getValue();
    this._browserCache.set("owners", JSON.stringify(cacheObject), CACHE_TTL_IN_MIN * 60);
  }

  retrieveObjectCache() {
    if (!this._browserCache)
      return;

    const cacheObject = JSON.parse(this._browserCache.get("owners"));
    if (cacheObject) {
      this._accounts.next(cacheObject);
      this._isCompleted$.next(true);
      return true;
    }
    return false;
  }

  /**
   * Get Account
   */
  getAccounts(page: number = null): Observable<Person[]> {
    return this._apiCaller.getPersonsByRoleTemplateName(OWNERSHIP_ROLES, { 'not__referenceOrganizationId': this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId }, BEAUTY_ACCOUNT_ASSEMBLE, page).pipe(
      mergeMap((accountsResponse) => {
        if ('error' in accountsResponse && accountsResponse['error'] === true) {
          // TODO: this is a trick for performance issue
          this.updateObjectCache();
          this._isCompleted$.next(true);
          return throwError(() => new Error('No more pages to retrieve'));
        }
        else return of(accountsResponse);
      }),
      map((accountsResponse) => accountsResponse.hits),
      tap((accounts: Person[]) => {
        this._lastSubscriptionTime = Date.now();
        if (page && page > 0) {
          this._accounts.next(this._accounts.getValue() ? [...this._accounts.getValue(), ...accounts] : accounts);
        } else {
          this._accounts.next(accounts);
        }
      })
    )
  }

  refreshAccounts(page: number = null, query: string = ""): Observable<Person[]> {
    const now = Date.now();
    const UPDATE_DELTA = CACHE_TTL_IN_MIN * 60 * 1000; // 15 min.

    if (this.retrieveObjectCache()) {
      console.log('Cache hit on accounts!');
      return of(this._accounts.getValue());
    }
    if (this._lastSubscriptionTime == null || (now - this._lastSubscriptionTime) > UPDATE_DELTA) {
      this._lastSubscriptionTime = Date.now();
      return generate({
        initialState: 0,
        condition: x => x < PAGES_TO_RETRIEVE,
        iterate: x => x + 1
      }).pipe(
        concatMap((page) => this.getAccounts(page)),
      );
    } else {
      return of(this._accounts.getValue());
      // return this.filterAccounts(query);
    }
  }

  /**
   * Create Account
   */
  createAccount(accountDetails: Person, organization: Organization, roleTemplateId: string): Observable<any> {
    return this.accounts$.pipe(
      take(1),
      switchMap(accounts => this._apiCaller.createAccount(
        organization.organizationId,
        {
          firstName: accountDetails.firstName,
          lastName: accountDetails.lastName,
        },
        accountDetails.account.email,
        roleTemplateId,
        accountDetails.account.password
      ).pipe(
        map((newAccount) => {
          // accounts.push(account);
          this._accounts.next([newAccount, ...accounts]);
          this.updateObjectCache();
          return newAccount;
        }))
      )
    );
  }


  /**
   * Update client
   *
   * @param id
   * @param client
   */
  updateAccount(accountDetails: Person): Observable<Person> {
    if (!accountDetails.personId)
      throwError(() => 'Could not found personId!');

    let accountDetailsToUpdate = Object.assign({}, accountDetails);
    delete accountDetailsToUpdate.roles; //TODO: verify better
    delete accountDetailsToUpdate.account.email;
    delete accountDetailsToUpdate.account.username;

    return this.accounts$.pipe(
      take(1),
      switchMap(accounts => this._apiCaller.updatePerson(accountDetails.personId, accountDetailsToUpdate).pipe(
        map((updatedClient) => {

          // Find the index of the updated client
          const index = accounts.findIndex(item => item.personId === updatedClient.personId);

          // Update the client
          accounts[index] = updatedClient;

          // Update the clients
          this._accounts.next(accounts);
          this.updateObjectCache();

          // Return the updated client
          return updatedClient;
        }),
      ))
    );
  }

  /**
   * Delete the client
   *
   * @param id
   */
  deleteAccount(id: string): Observable<boolean> {
    return this.accounts$.pipe(
      take(1),
      switchMap(clients => this._apiCaller.deletePerson(id).pipe(
        map((isDeleted: boolean) => {

          // Find the index of the deleted client
          const index = clients.findIndex(item => item.personId === id);

          // Delete the client
          clients.splice(index, 1);

          // Update the clients
          this._accounts.next(clients);
          this.updateObjectCache();

          // Return the deleted status
          return isDeleted;
        })
      ))
    );
  }

}
