import '../order.types';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Inject, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, ViewEncapsulation } from '@angular/core';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { GruulsAngularHttpProxyService } from '@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { GruulsAngularTranslateService } from '@gruuls-fe/services/gruuls-angular-translate.service';
import { ORDERSTATUS, Order, OrderInfo } from '../order.types';
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { GruulsAuthService } from '@gruuls-fe/services/gruuls-auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, tap } from 'rxjs';
import _ from 'lodash';
import { ProductsService } from '../../products/product.service';
import { GruulsAngularCartService } from '@gruuls-fe/services/gruuls-angular-cart.service';
import { Product } from '../../products/product.types';

@Component({
    selector: 'order-table',
    templateUrl: './order-table.component.html',
    styles: [
        /* language=SCSS */
        // TODO: set correct width
        `
            .inventory-grid {
                grid-template-columns: 48px auto 112px 72px 72px 72px;

                @screen sm {
                    grid-template-columns: 48px auto 112px 112px;
                }

                @screen md {
                    grid-template-columns: 48px auto 120px 120px 72px 72px 72px;
                }

                @screen lg {
                    grid-template-columns: 48px auto 200px 200px 72px 72px 72px;
                }
            }
        `
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderTableComponent implements OnInit {
    @Input('order') order: Order;
    @Input('canChange') canChange: boolean = false;
    @Input('isAdmin') isAdmin: boolean = false;
    @Output('onOrderChange') onOrderChange: EventEmitter<Order> = new EventEmitter<Order>();

    discounts: number[] = [0, 0.1, 0.2, 0.3, 1];
    translateStrings: any = { generic: {}, order: {}, user: {}, orderDetail: {}, product: {} };
    keyValueProducts: any = {};
    products: Product[] = [];
    quantityOptions: number[] = [];
    private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);

    constructor(
        private _translate: GruulsAngularTranslateService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _httpClient: GruulsAngularHttpProxyService,
        private _authService: GruulsAuthService,
        private _snackBar: MatSnackBar,
        private _productsService: ProductsService,
        private _fuseConfirmationService: FuseConfirmationService,
        private _cartService: GruulsAngularCartService,
    ) {
    }

    ngOnInit(): void {
        this.quantityOptions = Array.from({ length: 20 }, (_, i) => i + 1).concat([25,30,35,40,45,50]);

        this._productsService.allProducts$.pipe(
            tap((products) => {
                // Map each product into this.products with the key as the productId
                if (products != null) {
                    products.forEach((product) => {
                        this.products = products;
                        this.keyValueProducts[product.productId] = product;
                    });
                    if (!this.order.total)
                        this.updateOrderTotal();
                    this._changeDetectorRef.markForCheck();
                }
            })
        ).subscribe();

        this._productsService.getProducts().subscribe();

        const genericTranslations = ['autoSaveOK'];
        genericTranslations.forEach((translation) => {
            this.translateStrings['generic'][translation] = this._translate.translate('generic.' + translation);
        });

        const orderTranslations = ['plural', 'singular', 'no', 'search', 'addNew', 'copyToNew', 'noSearchResults', 'total', 'shippingTo', 'errorWhilePlacing', 'confirmCancel', 'reintroduce', 'addNewProduct'];
        orderTranslations.forEach((translation) => {
            this.translateStrings['order'][translation] = this._translate.translate('orders.' + translation);
        });

        const orderDetailsTranslations = ['no', 'gotobuy', 'search', 'addNew', 'send', 'noSearchResults', 'confirm', 'proceed', 'removedFromCart'];
        orderDetailsTranslations.forEach((translation) => {
            this.translateStrings['orderDetail'][translation] = this._translate.translate('orderDetails.' + translation);
        });

        const productsTranslations = ['name', 'unitPrice', 'price', 'discount', 'quantity', 'category', 'dimension', 'websiteRef', 'imgUrl', 'quantity', 'generic'];
        productsTranslations.forEach((translation) => {
            this.translateStrings['product'][translation] = this._translate.translate('products.' + translation);
        });
    }

    updateOrder(orderItem: any): void {
        if (!this.canChange)
            return;

        const orderToUpdate: Order = _.cloneDeep(this.order);
        this.onOrderChange.emit(orderToUpdate);
        this.updateOrderTotal();
    }

    updateOrderTotal(): void {
        this.order.total = 0;
        this.order.cart.products.forEach((product, index) => {
            this.order.total += product.quantity * this.keyValueProducts[product.productId].price * (1 - (product.discount ?? 0));
        });
        this._changeDetectorRef.markForCheck();
    }

    getProductById(productId: string): any {
        return this.keyValueProducts.find((product) => product.productId == productId);
    }

    addProductAtTheEnd(): void {
        if (this.keyValueProducts) {
            this.order.cart.products.push({
                productId: this.products[0]?.productId,
                quantity: 1,
                discount: 0,
                recentlyAdded: true,
                isGift: false,
                delivered: []
            });
            this.updateOrder(null);
        }
    }


}