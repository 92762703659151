export class AppointmentStatus {
  public static getStatusColor(status: string | null): { backgroundColor, textColor } {
    let color: string = "#827D86";
    let textColor: string = "#FEFEFE";
    switch (status?.toUpperCase()) {
      case "NOTIFIED": color = "#2F6071"; textColor = "#FEFEFE"; break; // Add color
      case "CONFIRMED": color = "#ACCEAC"; textColor = "#111111"; break;
      case "PAYED": color = "teal"; textColor = "#111111"; break;
      case "CANCELED": color = "#F2AC9D"; textColor = "#111111"; break;
      case "CHANGE_REQUEST": color = "#FCF1D0"; textColor = "#111111"; break;
      default: color = "#827D86"; textColor: "#FEFEFE"; break;
    }
    return { backgroundColor: color, textColor };
  }
}