import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import { CartService } from '@gruuls-core/services/cart-service';

@Injectable()
export class GruulsAngularCartService{
  cartService: CartService;

  public constructor(
  ){
    this.cartService = new CartService();
  }

  public setCart(cart: {}): {} {
    return this.cartService.setCart(cart);
  }
  
  public addProductToCart(productId: string): {} {
    return this.cartService.addProductToCart(productId);
  }

  public setProductToCart(productId: string, quantity: number): {} {
    return this.cartService.setProductToCart(productId, quantity);
  }

  public removeProductFromCart(productId: string): {} {
    return this.cartService.removeProductFromCart(productId);
  }

  public resetCart(): {} {
    return this.cartService.resetCart();
  }

  public getCurrentCart(): {} {
    return this.cartService.getCurrentCart();
  }

  public getCart(): Observable<any> {
    return this.cartService.getCart();
  }

}
