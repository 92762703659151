<!-- Loader -->
<div class="inset-x-0 top-0" *ngIf="isLoading">
  <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
</div>

<div *ngIf="activeStore; else noActiveStore" class="inset-0 flex flex-col min-w-0 overflow-hidden p-8">
  <mycalendar (isLoadingEmitter)="isLoading=$event"></mycalendar>
</div>
<ng-template #noActiveStore>
  <div *ngIf="!isLoading" class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">
  {{translateStrings.appointment.noStore}}. <a [routerLink]="['../../store/list']" class="text-primary">{{translateStrings.store.create}}</a>.</div>
</ng-template>