import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, tap, map, throwError, take, switchMap, filter, mergeMap } from 'rxjs';
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { GruulsAngularHttpProxyService } from '@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { GruulsAuthService } from '@gruuls-fe/services/gruuls-auth.service';
import { BEAUTY_ACCOUNT_ASSEMBLE } from 'app/beautycians/utils/assemble';
import { Person } from '@gruuls-core/types/person.type';
import { OWNERSHIP_ROLES } from '../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class CollaboratorService {
  private _accounts: BehaviorSubject<Person[] | null> = new BehaviorSubject(null);
  private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);
  private _lastSubscriptionTime: number = null;


  /**
   * Constructor
   */
  constructor(
    private _httpClient: GruulsAngularHttpProxyService,
    private _authService: GruulsAuthService
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get accounts$(): Observable<Person[]> {
    return this._accounts.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get Account
   */
  getAccounts(page: number = null): Observable<Person[]> {
    return this._apiCaller.getPersonsByRoleTemplateName(OWNERSHIP_ROLES, { _referenceOrganizationId: this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId }, BEAUTY_ACCOUNT_ASSEMBLE, page).pipe(
      map((accounts) => accounts.hits),
      tap((accounts: Person[]) => {
        this._lastSubscriptionTime = Date.now();
        this._accounts.next(accounts);
      })
    );
  }

  refreshAccounts(page: number = null, query: string = ""): Observable<Person[]> {
    const now = Date.now();
    const UPDATE_DELTA = 30 * 60 * 1000; // 15 min.

    if (this._lastSubscriptionTime == null || (now - this._lastSubscriptionTime) > UPDATE_DELTA)
      return this.getAccounts(page);
    else
      return of(this._accounts.getValue());
  }


  /**
   * Create Account
   */
  createAccount(accountDetails: Person, roleTemplateId: string): Observable<any> {
    return this.accounts$.pipe(
      take(1),
      switchMap(accounts => this._apiCaller.createAccount(
        this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId,
        {
          firstName: accountDetails.firstName,
          lastName: accountDetails.lastName,
        },
        accountDetails.account.email,
        roleTemplateId,
        accountDetails.account.password
      ).pipe(
        map((newAccount) => {
          // accounts.push(account);
          this._accounts.next([newAccount, ...accounts]);

          return newAccount;
        }))
      )
    );
  }


  /**
   * Update client
   *
   * @param id
   * @param client
   */
  updateAccount(accountDetails: Person, roleTemplateId: string): Observable<Person> {
    if (!accountDetails.personId)
      throwError(() => 'Could not found personId!');

    return this.accounts$.pipe(
      take(1),
      switchMap(accounts => this._apiCaller.updateAccount(accountDetails, this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId, roleTemplateId).pipe(
        map((updatedClient) => {

          // Find the index of the updated client
          const index = accounts.findIndex(item => item.personId === updatedClient.personId);

          // Update the client
          accounts[index] = updatedClient;

          // Update the clients
          this._accounts.next(accounts);

          // Return the updated client
          return updatedClient;
        }),
      ))
    );
  }

  /**
   * Delete the client
   *
   * @param id
   */
  deleteAccount(accountDetails: Person): Observable<boolean> {
    return this.accounts$.pipe(
      take(1),
      switchMap(clients => this._apiCaller.deletePerson(accountDetails.personId).pipe(
        map((isDeleted: boolean) => {

          // Find the index of the deleted client
          const index = clients.findIndex(item => item.personId === accountDetails.personId);

          // Delete the client
          clients.splice(index, 1);

          // Update the clients
          this._accounts.next(clients);

          // Return the deleted status
          return isDeleted;
        })
      ))
    );
    return of(true);
  }

}
