<!-- Button -->
<ng-container *ngIf="availableStores?.length && activeStore">
    <!-- <mat-select [(value)]="activeStore" placeholder="Seleziona uno store"
        class="w-full min-h-6 p-1 py-2 pl-4 pr-2 items-center rounded-full bg-hover white-selected text-white bg-primary-400"
        (selectionChange)="activeStore = $event.value">
        <mat-select-trigger class="text-white w-32 flex items-center py-0">
            <mat-icon class="text-white">storefront</mat-icon>
            <span class="mx-2">{{ (activeStore.name.length>16)? (activeStore.name | slice:0:16)+'...':(activeStore.name)
                }}</span>
            <mat-icon class="text-white">expand_more</mat-icon>
        </mat-select-trigger>
        <mat-option *ngFor="let store of availableStores; trackBy: trackByFn" [value]="store">
            <span class="p-2">{{store.name}}</span>
        </mat-option>
    </mat-select> -->

    <button mat-button [matMenuTriggerFor]="menu" class="primary-dropdown">
        <mat-icon>storefront</mat-icon>
        <span class="mx-2 text-accent-100">{{ (activeStore.name.length>16)? (activeStore.name |
            slice:0:16)+'...':(activeStore.name)}}</span>
        <mat-icon>expand_more</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
        <ng-container *ngFor="let store of availableStores; trackBy: trackByFn">
            <button mat-menu-item (click)="setActiveStore(store)" class="py-1" [ngClass]="{'font-semibold': (store.storeId === activeStore.storeId)}">{{store.name}}</button>
        </ng-container>
    </mat-menu>
    <!-- 
    <button mat-icon-button [matMenuTriggerFor]="stores">
        <ng-container *ngTemplateOutlet="storePlaceholder; context: {$implicit: activeStore}"></ng-container>
    </button>

    <mat-menu [xPosition]="'before'" #stores="matMenu">
        <ng-container *ngFor="let store of availableStores; trackBy: trackByFn">
            <ng-container *ngIf="activeStore === store; else storeButton">
                <span class="ml-3 p-4 font-extrabold">{{store.name}}</span>
            </ng-container>
            <ng-template #storeButton>
                <button mat-menu-item (click)="setActiveStore(store)">
                    <span class="flex items-center">
                        <span class="ml-3">{{store.name}}</span>
                    </span>
                </button>
            </ng-template>
        </ng-container>
    </mat-menu>

    <ng-template #storePlaceholder>
        <ng-container *ngIf="activeStore">
            <span class="relative rounded-sm overflow-hidden">
                <span class="absolute inset-0 ring-1 ring-black ring-opacity-10 font-extrabold"></span>
                {{ (activeStore.name.length>12)? (activeStore.name | slice:0:12)+'...':(activeStore.name) }}
            </span>
        </ng-container>
    </ng-template> -->
</ng-container>