import { AttachedRole } from "@gruuls-core/types/person.type";
import _ from "lodash";

type NavigationItem = {
    id: string;
    title: string;
    type: string;
    children?: NavigationItem[];
    [key: string]: any;
};

type NestedObject = {
    [key: string]: string[] | null | NestedObject;
};

export interface ComplexFilters {
    field: string;
    value: any;
    operator: string;
    affirmative?: boolean;
}

export class Utils {

    public static guid() {
        let underscore = "_";
        let s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        };
        return s4() + s4() + underscore + s4() + underscore + s4() + underscore + s4() + underscore + s4() + s4() + s4();
    }

    // public static domainFromUrl(url): string {
    //     let result;
    //     const match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im);
    //     if (match) {
    //         result = match[1];
    //         if (match === result.match(/^[^\.]+\.(.+\..+)$/)) {
    //             result = match[1];
    //         }
    //     }
    //     return result;
    // }

    public static exactMatchExpression(key: string, value: string): any {
        // return {
        //     [key]: value,
        // }
        return {
            bool: {
                must:
                {
                    match_phrase: {
                        [key]: {
                            query: value,
                        },
                    },
                },

            },
        };
    }

    public static notExactMatchExpression(key: string, value: string): any {
        // return {
        //     [key]: value,
        // }
        return {
            must_not:
            {
                match_phrase: {
                    [key]: {
                        query: value,
                    },
                },
            },

        };
    }

    public static complexExpression(key: string, value: any, operator: string): any {
        if (operator === 'match_phrase') {
            return {
                match_phrase: {
                    [key]: {
                        query: value,
                    },
                },
            };
        }
        if (['gt', 'gte', 'lt', 'lte'].includes(operator)) {
            return {
                range: {
                    [key]: {
                        [operator]: value
                    }
                }
            }
        }
        return {
            match: {
                [key]: value
            }
        }
    }

    public static buildWhereExpressionFromObject(filters: {}) {
        let f: ComplexFilters[] = [];

        Object.entries(filters).forEach(([key, value]) => {
            const field = key.toString().startsWith('not_') ? key.toString().substring(4) : key.toString();
            f.push({ field, value: value.toString(), operator: 'match_phrase', affirmative: !key.toString().startsWith('not_') });
        });
        return this.buildWhereExpression(f);
    }


    /**
     * Builds a where expression based on the provided filters.
     * @param filters - The filters to build the where expression from. (e.g. { field: 'date', value: 123456789, operator: 'gt', affirmative: true })
     * @returns The built where expression.
     */
    public static buildWhereExpression(filters: ComplexFilters[]): any {
        let where: any = {
            bool: {
                must: [],
                must_not: [],
            },
        };
        filters.forEach((filter) => {
            const must = filter['affirmative'] === false ? 'must_not' : 'must';
            const condition = this.complexExpression(filter['field'], filter['value'], filter['operator']);
            where.bool[must].push(condition);
            // where = _.merge(where, condition);

            // if (typeof filter === 'object') {
            // } else {
            //     //where[key.toString()] = value;
            //     const condition = this.exactMatchExpression(filter['key'], filter['value']?.toString());
            //     where = _.merge(where, condition);
            // }
        });
        return where;
    }

    public static hasRole(roles: AttachedRole[], roleTemplateNames: string[] | null, inOrganization: string | null = null): boolean {
        if (!roles)
            return false;
        else if ((!roleTemplateNames || roleTemplateNames.length == 0) && inOrganization)
            return (roles.find(role => role.inOrganization?.organizationId === inOrganization) != undefined)
        else if (roleTemplateNames && !inOrganization)
            return (roles.find(role => role.hasRole?.filter(hasRole => hasRole?.name == roleTemplateNames).length > 0) != undefined)
        else
            return (roles.find(role => {
                return role.inOrganization?.organizationId === inOrganization && role?.hasRole.filter(hasRole => hasRole.name == roleTemplateNames).length > 0
            }) != undefined)
    }

    public static hasUniqueRole(roles: any[], name: string): boolean {
        if (!roles)
            return false;
        else
            return (roles.find(role => role.hasRole.filter(hasRole => hasRole.name == name).length > 0) != undefined) && roles.length == 1;
    }

    public static getPermissions(roles: any[], organizationId: string, name: string): string[] {
        const rolesOnOrganization = roles.find(role => role.inOrganization.organizationId == organizationId);
        if (rolesOnOrganization) {
            // TODO: probably introduce into a service
        }
        return [];
    }

    public static exactQLMatchExpression(key: string, value: string): any {
        return {
            bool: {
                must: [
                    {
                        match_phrase: {
                            [key]: {
                                query: value,
                            },
                        },
                    },
                ],
            },
        };
    }

    public static exactQLNotMatchExpression(key: string, value: string): any {
        return {
            bool: {
                must_not: [
                    {
                        match_phrase: {
                            [key]: {
                                query: value,
                            },
                        },
                    },
                ],
            },
        };
    }

    public static isValidString(value: any): boolean {
        return typeof value === 'string' && value.trim().length > 0;
    }

    public static formatDate(d: Date): string {
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-') + " " + d.toLocaleTimeString();
    }

    public static transformNavigation(items: NavigationItem[]): NestedObject {
        const result: NestedObject = {};

        for (const item of items) {
            if (item.children?.length) {
                result[item.title] = this.transformNavigation(item.children);
            } else {
                result[item.title] = null;
            }
        }

        return result;
    }

    public static getNestedValue(obj: any, keyStructure: string[]): string {
        if (!obj || !keyStructure || keyStructure.length == 0)
            return '';
        if (keyStructure && keyStructure.length == 1)
            return obj.hasOwnProperty(keyStructure[0]) ? obj[keyStructure[0]] : '';
        else if (keyStructure[0] === '*') {
            return Object.keys(obj).reduce((acc, key) => {
                const nestedValue = this.getNestedValue(obj[key], keyStructure.slice(1));
                if (nestedValue) {
                    acc.push(nestedValue);
                }
                return acc;
            }, []).join(' ');
        } else
            return this.getNestedValue(obj[keyStructure[0]], keyStructure.slice(1));
    }

    // searches for elements of an array that contains the desired string and key
    public static searchStringByKeys(arr: any[], text: string, keys: any[]): any[] {
        if (!arr || arr.length == 0 || !text || text.length == 0)
            return arr;
        return arr.filter((obj) => {
            let fullTextKey = '';
            for (const key of keys) {
                if (typeof (key) === 'object') {
                    const nestedValue = this.getNestedValue(obj, key);
                    if (nestedValue) {
                        fullTextKey += ` ${nestedValue}`;
                    }
                } else {
                    if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined)
                        fullTextKey += ` ${obj[key]}`;
                }
                // TODO: check if this optimize
                // if (fullTextKey.toLowerCase()?.includes(text.toLowerCase()))
                //     return true;
            }
            return fullTextKey.toLowerCase()?.includes(text.toLowerCase());
        });
    }

    // sort array by key
    public static sortByKeys(arr: any[], keys: any[]): any[] {
        if (!arr || arr.length == 0)
            return arr;
        return arr.sort((a, b) => {
            let currentTextKey = '';
            let pastTextKey = '';
            for (const key of keys) {
                if (typeof (key) === 'object') {
                    currentTextKey += ` ${this.getNestedValue(a, key)}`;
                    pastTextKey += ` ${this.getNestedValue(b, key)}`;
                } else {
                    currentTextKey += ` ${a[key]}`;
                    pastTextKey += ` ${b[key]}`;
                }
            }

            return currentTextKey.localeCompare(pastTextKey);
        });
    }

    /**
 * 
 * @param message 
 * @param title 
 * @param OKlabel 
 * @returns 
 */
    public static getErrorDialogConfig(message: string, title: string = 'Error', color: "error" | "primary" | "accent" | "warn" | "basic" | "info" | "success" | "warning" = 'warning', OKlabel: string = 'OK'): any {
        return {
            "title": title,
            "message": message,
            "icon": {
                "show": true,
                "name": "warning",
                "color": color
            },
            "actions": {
                "confirm": {
                    "show": true,
                    "label": OKlabel,
                    "color": "primary"
                },
                "cancel": {
                    "show": false,
                    "label": "Cancel"
                }
            },
            "dismissible": true
        }
    }

    public static base64ToURL(base64Data: string, type: string): string {
        // Step 1: Decode Base64 and convert to a Uint8Array (binary data)
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Step 2: Create a Blob from the binary data
        const blob = new Blob([byteArray], { type: type });

        // Step 3: Create a URL for the Blob and open it
        const url = window.URL.createObjectURL(blob);
        
        return url;
        // display with: window.open(url);
    }
}