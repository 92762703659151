import { Product } from "../products/product.types";

export interface Order {
    id?: number;
    orderId?: string;
    name?: string | null;
    _note?: string | null; // this is used internally
    _referenceOrganizationId?: string;
    organizationId?: { organizationId: string };
    referent?: {
        personId: string;
    };
    store?: any,
    storeId?: string;
    date?: number;
    info?: string;
    infoObject?: OrderInfo[];
    // SET STATUS WITH DEFAULT VALUE
    externalPaymentConditionsId?: number;
    status?: string | null;
    items?: {
        productId: string;
        quantity: number;
        product?: Product;
        discount?: number;
    }[];
    cart?: {
        products: {
            productId: string;
            quantity: number;
            recentlyAdded?: boolean;
            product?: Product;
            discount?: number;
            isGift?: boolean;
            delivered?: {
                data: Date,
                quantita: number
            }[]
        }[]
    };
    delivered?: any;
    creationDate?: number;
    lastUpdate?: number;
    total?: number;
}
export interface OrderInfo {
    uuid?: string,
    personId?: string,
    organizationId?: string,
    date?: number,
    comment?: string,
    type?: string,
    isPublic: boolean
}

export const ORDERSTATUS = {
    WAITING: "WAITING",
    PROCESSING: "PROCESSING",
    PARTIAL_DELIVERING: "PARTIAL_DELIVERING",
    DELIVERING: "DELIVERING",
    SENT: "SENT",
    DELIVERED: "DELIVERED",
    CANCELED: "CANCELED",
};