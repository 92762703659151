import { BehaviorSubject, Observable, tap, map, of, take, switchMap, filter, Subject } from "rxjs";
import { Appointment } from "../modules/clients/client.types";
import { GruulsAngularHttpProxyService } from "@gruuls-fe/services/gruuls-angular-http-proxy.service";
import { ApiCaller } from "../utils/apiCaller";
import { GruulsAuthService } from "@gruuls-fe/services/gruuls-auth.service";
import { GruulsAngularBrowserCacheService } from "@gruuls-fe/services/gruuls-angular-browser-cache-service";
import md5 from 'crypto-js/md5';
import { GruulsAngularStoreService } from "@gruuls-fe/services/gruuls-angular-stores-service";
import { Injectable } from "@angular/core";

const CACHE_TTL_IN_MIN = 30;
@Injectable({
  providedIn: 'root'
})
export class AppointmentsService {
  // decided to duplicate this as an Angular service in order to use it as an Injectable

  _appointments$: BehaviorSubject<Appointment[]> = new BehaviorSubject<Appointment[]>([]);
  _clientAppointments$: BehaviorSubject<Appointment[]> = new BehaviorSubject<Appointment[]>([]);
  updates: BehaviorSubject<Appointment[]> = new BehaviorSubject<Appointment[]>([]);

  private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);
  private readonly _updateDelta = 15 * 60 * 1000;
  private _loadedStartRage: number = undefined;
  private _loadedEndRage: number = undefined;
  private storeId$: Subject<string> = undefined;
  private _lastAppointmentsUpdate: number = null;
  private filterHash: string = null;

  constructor(
    private _httpClient: GruulsAngularHttpProxyService,
    private _authService: GruulsAuthService,
    private browserCacheService: GruulsAngularBrowserCacheService,
    private storeService: GruulsAngularStoreService
  ) {
    // this.updates.next(this.appointments);
  }

  get appointments$(): Observable<Appointment[]> {
    return this._appointments$.asObservable();
  }

  get clientAppointments$(): Observable<Appointment[]> {
    return this._clientAppointments$.asObservable();
  }

  getAppointments(start: number = null, end: number = null): Observable<Appointment[]> {
    let filters = [];
    let newStart = start;
    let newEnd = end;

    // handle the case where the start and end are not provided
    if (start && end && ((this._loadedStartRage !== null && start < this._loadedStartRage) || (this._loadedEndRage !== null && end > this._loadedEndRage))) {
      newStart = start < this._loadedStartRage ? start : this._loadedStartRage;
      newEnd = end > this._loadedEndRage ? end : this._loadedEndRage;
    }
    if (newStart)
      filters.push({ field: 'date', operator: 'gt', value: newStart });
    if (newEnd)
      filters.push({ field: 'date', operator: 'lt', value: newEnd });

    return this.storeService.activeStore$.pipe(
      switchMap(store => {
        const appointmentFilters = [...filters];
        if (store?.storeId)
          appointmentFilters.push({ field: 'storeId', operator: 'match_phrase', value: store.storeId });
        appointmentFilters.push({ field: '_referenceOrganizationId', operator: 'match_phrase', value: this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId });

        this.filterHash = md5(JSON.stringify(appointmentFilters)).toString();
        if (this.browserCacheService) {
          const cacheObject = JSON.parse(this.browserCacheService.get("appointments_" + this.filterHash));
          if (cacheObject) {
            this._appointments$.next(cacheObject);
            return of(cacheObject);
          }
        }

        return this._apiCaller.getAppointments(null, appointmentFilters).pipe(
          tap((appointments) => {
            this._lastAppointmentsUpdate = Date.now();
            this._loadedStartRage = start;
            this._loadedEndRage = end;
            this.browserCacheService.set("appointments_" + this.filterHash, JSON.stringify(appointments), CACHE_TTL_IN_MIN * 60);
            this._appointments$.next(appointments);
          })
        );
      })
    )
    // if (storeId)
    //   filters.push({ field: 'storeId', operator: 'match_phrase', value: storeId });
    // filters.push({ field: '_referenceOrganizationId', operator: 'match_phrase', value: this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId });

    // const filtersHash = md5(JSON.stringify(filters)).toString();
    // if (this.browserCacheService) {
    //   const cacheObject = JSON.parse(this.browserCacheService.get("appointments_" + filtersHash));
    //   if (cacheObject) {
    //     this._appointments$.next(cacheObject);
    //     return of(cacheObject);
    //   }
    // }

    // return this._apiCaller.getAppointments(null, filters).pipe(
    //   tap((appointments) => {
    //     this._lastAppointmentsUpdate = Date.now();
    //     this._loadedStartRage = start;
    //     this._loadedEndRage = end;
    //     this.browserCacheService.set("appointments_" + filtersHash, JSON.stringify(appointments), CACHE_TTL_IN_MIN * 60);
    //     this._appointments$.next(appointments);
    //   })
    // );
  }

  /**
   * Get appointments
   */
  getClientAppointments(clientId: string): Observable<Appointment[]> {
    if (!clientId)
      return of([]);
    // TODO: is it possible to add a filter on ClientID in getAppointments method?
    return this.getAppointments().pipe(
      map((appointments: Appointment[]) => appointments.filter((appointment: Appointment) => (appointment.client && appointment.client.personId === clientId))),
      // return this._apiCaller.getAppointments(null, { client: clientId, _referenceOrganizationId: this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId }).pipe(
      tap((appointments: Appointment[]) => {
        this._clientAppointments$.next(appointments);
      })
    );
  }

  /**
   * Update appointment
   */
  updateAppointment(appointment: Appointment): Observable<Appointment> {
    return this.appointments$.pipe(
      take(1),
      switchMap(appointments => this._apiCaller.updateAppointment(appointment).pipe(
        map((updatedAppointment: Appointment) => {

          // Find the index of the updated contact
          const index = appointments.findIndex(item => item.appointmentId === appointment.appointmentId);

          // Update the contact
          appointments[index] = appointment; // TODO: this should be updatedAppointment 
          this.browserCacheService.set("appointments_" + this.filterHash, JSON.stringify(appointments), CACHE_TTL_IN_MIN * 60);

          // Update the contacts
          this._appointments$.next(appointments);
          // this._appointments.next(appointments);

          // Return the updated contact
          return updatedAppointment;
        }),
        switchMap(updatedAppointment => this.clientAppointments$.pipe(
          take(1),
          map((clientAppointments: Appointment[]) => {
            // Find the index of the updated contact
            const index = clientAppointments.findIndex(item => item.appointmentId === appointment.appointmentId);

            // Update the contact
            clientAppointments[index] = appointment; // TODO: this should be updatedAppointment 

            // Update the contacts
            this._clientAppointments$.next(clientAppointments);

            // Return the updated contact
            return updatedAppointment;
          }))
        ))
      )
    );
  }

  /**
   * Create appointment
   */
  createAppointment(appointment: Appointment): Observable<Appointment> {
    // return this._apiCaller.createAppointment(appointment);
    return this.appointments$.pipe(
      take(1),
      switchMap(appointments => this._apiCaller.createAppointment(appointment).pipe(
        map((newAppointment) => {
          
          const newAppointments = [newAppointment, ...appointments];
          this.browserCacheService.set("appointments_" + this.filterHash, JSON.stringify(newAppointments), CACHE_TTL_IN_MIN * 60);

          // Update the products with the new product
          this._appointments$.next(newAppointments);

          // Return the new product
          return newAppointment;
        }),
        switchMap(newAppointment => this.clientAppointments$.pipe(
          take(1),
          map((clientAppointments: Appointment[]) => {
            // Update the products with the new product
            this._clientAppointments$.next([newAppointment, ...clientAppointments]);

            // Return the new product
            return newAppointment;
          }))
        ))
      )
    );
  }

  /**
   * Delete appointment
   * 
   * @param uuid
   * 
   */
  deleteAppointment(uuid: string): Observable<boolean> {
    return this.appointments$.pipe(
      take(1),
      switchMap(appointments => this._apiCaller.deleteAppointment(uuid).pipe(
        map((isDeleted: boolean) => {
          // Find the index of the deleted contact
          if (isDeleted) {
            const index = appointments.findIndex(item => item.appointmentId === uuid);
            appointments.splice(index, 1);
            this.browserCacheService.set("appointments_" + this.filterHash, JSON.stringify(appointments), CACHE_TTL_IN_MIN * 60);
            this._appointments$.next(appointments);
          }
          return isDeleted;
        }),
        switchMap(isDeleted => this.clientAppointments$.pipe(
          take(1),
          map((clientAppointments: Appointment[]) => {
            if (isDeleted) {
              const index = clientAppointments.findIndex(item => item.appointmentId === uuid);
              clientAppointments.splice(index, 1);
              this._clientAppointments$.next(clientAppointments);
            }
            return isDeleted;
          }))
        ))
      )
    );
  }

}