export const OWNERSHIP_ROLES = ['Beautycians-HQ', 'Beautycians-HQ-Sales', 'Beautycians-Stores-Owner', 'Beautycians-Store-Owner', 'Beautycians-Store-Manager', 'Beautycians-Store-Operator'];

export const BeautyRoles = {
  HQ: 'Beautycians-HQ',
  HQSales: 'Beautycians-HQ-Sales',
  StoresOwner: 'Beautycians-Stores-Owner',
  StoreOwner: 'Beautycians-Store-Owner',
  StoreManager: 'Beautycians-Store-Manager',
  StoreOperator: 'Beautycians-Store-Operator'
};
