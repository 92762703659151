import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { GruulsAngularHttpProxyService } from '@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { Product } from './product.types';
import { Utils } from '@gruuls-core/utils/Utils';
import { GruulsAuthService } from '@gruuls-fe/services/gruuls-auth.service';

@Injectable({
    providedIn: 'root'
})
export class ProductsService {
    // Private
    private _product: BehaviorSubject<Product | null> = new BehaviorSubject(null);
    private _products: BehaviorSubject<Product[] | null> = new BehaviorSubject(null);
    private _fProducts: BehaviorSubject<Product[] | null> = new BehaviorSubject(null);
    private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);
    private _lastSubscriptionTime: number = null;
    /**
     * Constructor
     */
    constructor(
        private _httpClient: GruulsAngularHttpProxyService,
        private _authService: GruulsAuthService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for object
     */
    get product$(): Observable<Product> {
        return this._product.asObservable();
    }

    /**
     * Getter for objects
     */
    get products$(): Observable<Product[]> {
        return this._fProducts.asObservable();
    }

    get allProducts$(): Observable<Product[]> {
        return this._products.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get contacts
     */
    getProducts(): Observable<Product[]> {
        return this._apiCaller.getProducts().pipe(
            map((products) => Utils.sortByKeys(products, ['name'])),
            tap((products) => {
                this._lastSubscriptionTime = Date.now();
                this._products.next(products);
                this._fProducts.next(products);
            })
        );
    }

    refresh(): Observable<Product[]> {
        const now = Date.now();
        const UPDATE_DELTA = 15 * 60 * 1000; // 15 min.

        if (this._lastSubscriptionTime == null || (now - this._lastSubscriptionTime) > UPDATE_DELTA) {
            this._lastSubscriptionTime = Date.now();
            return this.getProducts();
        }
        else {
            return of(this._products.getValue());
        }
        // return this.filterClients(query);
    }

    /**
     * Search products with given query
     *
     * @param query
     */
    searchProducts(query: string): Observable<Product[]> {
        const filteredProducts = this._products.pipe(
            take(1),
            map((products: Product[]) => products.filter(product => ((product.name && product.name.toLowerCase().includes(query.toLowerCase())) || product.productId.includes(query)))),
            tap((products: Product[]) => {
                this._fProducts.next(products);
            })
        );
        return filteredProducts;
    }

    /**
     * Get product by id
     */
    getProductById(uuid: string): Observable<Product> {
        return this._products.pipe(
            take(1),
            map((products) => {
                if (!products) {
                    return null;
                }
                // Find the contact
                const product = products.find(item => item.productId === uuid) || null;

                // Update the contact
                this._product.next(product);

                // Return the contact
                return product;
            }),
            switchMap((product) => {

                if (!product)
                    return throwError(() => 'Could not found product with id of ' + uuid + '!');

                return of(product);
            })
        );
    }

    /**
     * Create contact
     */
    createProduct(product: Product): Observable<Product> {
        return this.products$.pipe(
            take(1),
            switchMap(products => this._apiCaller.createProduct(product).pipe(
                map((newProduct) => {

                    // Update the products with the new product
                    this._products.next([newProduct, ...products]);
                    this._fProducts.next([newProduct, ...products]);

                    // Return the new product
                    return newProduct;
                })
            ))
        );
    }

    /**
     * Update contact
     *
     * @param uuid
     * @param contact
     */
    updateProduct(uuid: string, product: Product): Observable<Product> {
        return this.products$.pipe(
            take(1),
            switchMap(products => this._apiCaller.updateProduct(uuid, product).pipe(
                map((updatedProduct: Product) => {

                    // Find the index of the updated contact
                    const index = products.findIndex(item => item.productId === uuid);

                    // Update the contact
                    products[index] = updatedProduct;

                    // Update the contacts
                    this._products.next(products);
                    this._fProducts.next(products);

                    // Return the updated contact
                    return updatedProduct;
                }),
                switchMap(updatedProduct => this.product$.pipe(
                    take(1),
                    filter(item => item && item.productId === uuid),
                    tap(() => {

                        // Update the product if it's selected
                        this._product.next(updatedProduct);

                        // Return the updated product
                        return updatedProduct;
                    })
                ))
            ))
        );
    }

    /**
     * Delete the contact
     *
     * @param id
     */
    deleteProduct(id: string): Observable<boolean> {
        return this.products$.pipe(
            take(1),
            switchMap(products => this._apiCaller.deleteProduct(id).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted contact
                    const index = products.findIndex(item => item.productId === id);

                    // Delete the contact
                    products.splice(index, 1);

                    // Update the contacts
                    this._products.next(products);
                    this._fProducts.next(products);

                    // Return the deleted status
                    return isDeleted;
                })
            ))
        );
    }

    /**
     * Update the avatar of the given contact
     *
     * @param id
     * @param avatar
     */
    // uploadAvatar(id: string, avatar: File): Observable<Contact>
    // {
    //     return this.contacts$.pipe(
    //         take(1),
    //         switchMap(contacts => this._httpClient.post<Contact>('api/apps/contacts/avatar', {
    //             id,
    //             avatar
    //         }, {
    //             headers: {
    //                 // eslint-disable-next-line @typescript-eslint/naming-convention
    //                 'Content-Type': avatar.type
    //             }
    //         }).pipe(
    //             map((updatedContact) => {

    //                 // Find the index of the updated contact
    //                 const index = contacts.findIndex(item => item.id === id);

    //                 // Update the contact
    //                 contacts[index] = updatedContact;

    //                 // Update the contacts
    //                 this._contacts.next(contacts);

    //                 // Return the updated contact
    //                 return updatedContact;
    //             }),
    //             switchMap(updatedContact => this.contact$.pipe(
    //                 take(1),
    //                 filter(item => item && item.id === id),
    //                 tap(() => {

    //                     // Update the contact if it's selected
    //                     this._contact.next(updatedContact);

    //                     // Return the updated contact
    //                     return updatedContact;
    //                 })
    //             ))
    //         ))
    //     );
    // }
}
