import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { take, takeUntil, tap } from 'rxjs/operators';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Store } from 'app/beautycians/utils/dataTypes';
import { GruulsAngularTranslateService } from '@gruuls-fe/services/gruuls-angular-translate.service';
import { GruulsAngularStoreService } from '@gruuls-fe/services/gruuls-angular-stores-service';
import { Subject } from 'rxjs';

@Component({
    selector       : 'stores-selector',
    templateUrl    : './stores.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'stores-selector'
})
export class StoresSelectorComponent implements OnInit, OnDestroy
{
    availableStores: Store[];
    activeStore: Store;
    translateStrings: any = { client: { } };
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _storeService: GruulsAngularStoreService,
        private _translate: GruulsAngularTranslateService
    )
    {
    }

    ngOnInit(): void {
        
        const clientTranslations = ['referenceStore'];
        clientTranslations.forEach((translation) => {
            this.translateStrings['client'][translation] = this._translate.translate('clients.' + translation);
        });

        // TODO: needs to put a check on login here ?
        this._storeService.stores$.pipe(
            takeUntil(this._unsubscribeAll),
            tap((stores) => {
                this.availableStores = stores;
                this._changeDetectorRef.markForCheck();
            })
        ).subscribe();

        this._storeService.activeStore$.pipe(
            takeUntil(this._unsubscribeAll),
            tap((activeStore) => {
                this.activeStore = activeStore;
                this._changeDetectorRef.markForCheck();
            })
        ).subscribe();

        this._storeService.refreshStores().pipe(
            takeUntil(this._unsubscribeAll),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

    trackByFn(index: number, item: Store): string {
        return item.storeId;
    }

    setActiveStore(store: Store): void {
        this._storeService.setActiveStore(store).subscribe();
        this._changeDetectorRef.markForCheck();
    }
}