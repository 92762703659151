import '../order.types';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Inject, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { TemplatePortal } from '@angular/cdk/portal';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { debounceTime, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { GruulsAngularHttpProxyService } from '@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { GruulsLocalStorageService } from '@gruuls-core/infrastructure/gruuls-local-storage-service';
import { GruulsAngularTranslateService } from '@gruuls-fe/services/gruuls-angular-translate.service';
import { ORDERSTATUS, Order, OrderInfo } from '../order.types';
import { DOCUMENT } from '@angular/common';
import { GruulsAngularCartService } from '@gruuls-fe/services/gruuls-angular-cart.service';
import { OrderStatus } from 'app/beautycians/utils/orderStatus';
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { MatDialog } from '@angular/material/dialog';
import { OrderConfirmationDialogComponent } from './order-confirmation-dialog.component';
import { GruulsAuthService } from '@gruuls-fe/services/gruuls-auth.service';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BeautyciansUtils } from 'app/beautycians/utils/utils';
import { Utils } from '@gruuls-core/utils/Utils';

@Component({
    selector: 'order-details',
    templateUrl: './order-details.component.html',
    styles: [
        /* language=SCSS */
        // TODO: set correct width
        `
            .inventory-grid {
                grid-template-columns: 48px auto 112px 72px 72px 72px;

                @screen sm {
                    grid-template-columns: 48px auto 112px 112px;
                }

                @screen md {
                    grid-template-columns: 48px auto 120px 120px 72px 72px 72px;
                }

                @screen lg {
                    grid-template-columns: 48px auto 200px 200px 72px 72px 72px;
                }
            }
        `
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
    @ViewChild('addCommentForm') addCommentForm: NgForm;
    @ViewChild('contextToggle') contextToggle: MatSlideToggle;
    @ViewChild('commentInput') commentInput: ElementRef;

    comments: {}[];
    showRemove: boolean[] = [];
    isLoading: boolean = true;
    orderId: string;
    order: Order = { cart: { products: [] } };
    currentOrder: {} = {};
    orderStatus: string[] = Object.keys(ORDERSTATUS);
    canChange: boolean = false;

    private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);
    private _tagsPanelOverlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    translateStrings: any = { order: {}, user: {}, generic: {}, orderDetail: {}, product: {} };

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _authService: GruulsAuthService,
        private _cartService: GruulsAngularCartService,
        private _changeDetectorRef: ChangeDetectorRef,
        @Inject(DOCUMENT) private _document: any,
        private _formBuilder: FormBuilder,
        private _fuseConfirmationService: FuseConfirmationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _httpClient: GruulsAngularHttpProxyService,
        private _renderer2: Renderer2,
        private _router: Router,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _translate: GruulsAngularTranslateService,
        private gruulsLocalStorage: GruulsLocalStorageService,
        private _snackBar: MatSnackBar,
        public dialog: MatDialog
    ) {
    }

    @HostBinding('class') get classList(): any {
        return {
            'w-full': true
        };
    }

    /**
     * On init
     */
    ngOnInit(): void {
        let myOrder: Observable<Order>;

        this._activatedRoute.params.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe((params) => {
            // Get the order details
            this.orderId = params.id;
            if (!this.orderId) {
                this.canChange = true;
                const currentOrder = this._cartService.getCurrentCart();
                Object.entries(currentOrder).forEach(([key, value]) => {
                    this.order.cart.products.push({ productId: key, quantity: Number(value), discount: 0 });
                });
                myOrder = of(this.order);
            } else {
                myOrder = this._apiCaller.getOrder(this.orderId).pipe(
                    tap((order) => {
                        this.order = order;
                        // this.canChange = (this.order.status === ORDERSTATUS.WAITING);
                        this._changeDetectorRef.markForCheck();
                    }),
                );
            }
            // Get products and Map Products details to the orderItem
            // TODO: improve timing with parallel call together with the order details
            myOrder.pipe(
                map((order) => {
                    if (order.infoObject)
                        order.infoObject = order.infoObject.filter(comment => comment.isPublic || (comment.organizationId == this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId));
                    return order;
                }),
                switchMap(order => {
                    if (this.order.cart.products.length > 0) {
                        return forkJoin({
                            store: this._apiCaller.getStore(this.order.storeId).pipe(
                                tap(
                                    (responseStore) => {
                                        this.order.store = responseStore;
                                        this._changeDetectorRef.markForCheck();
                                    }
                                ))
                        })
                    } else {
                        return of({});
                    }
                })
            ).subscribe({
                next: (res) => {
                    this.order = Object.assign({}, this.order);
                    this.isLoading = false;
                    this._changeDetectorRef.markForCheck();
                },
                error: (msg) => {
                    console.log('Error while getting products: ', msg);
                    this.isLoading = false;
                    this._changeDetectorRef.markForCheck();
                }
            });
        });

        const genericTranslations = ['warning', 'cancel', 'confirm', 'close', 'send', 'error', 'insert', 'internal', 'public', 'delete', 'autoSaveOK'];
        genericTranslations.forEach((translation) => {
            this.translateStrings['generic'][translation] = this._translate.translate('generic.' + translation);
        });

        const orderTranslations = ['plural', 'singular', 'no', 'search', 'addNew', 'copyToNew', 'noSearchResults', 'total', 'shippingTo', 'errorWhilePlacing', 'confirmCancel', 'cart'];
        orderTranslations.forEach((translation) => {
            this.translateStrings['order'][translation] = this._translate.translate('orders.' + translation);
        });

        const orderDetailsTranslations = ['no', 'gotobuy', 'search', 'addNew', 'send', 'noSearchResults', 'confirm', 'proceed'];
        orderDetailsTranslations.forEach((translation) => {
            this.translateStrings['orderDetail'][translation] = this._translate.translate('orderDetails.' + translation);
        });

        const productsTranslations = ['name', 'unitPrice', 'price', 'discount', 'quantity', 'category', 'dimension', 'websiteRef', 'imgUrl', 'quantity', 'generic'];
        productsTranslations.forEach((translation) => {
            this.translateStrings['product'][translation] = this._translate.translate('products.' + translation);
        });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();

        // Dispose the overlays if they are still on the DOM
        if (this._tagsPanelOverlayRef) {
            this._tagsPanelOverlayRef.dispose();
        }
    }

    addComment(): void {
        if (this.commentInput.nativeElement.value == "")
            return;
        const order = this.order;
        let comment: OrderInfo = {
            personId: this._authService.getCurrentLoggedUser().personId,
            organizationId: this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId,
            date: new Date().getTime(),
            comment: this.commentInput.nativeElement.value,
            isPublic: this.contextToggle.checked,
        };
        this._apiCaller.addOrderComment(order, comment).subscribe({
            next: (orderResponse) => {
                this.order.infoObject = orderResponse.infoObject;
                this.order = Object.assign({}, this.order);
                this.addCommentForm.reset();
                this._changeDetectorRef.markForCheck();
            }
        })
    }

    addProductToCart(productId: string): void {
        this.currentOrder = this._cartService.addProductToCart(productId);
    }

    removeProductFromCart(productId: string): void {
        this.currentOrder = this._cartService.removeProductFromCart(productId);
    }

    getStatusColor(status: string): string {
        return OrderStatus.getStatusColor(status);
    }

    getStatusIcon(status: string): string {
        return OrderStatus.getStatusIcon(status);
    }

    updateOrder(order$): void {
        const orderToBeSaved = _.cloneDeep(order$);
        orderToBeSaved.cart.products = orderToBeSaved.cart.products.filter(item => item.quantity > 0);
        
        if (!orderToBeSaved.orderId) {
            const cart = {};
            orderToBeSaved.cart.products.forEach((product) => {
                cart[product.productId] = product.quantity;
            });
            this._cartService.setCart(cart);
        }
        this._apiCaller.updateOrder(orderToBeSaved).subscribe({
            next: (response) => {
                this._snackBar.open(this.translateStrings['generic']['autoSaveOK'], null, {
                    duration: 1500,
                    horizontalPosition: "left",
                    verticalPosition: "bottom"
                });
            },
            error: (error) => {
                console.error(error);
                this._fuseConfirmationService.open(
                    Utils.getErrorDialogConfig('Something went wrong:' + error.error, 'Error', 'warning')
                );
            }
        });
    }

    proceedWithOrder(): void {
        const config = {
            "title": this.translateStrings['orderDetail']['confirm'],
            "message": "",
            "icon": {
                "show": true,
                "name": "send",
                "color": "primary"
            },
            "actions": {
                "confirm": {
                    "show": true,
                    "label": this.translateStrings['orderDetail']['send'],
                    "color": "primary"
                },
                "cancel": {
                    "show": true,
                    "label": this.translateStrings['generic']['cancel'],
                }
            },
            "dismissible": true
        }

        const customDialog = this.dialog.open(OrderConfirmationDialogComponent, {
            autoFocus: false,
            disableClose: !config.dismissible,
            data: config,
            panelClass: 'custom-confirmation-dialog-panel'
        })
        customDialog.componentInstance.order = this.order;

        customDialog.afterClosed().subscribe((result) => {
            // if (result.message === 'confirm') {
            //     this.order.storeId = result.store.storeId;
            //     this.sendOrder();
            // }
        });

    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
