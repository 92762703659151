import { Injectable } from '@angular/core';
import { BrowserCacheService } from '@gruuls-core/services/browser-cache-service';
import { GruulsAuthService } from './gruuls-auth.service';
import md5 from 'crypto-js/md5';

@Injectable({
  providedIn: 'root'
})
export class GruulsAngularBrowserCacheService {
  private browserCacheService: BrowserCacheService;

  public constructor(
    private authService: GruulsAuthService
  ) {
    this.authService.currentUserObservable().subscribe({
      next: (authUser) => {
        if (authUser) {
          const personId = authUser.personId;
          const namespace = "cache_" + md5(personId);
          const encryptionKey = personId;
          this.browserCacheService = new BrowserCacheService(namespace, encryptionKey);
        } else {
          this.browserCacheService = undefined;
        }
      },
      error: (error) => {
        console.error("Error while initializing BrowserCacheService: " + error);
      }
    });
  }

  // getNamespace() {
  //   return this.browserCacheService.namespace;
  // }

  set(key: string, value: any, ttl: number | null = null, inMemory: boolean = false) {
    if (this.browserCacheService) {
      this.browserCacheService.set(key, value, ttl, inMemory);
    } else {
      console.warn("BrowserCacheService is not initialized.");
    }
  }

  get(key) {
    return this.browserCacheService ? this.browserCacheService.get(key) : null;
  }

  delete(key) {
    if (this.browserCacheService) {
      this.browserCacheService.delete(key);
    }
  }

  clear() {
    if (this.browserCacheService) {
      this.browserCacheService.clear();
    }
  }

}