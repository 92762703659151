export class BrowserCacheService {
  encryptionKey: any;
  namespace: string;
  cache: Map<any, any>;
  inMemoryCache: Map<any, any>;

  constructor(namespace = 'app-cache', encryptionKey = null) {
    this.namespace = namespace;
    this.encryptionKey = encryptionKey;
    this.cache = new Map();
    this.inMemoryCache = new Map();
    this.loadFromStorage();
  }

  // Encryption helper using AES
  private async encrypt(data) {
    if (!this.encryptionKey) return JSON.stringify(data);
    
    const encoder = new TextEncoder();
    const encodedData = encoder.encode(JSON.stringify(data));
    const encodedKey = encoder.encode(this.encryptionKey);
    
    if (!crypto.subtle) {
      // Web Crypto API not supported, for example when using HTTP in Chrome
      throw new Error('Web Crypto API is not supported in this environment');
    }
    // Generate key from password
    const key = await crypto.subtle.importKey(
      'raw',
      encodedKey,
      { name: 'PBKDF2' },
      false,
      ['deriveBits', 'deriveKey']
    );
    
    // Generate random salt
    const salt = crypto.getRandomValues(new Uint8Array(16));
    
    // Derive actual encryption key
    const aesKey = await crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt: salt,
        iterations: 100000,
        hash: 'SHA-256'
      },
      key,
      { name: 'AES-GCM', length: 256 },
      false,
      ['encrypt']
    );
    
    // Generate random IV
    const iv = crypto.getRandomValues(new Uint8Array(12));
    
    // Encrypt the data
    const encryptedContent = await crypto.subtle.encrypt(
      { name: 'AES-GCM', iv: iv },
      aesKey,
      encodedData
    );
    
    // Combine the salt, IV, and encrypted content
    const encryptedArray = new Uint8Array(salt.length + iv.length + encryptedContent.byteLength);
    encryptedArray.set(salt, 0);
    encryptedArray.set(iv, salt.length);
    encryptedArray.set(new Uint8Array(encryptedContent), salt.length + iv.length);
    
    // Convert to base64 for storage
    return btoa(String.fromCharCode.apply(null, encryptedArray));
  }

  // Decryption helper
  private async decrypt(encryptedData: string) {
    if (!this.encryptionKey) return JSON.parse(encryptedData);
    
    // Convert from base64
    let encryptedArray;
    try {
      encryptedArray = new Uint8Array(atob(encryptedData).split('').map(char => char.charCodeAt(0)));
    } catch (error) {
      throw new Error('Invalid base64 string');
    }
    
    // Extract salt, IV, and encrypted content
    const salt = encryptedArray.slice(0, 16);
    const iv = encryptedArray.slice(16, 28);
    const data = encryptedArray.slice(28);
    
    const encoder = new TextEncoder();
    const encodedKey = encoder.encode(this.encryptionKey);
    
    // Generate key from password
    const key = await crypto.subtle.importKey(
      'raw',
      encodedKey,
      { name: 'PBKDF2' },
      false,
      ['deriveBits', 'deriveKey']
    );
    
    // Derive decryption key
    const aesKey = await crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt: salt,
        iterations: 100000,
        hash: 'SHA-256'
      },
      key,
      { name: 'AES-GCM', length: 256 },
      false,
      ['decrypt']
    );
    
    // Decrypt the data
    const decryptedContent = await crypto.subtle.decrypt(
      { name: 'AES-GCM', iv: iv },
      aesKey,
      data
    );
    
    const decoder = new TextDecoder();
    return JSON.parse(decoder.decode(decryptedContent));
  }

  // Load cache from localStorage
  private async loadFromStorage() {
    const storedData = localStorage.getItem(this.namespace);
    if (storedData) {
      try {
        const decryptedData = await this.decrypt(storedData);
        this.cache = new Map(Object.entries(decryptedData));
      } catch (error) {
        console.error('Error loading cache:', error);
        this.cache = new Map();
      }
    }
  }

  // Save cache to localStorage
  private async saveToStorage() {
    try {
      const cacheObject = Object.fromEntries(this.cache);
      const encryptedData = await this.encrypt(cacheObject);
      localStorage.setItem(this.namespace, encryptedData);
    } catch (error) {
      console.error('Error saving cache:', error);
    }
  }

  // Set a value in cache with optional TTL in seconds
  async set(key, value, ttl = null, inMemoryOnly = false) {
    const item = {
      value,
      timestamp: Date.now(),
      ttl: ttl ? ttl * 1000 : null // Convert TTL to milliseconds
    };
    if (!inMemoryOnly) {
      this.cache.set(key, item);
      await this.saveToStorage();
    } else {
      this.inMemoryCache.set(key, item);
    }
  }

  // Get a value from cache
  get(key) {
    let item = this.cache.get(key);
    
    if (!item) item = this.inMemoryCache.get(key);
    
    if (!item) return null;
    
    // Check if item has expired
    if (item.ttl && Date.now() - item.timestamp > item.ttl) {
      this.delete(key);
      return null;
    }
    
    return item.value;
  }

  // Delete a value from cache
  async delete(key) {
    this.cache.delete(key);
    this.inMemoryCache.delete(key);
    await this.saveToStorage();
  }

  // Clear entire cache
  async clear() {
    this.cache.clear();
    this.inMemoryCache.clear();
    await this.saveToStorage();
  }
}