import { Pipe, PipeTransform } from '@angular/core';
import { Person } from '@gruuls-core/types/person.type';
import { Utils } from '@gruuls-core/utils/Utils';

@Pipe({

  name: 'hasRole'

})

export class CustomPipeHasRole implements PipeTransform {

  transform(value: Person, arg1: any): any {
    return Utils.hasRole(value.roles, [arg1]);
  }
}
