
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Utils } from '@gruuls-core/utils/Utils';
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { Subject } from 'rxjs';

@Component({
    selector       : 'settings',
    templateUrl    : './settings.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent implements OnInit, OnDestroy
{
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    structure: any = [];
    availablePages: any = [];

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private apiCaller: ApiCaller
    )
    {
    }

    ngOnInit(): void {
        this.apiCaller.getAppConfiguration().subscribe((config) => {
            this.availablePages = config.routes,
            this._changeDetectorRef.markForCheck();
        });

        this.apiCaller.getMenu().subscribe((menu) => {
            this.structure = Utils.transformNavigation(menu);
            this.structure = {
                Groceries: {
                  'Almond Meal flour': null,
                  'Organic eggs': null,
                  'Protein Powder': null,
                  Fruits: {
                    Apple: null,
                    Berries: ['Blueberry', 'Raspberry'],
                    Orange: null
                  }
                },
                Reminders: [
                  'Cook dinner',
                  'Read the Material Design spec',
                  'Upgrade Application to Angular'
                ]
              };
            this._changeDetectorRef.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

}