<div class="inset-0 flex flex-col min-w-0 overflow-hidden">
    <mat-drawer-container class="flex-auto h-full bg-card dark:bg-transparent" (backdropClick)="onBackdropClicked()">

        <!-- Drawer -->
        <mat-drawer class="w-full md:w-160 dark:bg-gray-900" [mode]="drawerMode" [opened]="false" [position]="'end'"
            [disableClose]="true" #matDrawer>
            <router-outlet></router-outlet>
        </mat-drawer>

        <mat-drawer-content class="flex flex-col">

            <!-- Main -->
            <div class="flex-auto">
                <!-- Header -->
                <div class="flex flex-col sm:flex-row md:flex-col flex-auto justify-between py-8 px-6 md:px-8 border-b">

                    <!-- Title -->
                    <div>
                        <div class="text-4xl font-extrabold tracking-tight leading-none">
                            {{translateStrings.client.plural}}</div>
                        <div class="ml-0.5 font-medium text-secondary">
                            <ng-container *ngIf="filteredContacts?.length">
                                {{filteredContacts.length}}
                            </ng-container>
                            {{filteredContacts?.length | i18nPlural: {
                            '=0' : translateStrings.client.noClients,
                            '=1' : translateStrings.client.singular,
                            'other': translateStrings.client.plural
                            } }}
                        </div>
                    </div>

                    <!-- Main actions -->
                    <div class="flex items-center mt-4 sm:mt-0 md:mt-4">
                        <!-- Search -->
                        <div class="flex-auto">
                            <mat-form-field
                                class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                                <input matInput [formControl]="searchInputControl" [autocomplete]="'off'"
                                    [placeholder]="translateStrings.client.search">
                            </mat-form-field>
                        </div>
                        <!-- Add contact button -->
                        <button class="ml-4" mat-flat-button [color]="'primary'"
                            (click)="this.selectedUser = undefined; openModal()">
                            <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                            <span class="ml-2 mr-1">{{ translateStrings.client.addNew }}</span>
                        </button>
                    </div>
                </div>

                <!-- Loader -->
                <div class="inset-x-0" *ngIf="isLoading">
                    <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
                </div>

                <!-- Contacts list -->
                <div class="relative">
                    <ng-container *ngIf="(isLoading || !isLoadingError); else loadingerror">
                        <ng-container *ngIf="filteredContacts?.length; else noContacts">
                            <ng-container *ngFor="let contact of filteredContacts; let i = index; trackBy: trackByFn">
                                <!-- Group -->
                                <ng-container
                                    *ngIf="i === 0 || contact?.firstName?.charAt(0) !== filteredContacts[i - 1]?.firstName?.charAt(0)">
                                    <div
                                        class="z-10 sticky top-0 -mt-px px-6 py-1 md:px-8 border-t border-b font-medium uppercase text-white bg-accent bg-opacity-50 dark:bg-gray-900">
                                        {{contact?.firstName?.charAt(0)}}
                                    </div>
                                </ng-container>
                                <!-- Contact -->
                                <ng-container *ngIf="hasDiagnostic">
                                    <a [routerLink]="['../detail', contact.personId]">
                                    <ng-template [ngTemplateOutlet]="contactLineTemplate"
                                        [ngTemplateOutletContext]="{ contact: contact, translateStrings: translateStrings }"></ng-template>
                                    </a>
                                </ng-container>
                                <ng-container *ngIf="!hasDiagnostic">
                                    <a [routerLink]="" (click)="openLicenseUpdateDialog()">
                                        <ng-template [ngTemplateOutlet]="contactLineTemplate"
                                        [ngTemplateOutletContext]="{ contact: contact, translateStrings: translateStrings }"></ng-template>
                                    </a>
                                </ng-container>
                            </ng-container>

                            <!-- Load more -->
                            <div *ngIf="!reachedLimitSubject$.value" class="mx-auto my-12 items-center w-64">
                                <button *ngIf="!isSpecificLoading['loadMore']" class="w-64" mat-flat-button
                                    color="accent" (click)="loadMore()" [disabled]="isSpecificLoading['loadMore']">
                                    <span *ngIf="!isSpecificLoading['loadMore']" class="ml-2 mr-1">{{
                                        translateStrings.generic.loadMore }}</span>
                                </button>
                                <div *ngIf="isSpecificLoading['loadMore']" class="w-1 mx-auto">
                                    <mat-progress-spinner [diameter]="24"
                                        [mode]="'indeterminate'"></mat-progress-spinner>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <!-- No contacts -->
                    <ng-template #noContacts>
                        <div *ngIf="!isLoading"
                            class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">
                            {{translateStrings.client.noSearchResults}}. <a [routerLink]="" (click)="openModal()"
                                class="text-primary">{{translateStrings.client.create}}</a>.</div>
                    </ng-template>

                </div>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>


<!-- Main modal -->
<ng-template #userModal>
    <h3 mat-dialog-title>{{ translateStrings.client.addNew }}</h3>
    <mat-dialog-content #userFormModalContent>
        <form [formGroup]="addNewUserForm" (ngSubmit)="userFormSubmit()" #newUserForm="ngForm">
            <div class="flex flex-col gt-xs:flex-row p-7 gap-2">

                <mat-form-field class="flex-auto gt-xs:pr-3">
                    <mat-label>{{translateStrings.user.firstName}}</mat-label>
                    <input matInput formControlName="firstName">
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
                </mat-form-field>

                <mat-form-field class="flex-auto gt-xs:pl-3">
                    <mat-label>{{translateStrings.user.lastName}}</mat-label>
                    <input matInput formControlName="lastName">
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
                </mat-form-field>

                <mat-form-field class="flex-auto gt-xs:pl-3">
                    <mat-label>{{translateStrings.user.mobilePhoneNumber}}</mat-label>
                    <input matInput formControlName="mobilePhoneNumber">
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_outline:phone'"></mat-icon>
                    <div *ngIf="addNewUserForm.controls['mobilePhoneNumber'].invalid && (addNewUserForm.controls['mobilePhoneNumber'].dirty || addNewUserForm.controls['mobilePhoneNumber'].touched)"
                        class="text-red-600">{{translateStrings['generic']['validatorMobilePhoneNumber']}}
                    </div>
                </mat-form-field>

                <mat-form-field class="flex-auto gt-xs:pl-3">
                    <mat-label>{{translateStrings.user.email}}</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_outline:mail'"></mat-icon>
                </mat-form-field>

                <mat-form-field class="fuse-mat-textarea flex-auto">
                    <mat-label>{{translateStrings.user.address}}</mat-label>
                    <textarea matInput [rows]="3" formControlName="address" matTextareaAutosize></textarea>
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_outline:location-marker'"></mat-icon>
                </mat-form-field>

                <mat-form-field class="flex-auto gt-xs:pl-3">
                    <mat-label>{{translateStrings.user.sex}}</mat-label>
                    <mat-select formControlName="sex">
                        <mat-option value="M">{{translateStrings.user.male}}</mat-option>
                        <mat-option value="F">{{translateStrings.user.female}}</mat-option>
                        <mat-option value="ND">{{translateStrings.user.ND}}</mat-option>
                    </mat-select>
                    <!-- <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon> -->
                </mat-form-field>

                <mat-form-field class="flex-auto">
                    <mat-label>{{translateStrings.user.birthdate}}</mat-label>
                    <input matInput formControlName="birthdate" [matDatepicker]="birthdate_picker">
                    <mat-datepicker-toggle matSuffix [for]="birthdate_picker"></mat-datepicker-toggle>
                    <mat-datepicker #birthdate_picker startView="multi-year"></mat-datepicker>
                </mat-form-field>

                <mat-form-field *ngIf="!selectedUser" class="flex-auto gt-xs:pl-3">
                    <mat-label>{{translateStrings.client.referenceStore}}</mat-label>
                    <mat-select formControlName="store">
                        <ng-container *ngFor="let store of stores">
                            <mat-option [value]="store.storeId">{{store.name}}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_outline:shopping-bag'"></mat-icon>
                </mat-form-field>

                <mat-label>{{translateStrings.client.privacy}}<a href="https://beautycians.it/privacy-policy/"
                        target="_blank" class="text-accent-800">{{translateStrings.generic.privacyLink}}</a></mat-label>

            </div>

            <!-- Modal footer -->
            <div
                class="flex justify-between items-center py-6 mx-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                <div>
                    <button mat-button mat-dialog-close type="button"
                        class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">{{translateStrings.generic.close}}</button>
                </div>
                <div class="flex space-x-2">
                    <button type="submit" mat-flat-button [color]="'primary'" class="rounded-lg" [disabled]="isSpecificLoading['save']">
                        <span *ngIf="!isSpecificLoading['save']">
                            {{translateStrings.generic.save}}
                        </span>
                        <mat-progress-spinner *ngIf="isSpecificLoading['save']" [diameter]="24"
                            [mode]="'indeterminate'"></mat-progress-spinner>
                    </button>
                </div>
            </div>
        </form>
    </mat-dialog-content>
</ng-template>

<ng-template #loadingerror>
    <img src="/assets/images/ui/beautycians/error_503.jpg" alt="Error" class="w-2/6 h-1/6 mx-auto">
    <div class="text-2xl font-semibold tracking-tight text-center w-4/6 sm:w-full mx-auto">
        {{translateStrings.generic.error503}}</div>
</ng-template>


<ng-template #contactLineTemplate let-contact="contact" let-translateStrings="translateStrings">
    <div
        class="z-20 flex items-center px-6 py-4 md:px-8 cursor-pointer border-b justify-between hover:bg-accent hover:bg-opacity-10 dark:hover:bg-hover">

        <div class="flex items-center">
            <div class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                <ng-container *ngIf="contact.avatar">
                    <img class="object-cover w-full h-full" [src]="contact.avatar" alt="Contact avatar" />
                </ng-container>
                <ng-container *ngIf="!contact.avatar">
                    <div
                        class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-accent bg-opacity-70 text-white dark:bg-gray-700 dark:text-gray-200">
                        {{contact?.firstName?.charAt(0)}}
                    </div>
                </ng-container>
            </div>
            <div class="min-w-0 ml-4">
                <div class="font-medium leading-5 truncate">{{contact.firstName}}
                    {{contact.lastName}}</div>
                <div class="leading-5 truncate text-secondary"></div>
            </div>
        </div>
        <button (click)="$event.stopPropagation();  $event.preventDefault(); selectedUser = contact;" mat-icon-button
            [matMenuTriggerFor]="menu" aria-label="Toggle menu"
            [disabled]="isSpecificLoading['sendMedicalHistory'] && (selectedUser == contact)">
            <mat-icon *ngIf="!isSpecificLoading['sendMedicalHistory'] || (selectedUser != contact)">more_vert</mat-icon>
            <mat-progress-spinner *ngIf="isSpecificLoading['sendMedicalHistory'] && (selectedUser == contact)"
                [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
        </button>

        <mat-menu #menu="matMenu" (click)="$event.stopPropagation()">
            <button mat-menu-item (click)="$event.stopPropagation(); $event.preventDefault(); openModal()">
                <mat-icon>edit</mat-icon>
                <span>{{translateStrings.generic.edit}}</span>
            </button>
            <button mat-menu-item *ngIf="hasDiagnostic" (click)="sendMedicalHistory(contact)" [disabled]="!contact.account?.email">
                <mat-icon>alternate_email</mat-icon>
                <span>{{translateStrings.client.sendMedicalHistory}}</span>
            </button>
            <button mat-menu-item class="text-red-400" (click)="deleteContact(contact)">
                <mat-icon class="text-red-400">delete</mat-icon>
                <span>{{translateStrings.generic.delete}}</span>
            </button>
        </mat-menu>
    </div>
</ng-template>