export enum GruulsConstants {
    QUERY_API_ENDPOINT = 'api/executor/query',
    COMMAND_API_ENDPOINT = 'api/executor/command',
    LOGIN_API_ENDPOINT = 'api/executor/login'
}

export enum TTL_IN_MS {
    ONE_HOUR = 60 * 60 * 1000,
    ONE_DAY = 24 * 60 * 60 * 1000,
    ONE_WEEK = 7 * 24 * 60 * 60 * 1000,
    ONE_MONTH = 30 * 24 * 60 * 60 * 1000
}

export const ItalianProvinces = [{ key: "AG", value: "Agrigento (AG)" },
    { key: "AL", value: "Alessandria (AL)" },
    { key: "AN", value: "Ancona (AN)" },
    { key: "AO", value: "Aosta (AO)" },
    { key: "AR", value: "Arezzo (AR)" },
    { key: "AP", value: "Ascoli Piceno (AP)" },
    { key: "AT", value: "Asti (AT)" },
    { key: "AV", value: "Avellino (AV)" },
    { key: "BA", value: "Bari (BA)" },
    { key: "BT", value: "Barletta-Andria-Trani (BT)" },
    { key: "BL", value: "Belluno (BL)" },
    { key: "BN", value: "Benevento (BN)" },
    { key: "BG", value: "Bergamo (BG)" },
    { key: "BI", value: "Biella (BI)" },
    { key: "BO", value: "Bologna (BO)" },
    { key: "BZ", value: "Bolzano (BZ)" },
    { key: "BS", value: "Brescia (BS)" },
    { key: "BR", value: "Brindisi (BR)" },
    { key: "CA", value: "Cagliari (CA)" },
    { key: "CL", value: "Caltanissetta (CL)" },
    { key: "CB", value: "Campobasso (CB)" },
    { key: "CI", value: "Carbonia-Iglesias (CI)" },
    { key: "CE", value: "Caserta (CE)" },
    { key: "CT", value: "Catania (CT)" },
    { key: "CZ", value: "Catanzaro (CZ)" },
    { key: "CH", value: "Chieti (CH)" },
    { key: "CO", value: "Como (CO)" },
    { key: "CS", value: "Cosenza (CS)" },
    { key: "CR", value: "Cremona (CR)" },
    { key: "KR", value: "Crotone (KR)" },
    { key: "CN", value: "Cuneo (CN)" },
    { key: "EN", value: "Enna (EN)" },
    { key: "FM", value: "Fermo (FM)" },
    { key: "FE", value: "Ferrara (FE)" },
    { key: "FI", value: "Firenze (FI)" },
    { key: "FG", value: "Foggia (FG)" },
    { key: "FC", value: "Forlì-Cesena (FC)" },
    { key: "FR", value: "Frosinone (FR)" },
    { key: "GE", value: "Genova (GE)" },
    { key: "GO", value: "Gorizia (GO)" },
    { key: "GR", value: "Grosseto (GR)" },
    { key: "IM", value: "Imperia (IM)" },
    { key: "IS", value: "Isernia (IS)" },
    { key: "SP", value: "La Spezia (SP)" },
    { key: "AQ", value: "L'Aquila (AQ)" },
    { key: "LT", value: "Latina (LT)" },
    { key: "LE", value: "Lecce (LE)" },
    { key: "LC", value: "Lecco (LC)" },
    { key: "LI", value: "Livorno (LI)" },
    { key: "LO", value: "Lodi (LO)" },
    { key: "LU", value: "Lucca (LU)" },
    { key: "MC", value: "Macerata (MC)" },
    { key: "MN", value: "Mantova (MN)" },
    { key: "MS", value: "Massa-Carrara (MS)" },
    { key: "MT", value: "Matera (MT)" },
    { key: "ME", value: "Messina (ME)" },
    { key: "MI", value: "Milano (MI)" },
    { key: "MO", value: "Modena (MO)" },
    { key: "MB", value: "Monza e della Brianza (MB)" },
    { key: "NA", value: "Napoli (NA)" },
    { key: "NO", value: "Novara (NO)" },
    { key: "NU", value: "Nuoro (NU)" },
    { key: "OT", value: "Olbia-Tempio (OT)" },
    { key: "OR", value: "Oristano (OR)" },
    { key: "PD", value: "Padova (PD)" },
    { key: "PA", value: "Palermo (PA)" },
    { key: "PR", value: "Parma (PR)" },
    { key: "PV", value: "Pavia (PV)" },
    { key: "PG", value: "Perugia (PG)" },
    { key: "PU", value: "Pesaro e Urbino (PU)" },
    { key: "PE", value: "Pescara (PE)" },
    { key: "PC", value: "Piacenza (PC)" },
    { key: "PI", value: "Pisa (PI)" },
    { key: "PT", value: "Pistoia (PT)" },
    { key: "PN", value: "Pordenone (PN)" },
    { key: "PZ", value: "Potenza (PZ)" },
    { key: "PO", value: "Prato (PO)" },
    { key: "RG", value: "Ragusa (RG)" },
    { key: "RA", value: "Ravenna (RA)" },
    { key: "RC", value: "Reggio Calabria (RC)" },
    { key: "RE", value: "Reggio Emilia (RE)" },
    { key: "RI", value: "Rieti (RI)" },
    { key: "RN", value: "Rimini (RN)" },
    { key: "RM", value: "Roma (RM)" },
    { key: "RO", value: "Rovigo (RO)" },
    { key: "SA", value: "Salerno (SA)" },
    { key: "VS", value: "Medio Campidano (VS)" },
    { key: "SS", value: "Sassari (SS)" },
    { key: "SV", value: "Savona (SV)" },
    { key: "SI", value: "Siena (SI)" },
    { key: "SR", value: "Siracusa (SR)" },
    { key: "SO", value: "Sondrio (SO)" },
    { key: "TA", value: "Taranto (TA)" },
    { key: "TE", value: "Teramo (TE)" },
    { key: "TR", value: "Terni (TR)" },
    { key: "TO", value: "Torino (TO)" },
    { key: "OG", value: "Ogliastra (OG)" },
    { key: "TP", value: "Trapani (TP)" },
    { key: "TN", value: "Trento (TN)" },
    { key: "TV", value: "Treviso (TV)" },
    { key: "TS", value: "Trieste (TS)" },
    { key: "UD", value: "Udine (UD)" },
    { key: "VA", value: "Varese (VA)" },
    { key: "VE", value: "Venezia (VE)" },
    { key: "VB", value: "Verbano-Cusio-Ossola (VB)" },
    { key: "VC", value: "Vercelli (VC)" },
    { key: "VR", value: "Verona (VR)" },
    { key: "VV", value: "Vibo Valentia (VV)" },
    { key: "VI", value: "Vicenza (VI)" },
    { key: "VT", value: "Viterbo (VT)" }]