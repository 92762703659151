import { BehaviorSubject, EMPTY, Observable, map, mergeMap, of, switchMap, take, tap } from "rxjs";
import { Cabin, Store } from "../utils/dataTypes";
import { ApiCaller } from "../utils/apiCaller";
import { GruulsAngularHttpProxyService } from "@gruuls-fe/services/gruuls-angular-http-proxy.service";
import { GruulsAuthService } from "@gruuls-fe/services/gruuls-auth.service";
import { Utils } from "@gruuls-core/utils/Utils";
import { GruulsAngularBrowserCacheService } from "@gruuls-fe/services/gruuls-angular-browser-cache-service";
import { GruulsAngularCabinService } from "@gruuls-fe/services/gruuls-angular-cabin-service";

export class StoreService {
    // decided to duplicate this as an Angular service in order to use it as an Injectable
    private _store: BehaviorSubject<Store | null> = new BehaviorSubject(null);
    private _activeStore: BehaviorSubject<Store | null> = new BehaviorSubject(null);
    private _stores: BehaviorSubject<Store[] | null> = new BehaviorSubject(null);
    private _lastSubscriptionTime: number = null;
    private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);


    constructor(
        private _httpClient: GruulsAngularHttpProxyService,
        private _authService: GruulsAuthService,
        private browserCacheService: GruulsAngularBrowserCacheService,
        private cabinService: GruulsAngularCabinService
    ) {
        if (this.browserCacheService) {
            const cacheObject = this.browserCacheService.get("activeStore");
            if (cacheObject)
                this.setActiveStore(cacheObject);
        };
    }

    get activeStore(): Store {
        return this._activeStore.getValue();
    }

    get activeStore$(): Observable<Store> {
        return this._activeStore.asObservable();
    }

    get store$(): Observable<Store> {
        return this._store.asObservable();
    }

    get stores$(): Observable<Store[]> {
        return this._stores.asObservable();
    }

    getStores(): Observable<Store[]> {
        if (this._authService && this._authService.isAuthenticated()) {
            return this._apiCaller.getStores(this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId).pipe(
                tap((stores) => {
                    this._lastSubscriptionTime = Date.now();
                    this._stores.next(Utils.sortByKeys(stores, ['name', 'address']));
                    if (stores.length > 0 && !this._activeStore.getValue()) {
                        this._activeStore.next(stores[0]);
                    } else {
                        this._activeStore.next(null);
                    }
                })
            );
        } else {
            this._stores.next([]);
            return of([]);
        }
    }

    refreshStores(): Observable<Store[]> {
        const now = Date.now();
        const UPDATE_DELTA = 15 * 60 * 1000;

        if (this._lastSubscriptionTime == null || (now - this._lastSubscriptionTime) > UPDATE_DELTA) {
            this._lastSubscriptionTime = Date.now();
            return this.getStores();
        } else {
            return of(this._stores.getValue());
        }
    }

    getStoreById(storeId: string): Observable<Store> {
        return this._apiCaller.getStore(storeId).pipe(
            tap((store) => {
                this._store.next(store);
            })
        );
    }

    getActiveStore(): Observable<Store> {
        if (!this._activeStore.getValue()) {
            // throw new Error('Active store is not set');
            return EMPTY;
        }
        return of(this._activeStore.getValue());
    }

    setActiveStore(store: Store | null): Observable<Store | null> {
        if (!store) {
            store = this.activeStore;
        }
        return this.cabinService.getCabinsByStoreId(store.storeId).pipe(
            map((cabins) => {
                store.cabins = cabins;
                this._activeStore.next(store);
                this.browserCacheService.set("activeStore", store, 30 * 24 * 3600);
                return store;
            })
        );
    }

    emptyActiveStore(): void {
        return this._activeStore.next(null);
    }

    createStore(store: Store): Observable<Store> {
        store.organization.organizationId = this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId;
        return this.stores$.pipe(
            take(1),
            switchMap(stores => this._apiCaller.createStore(store).pipe(
                map((newStore: Store) => {
                    // Update the products with the new product
                    this._stores.next([newStore, ...stores]);
                    // Return the new product
                    return newStore;
                }),
                mergeMap((newStore: Store) => {
                    if (stores.length === 0) {
                        return this.setActiveStore(newStore).pipe(
                            map(() => newStore)
                        );
                    }
                    return of(newStore);
                }),
            ))
        );
    }

    createStoreCabin(storeId: string, cabin: Cabin): Observable<any> {
        // TODO: this should be tested
        return this.cabinService.createCabin(cabin).pipe(
            mergeMap((newCabin) => {
                if (this.activeStore.storeId === storeId) {
                    return this.setActiveStore(this.activeStore).pipe(
                        map(() => newCabin)
                    );
                }
            })
        );
    }

    updateStore(store: Store): Observable<Store> {
        if (!store.storeId) {
            throw new Error('Store id is required');
        }

        return this.stores$.pipe(
            take(1),
            switchMap(stores => this._apiCaller.updateStore(store).pipe(
                map((updatedStore) => {

                    // Find the index of the updated client
                    const index = stores.findIndex(item => item.storeId === store.storeId);
                    stores[index] = updatedStore;
                    this._stores.next(stores);

                    // Return the updated client
                    return updatedStore;
                }),
                mergeMap((newStore: Store) => {
                    if (this.activeStore.storeId === store.storeId) {
                        return this.setActiveStore(newStore).pipe(
                            map(() => newStore)
                        );
                    }
                    return of(newStore);
                }),
            )
            ))
    }

    deleteStore(storeId: string): Observable<boolean> {
        return this.stores$.pipe(
            take(1),
            switchMap(stores => this._apiCaller.deleteStore(storeId).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted client
                    const index = stores.findIndex(item => item.storeId === storeId);

                    // Delete the client
                    stores.splice(index, 1);

                    // Update the clients
                    this._stores.next(stores);

                    // Return the deleted status
                    return isDeleted;
                }),
                mergeMap((isDeleted: boolean) => {
                    if (this._activeStore.getValue()?.storeId === storeId) {
                        if (stores.length > 0) {
                            return this.setActiveStore(stores[0]).pipe(
                                map(() => isDeleted)
                            );
                        } else {
                            this.emptyActiveStore();
                        }
                    }
                    return of(isDeleted);
                }),

            ))
        );
    }
}