import {Observable, throwError} from 'rxjs';
import {map} from 'rxjs/operators';
import {Domain} from '../domain/domain';
import { GruulsAuthServiceInterface } from '@gruuls-core/service-interfaces/gruuls-auth-service-interface';
import {GruulsConstants} from '../../utils/gruuls-constants';
import {Utils} from '../../utils/Utils';
import {GruulsHttpProxyInterface, PostData} from '../../service-interfaces/gruuls-http-proxy-interface';

export class CommandSchemaRepository {

    constructor(
        private proxy: GruulsHttpProxyInterface,
        private domain: Domain,
        private authService: GruulsAuthServiceInterface,
        private commandExecutorUrl: string
    ) {
        if (!commandExecutorUrl){
            this.commandExecutorUrl = GruulsConstants.COMMAND_API_ENDPOINT;
        }
    }

    public createNewEntity(formData: any, assembleAs: any): Observable<any> {
        if (!assembleAs) {
            assembleAs = this.domain.getSchema().getDefaultAssembleAs();
        }

        const currentUser = this.authService.getCurrentLoggedUser();
        const body = {
            contextName: this.domain.getContextName(),
            domainName: this.domain.getDomainName(),
            commandName: 'CREATE',
            commandType: 'CREATE_AGGREGATE_COMMAND',
            commandId: Utils.guid(),
            userOrganizationId: currentUser.getSelectedOrganization().organizationId,
            body: {
                aggregate: formData,
                assembleAs: assembleAs
            }
        };

        const postData: PostData = {
            url: this.commandExecutorUrl,
            body: body,
            headers: {
                // token sky
                // authorization: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpIjpudWxsLCJ1Ijoic2t5Lml0YWxpYSIsImYiOiJTa3kiLCJsIjoiSXRhbGlhIiwicyI6bnVsbCwicCI6Ijg4NmQxOGZhLTNiMWQtNDRlYS1hOTI1LWZhZGIxMWExYjI1NyIsImV4cCI6MTU5NTA2Nzk4NX0.6dm1GNYxQ5nRM7hcTa1i_MtLgBKmkpXuvdrf0HtVi3Ig4cgKYsjb5cYALkCOpQVTGmFFBHHtOmhc6fNzJH4kdg"
            }
        };

        return this.proxy.doPost(postData)
            .pipe(
                map((res: any) => res.hits[0])
            );
    }

    public deleteEntityOrPartial(keyFieldValue: any, formData: any, assembleAs: any): Observable<any> {
        const keyFieldName = this.domain.getSchema().getKeyFieldName();
        if (!assembleAs) {
            assembleAs = {};
            assembleAs[keyFieldName] = true;
        }

        const match_phrase = {};
        match_phrase[keyFieldName] = {query: keyFieldValue};
        const where = {bool: {must: [{match_phrase: match_phrase}]}};

        const currentUser = this.authService.getCurrentLoggedUser();
        const body = {
            contextName: this.domain.getContextName(),
            domainName: this.domain.getDomainName(),
            commandName: 'DELETE',
            commandType: 'DELETE_AGGREGATE_COMMAND',
            commandId: Utils.guid(),
            userOrganizationId: currentUser.getSelectedOrganization().organizationId,
            body: {
                where: where,
                aggregate: formData,
                assembleAs: assembleAs
            }
        };

        const postData: PostData = {
            url: this.commandExecutorUrl,
            body: body,
            headers: {
                // token sky
                // authorization: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpIjpudWxsLCJ1Ijoic2t5Lml0YWxpYSIsImYiOiJTa3kiLCJsIjoiSXRhbGlhIiwicyI6bnVsbCwicCI6Ijg4NmQxOGZhLTNiMWQtNDRlYS1hOTI1LWZhZGIxMWExYjI1NyIsImV4cCI6MTU5NTA2Nzk4NX0.6dm1GNYxQ5nRM7hcTa1i_MtLgBKmkpXuvdrf0HtVi3Ig4cgKYsjb5cYALkCOpQVTGmFFBHHtOmhc6fNzJH4kdg"
            }
        };

        return this.proxy.doPost(postData)
            .pipe(
                map((res: any) => res.hits[0])
            );
    }

    public updateExistingEntity(formData: any, assembleAs: any): Observable<any> {
        if (!assembleAs) {
            assembleAs = this.domain.getSchema().getDefaultAssembleAs();
        }

        const keyFieldName = this.domain.getSchema().getKeyFieldName();

        if (!keyFieldName) {
            return throwError('Could not perform Update command since given schema doesn\'t have any key field. ContextName:\'' + this.domain.getContextName() + '\' DomainName:\'' + this.domain.getDomainName() + '\' KeyFieldName:\'' + keyFieldName + '\' formData:\'' + JSON.stringify(formData) + '\'');
        }

        const keyFieldValue = formData[keyFieldName];

        if (!keyFieldValue) {
            return throwError('Could not perform Update command since given data doesn\'t have value for schema key. ContextName:\'' + this.domain.getContextName() + '\' DomainName:\'' + this.domain.getDomainName() + '\' KeyFieldName:\'' + keyFieldName + '\' formData:\'' + JSON.stringify(formData) + '\'');
        }

        const match_phrase = {};
        match_phrase[keyFieldName] = {query: keyFieldValue};
        const where = {bool: {must: [{match_phrase: match_phrase}]}};

        const currentUser = this.authService.getCurrentLoggedUser();
        const body = {
            contextName: this.domain.getContextName(),
            domainName: this.domain.getDomainName(),
            commandName: 'UPDATE',
            commandType: 'UPDATE_AGGREGATE_COMMAND',
            commandId: Utils.guid(),
            userOrganizationId: currentUser.getSelectedOrganization().organizationId,
            body: {
                where: where,
                aggregate: formData,
                assembleAs: assembleAs
            }
        };

        const postData: PostData = {
            url: this.commandExecutorUrl,
            body: body,
            headers: {
                // token sky
                // authorization: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpIjpudWxsLCJ1Ijoic2t5Lml0YWxpYSIsImYiOiJTa3kiLCJsIjoiSXRhbGlhIiwicyI6bnVsbCwicCI6Ijg4NmQxOGZhLTNiMWQtNDRlYS1hOTI1LWZhZGIxMWExYjI1NyIsImV4cCI6MTU5NTA2Nzk4NX0.6dm1GNYxQ5nRM7hcTa1i_MtLgBKmkpXuvdrf0HtVi3Ig4cgKYsjb5cYALkCOpQVTGmFFBHHtOmhc6fNzJH4kdg"
            }
        };

        return this.proxy.doPost(postData)
            .pipe(
                map((res: any) => res.hits[0])
            );
    }

    public executeCustomCommand(commandName: string, formData: any, assembleAs: any): Observable<any> {
        if (!assembleAs) {
            assembleAs = this.domain.getSchema().getDefaultAssembleAs();
        }

        const keyFieldName = this.domain.getSchema().getKeyFieldName();

        let where;
        if (!keyFieldName) {
            console.warn('The given data doesn\'t have any key field. ContextName:\'' + this.domain.getContextName() + '\' DomainName:\'' + this.domain.getDomainName() + '\' KeyFieldName:\'' + keyFieldName + '\' formData:\'' + JSON.stringify(formData) + '\'');
            // return throwError("Could not perform Update command since given schema doesn't have any key field. ContextName:'" + this.domain.getContextName() + "' DomainName:'" + this.domain.getDomainName() + "' KeyFieldName:'" + keyFieldName + "' formData:'" + JSON.stringify(formData) + "'");
        }else{
            const keyFieldValue = formData[keyFieldName];
            if (!keyFieldValue) {
                return throwError('Could not perform Update command since given data doesn\'t have value for schema key. ContextName:\'' + this.domain.getContextName() + '\' DomainName:\'' + this.domain.getDomainName() + '\' KeyFieldName:\'' + keyFieldName + '\' formData:\'' + JSON.stringify(formData) + '\'');
            }

            const match_phrase = {};
            match_phrase[keyFieldName] = {query: keyFieldValue};
            where = {bool: {must: [{match_phrase: match_phrase}]}};
        }


        const currentUser = this.authService.getCurrentLoggedUser();
        const body = {
            contextName: this.domain.getContextName(),
            domainName: this.domain.getDomainName(),
            commandName: commandName,
            commandType: 'NORMAL_COMMAND',
            commandId: Utils.guid(),
            userOrganizationId: currentUser.getSelectedOrganization().organizationId,
            body: {
                where: where,
                aggregate: formData,
                assembleAs: assembleAs
            }
        };

        const postData: PostData = {
            url: this.commandExecutorUrl,
            body: body,
            headers: {
                // token sky
                // authorization: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpIjpudWxsLCJ1Ijoic2t5Lml0YWxpYSIsImYiOiJTa3kiLCJsIjoiSXRhbGlhIiwicyI6bnVsbCwicCI6Ijg4NmQxOGZhLTNiMWQtNDRlYS1hOTI1LWZhZGIxMWExYjI1NyIsImV4cCI6MTU5NTA2Nzk4NX0.6dm1GNYxQ5nRM7hcTa1i_MtLgBKmkpXuvdrf0HtVi3Ig4cgKYsjb5cYALkCOpQVTGmFFBHHtOmhc6fNzJH4kdg"
            }
        };

        return this.proxy.doPost(postData)
            .pipe(
                map((res: any) => res.hits[0])
            );
    }


    public deleteEntityById(entityId: any): Observable<boolean> {
        return this.deleteFieldsOfEntityById(entityId, true);
    }

    public deleteFieldsOfEntityById(entityId: any, fieldsToDeleteMap: any): Observable<boolean> {
        const currentUser = this.authService.getCurrentLoggedUser();
        const body = {
            contextName: this.domain.getContextName(),
            domainName: this.domain.getDomainName(),
            commandName: 'DELETE',
            commandType: 'DELETE_COMMAND',
            commandId: Utils.guid(),
            userOrganizationId: currentUser.getSelectedOrganization().organizationId,
            body: {
                where: {
                    _key: entityId
                },
                aggregate: fieldsToDeleteMap
            }
        };

        const postData: PostData = {
            url: this.commandExecutorUrl,
            body: body,
            headers: {
                // token sky
                // authorization: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpIjpudWxsLCJ1Ijoic2t5Lml0YWxpYSIsImYiOiJTa3kiLCJsIjoiSXRhbGlhIiwicyI6bnVsbCwicCI6Ijg4NmQxOGZhLTNiMWQtNDRlYS1hOTI1LWZhZGIxMWExYjI1NyIsImV4cCI6MTU5NTA2Nzk4NX0.6dm1GNYxQ5nRM7hcTa1i_MtLgBKmkpXuvdrf0HtVi3Ig4cgKYsjb5cYALkCOpQVTGmFFBHHtOmhc6fNzJH4kdg"
            }
        };

        return this.proxy.doPost(postData)
            .pipe(
                map((res: any) => true)
            );
    }

    public getAvailableCommands(): Observable<any> {
        return throwError('Not yet Implemented');
    }


}
