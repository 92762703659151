import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import _ from 'lodash';
import { AppointmentsService } from 'app/beautycians/services/appointmentsService';
import { GruulsAngularHttpProxyService } from './gruuls-angular-http-proxy.service';
import { GruulsAuthService } from './gruuls-auth.service';
import { Appointment } from 'app/beautycians/modules/clients/client.types';
import { GruulsAngularBrowserCacheService } from './gruuls-angular-browser-cache-service';
import { GruulsAngularStoreService } from './gruuls-angular-stores-service';

@Injectable()
export class GruulsAngularAppointmentsService{
  private appointmentsService: AppointmentsService;

  public constructor(
    private _httpClient: GruulsAngularHttpProxyService,
    private _authService: GruulsAuthService,
    private browserCacheService: GruulsAngularBrowserCacheService,
    private storeService: GruulsAngularStoreService
  ){
    this.appointmentsService = new AppointmentsService(_httpClient, _authService, browserCacheService, storeService);
  }

  get appointments$(): Observable<Appointment[]> {
    return this.appointmentsService.appointments$;
  }

  get clientAppointments$(): Observable<Appointment[]> {
    return this.appointmentsService.clientAppointments$;
  }

  public getAppointments(start: number = null, end: number = null): Observable<Appointment[]> {
    return this.appointmentsService.getAppointments(start, end);
  }

  public getClientAppointments(clientId: string): Observable<any> {
    return this.appointmentsService.getClientAppointments(clientId);
  }

  // public refreshAppointments(start: number = null, end: number = null, storeId: string = null): Observable<any> {
  //   return this.appointmentsService.refreshAppointments(start, end, storeId);
  // }

  public updateAppointment(appointment: any): Observable<any> {
    return this.appointmentsService.updateAppointment(appointment);
  }

  public createAppointment(appointment: any): Observable<any> {
    return this.appointmentsService.createAppointment(appointment);
  }

  public deleteAppointment(appointment: any): Observable<any> {
    return this.appointmentsService.deleteAppointment(appointment);
  }

}
