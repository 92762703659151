import { CabinService } from "app/beautycians/services/cabinService";
import { Cabin } from "app/beautycians/utils/dataTypes";
import { GruulsAngularHttpProxyService } from "./gruuls-angular-http-proxy.service";
import { GruulsAuthService } from "./gruuls-auth.service";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";


@Injectable()
export class GruulsAngularCabinService {
    cabinService: CabinService;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: GruulsAngularHttpProxyService,
        private _authService: GruulsAuthService
    ) {
        this.cabinService = new CabinService(this._httpClient, this._authService);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors

    /**
     * Getter for cabins
     */
    get cabins$(): Observable<Cabin[]> {
        return this.cabinService.cabins$;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get cabins
     */
    getCabinsByStoreId(storeId: string): Observable<Cabin[]> {
        return this.cabinService.getCabinsByStoreId(storeId);
    }

    /**
     * Create cabin
     */
    createCabin(cabin: Cabin): Observable<Cabin> {
        return this.cabinService.createCabin(cabin);
    }

    updateCabin(cabin: Cabin): Observable<Cabin> {
        return this.cabinService.updateCabin(cabin);
    }

    deleteCabin(cabin: Cabin): Observable<boolean> {
        return this.cabinService.deleteCabin(cabin.cabinId);
    }

}
