import { GruulsMockDomain, GruulsMockHttpRequest } from '../../gruuls-mock-domain';
import { ElementCatalogConfigInterface } from '@gruuls-core/elements/ui/element-catalog/element-catalog-config';
import { HttpClient } from '@angular/common/http';
import { GruulsCoreMenuMockDomain } from './gruuls-core-menu-mock-domain';

interface MenuItem {
    id: string;
    title: string;
    type: string;
    icon: string;
    link: string;
    roles?: string[];
}

export class GruulsCorePageMockDomain extends GruulsMockDomain {

    constructor(
        httpClient: HttpClient,
    ) {
        super(httpClient);
        this.entities = this.pages;
    }

    // Questa array contiene la definizione del menu di navigazione
    pages = [
        // Questa è una singola pagina / porzione di pagina (qui non è definita la rotta), fa riferimento a un elemento
        {
            // id della pagina, la identifica
            id: 'beautyRoot',
            // Nome della pagina (nome comodo per l'utente)
            name: 'Context',
            // Tipo di elemento
            type: 'ContextElement',
            disableFlex: true,
            width: 'full',
            elements: [
                {
                    name: 'Navigation',
                    type: 'VerticalNavigationElement',
                    height: 'full',
                    customClasses: 'dark bg-accent print:hidden',
                    chainElementConfigs: [
                        (message, context, element) => {
                            if (!context.authService) return;
                            // TODO: here I'd like to read from GruulsCoreMenuMockDomain
                            const menu = [
                                {
                                    id: 'client',
                                    title: 'Clienti',
                                    subtitle: 'Gestisci i tuoi clienti',
                                    type: 'group',
                                    icon: 'heroicons_outline:user-group',
                                    children: [
                                        {
                                            id: 'client.list',
                                            title: 'Clienti',
                                            type: 'basic',
                                            icon: 'heroicons_outline:users',
                                            link: '/beauty/client/list'
                                        },
                                        {
                                            id: 'client.appointment',
                                            title: 'Appuntamenti',
                                            type: 'basic',
                                            icon: 'heroicons_outline:calendar',
                                            link: '/beauty/client/appointment'
                                        },
                                    ]
                                },
                                {
                                    id: 'owner',
                                    title: 'Account',
                                    subtitle: 'Gestisci gli account della catena',
                                    roles: ['Beautycians-HQ','Beautycians-HQ-Sales'],
                                    type: 'group',
                                    icon: 'heroicons_outline:user-group',
                                    children: [
                                        {
                                            id: 'centri.list',
                                            title: 'Centri',
                                            type: 'basic',
                                            icon: 'heroicons_outline:building-storefront',
                                            link: '/beauty/centri/list'
                                        },
                                        {
                                            id: 'owner.list',
                                            title: 'Account',
                                            type: 'basic',
                                            icon: 'heroicons_outline:users',
                                            link: '/beauty/owner/list'
                                        },
                                        // {
                                        //     id: 'student.list',
                                        //     title: 'Studenti',
                                        //     type: 'basic',
                                        //     icon: 'heroicons_outline:academic-cap',
                                        //     link: '/beauty/student/list'
                                        // }
                                    ]
                                },
                                {
                                    id: 'store',
                                    title: 'Punti vendita',
                                    roles: ['Beautycians-Stores-Owner', 'Beautycians-Store-Manager', 'Beautycians-Stores-Manager', 'Beautycians-HQ'],                                    subtitle: 'Gestisci i negozi della catena',
                                    type: 'group',
                                    children: [
                                        {
                                            id: 'store.list',
                                            title: 'Punti vendita',
                                            type: 'basic',
                                            icon: 'heroicons_outline:location-marker',
                                            link: '/beauty/store/list'
                                        },
                                        {
                                            id: 'operator.list',
                                            title: 'Collaboratori',
                                            type: 'basic',
                                            icon: 'heroicons_outline:briefcase',
                                            link: '/beauty/operator/list'

                                        }
                                    ]
                                },
                                {
                                    id: 'product',
                                    title: 'Acquisti',
                                    subtitle: 'Gestisci i tuoi acquisti',
                                    type: 'group',
                                    children: [
                                        {
                                            id: 'product.list',
                                            title: 'Prodotti',
                                            type: 'basic',
                                            icon: 'heroicons_outline:shopping-bag',
                                            link: '/beauty/product/'
                                        },
                                        {
                                            id: 'order.list',
                                            title: 'Ordini',
                                            type: 'basic',
                                            icon: 'heroicons_outline:truck',
                                            link: '/beauty/order/list'
                                        },
                                        {
                                            id: 'invoices',
                                            title: 'Fatture',
                                            type: 'basic',
                                            icon: 'heroicons_outline:document-text',
                                            link: '/beauty/invoices'
                                        }
                                        // {
                                        //     id: 'file-manager.list',
                                        //     title: 'Fatture',
                                        //     type: 'basic',
                                        //     icon: 'heroicons_outline:document-text',
                                        //     link: '/beauty/invoices/'
                                        // }
                                    ]
                                }
                            ];

                            // filter menu and sub-items if a specific role is defined in the map
                            const userRoles = context.authService.getCurrentLoggedUser().roles.map((role) => role.hasRole[0].name);

                            const userMenu = menu.map((menuSection) => {
                                if (!menuSection.roles || userRoles.some((userRole) => menuSection.roles.includes(userRole))) {
                                    return {
                                        ...menuSection,
                                        children: menuSection.children ? menuSection.children.filter((menuItem: MenuItem) => menuItem.roles ? menuItem.roles.some((menuItemRole) => menuItemRole.includes(userRoles)) : true) : []
                                    }
                                }
                            });

                            element.config.navigation = userMenu.filter((menuSection) => menuSection != undefined);
                        }
                    ],
                    navigation: [],
                },
                {
                    name: 'MainElement',
                    type: 'DynamicFlexContainer',
                    layout: 'col',
                    width: 'full',
                    height: 'full',
                    elements: [
                        {
                            name: 'Header',
                            type: 'TopHeaderForVerticalLayoutElement',
                            disableFlex: true
                        },
                        {
                            name: 'mainRouterOutlet',
                            type: 'RouterOutletElement',
                            height: 'full',
                            width: 'full'
                        }
                    ]
                }
            ]
        },
        {
            id: 'sanuPages',
            name: 'Context',
            type: 'ContextElement',
            disableFlex: true,
            width: 'full',
            elements: [
                {
                    name: 'Navigation',
                    type: 'VerticalNavigationElement',
                    height: 'full',
                    customClasses: 'dark bg-accent print:hidden',
                    chainElementConfigs: [
                        (message, context, element) => {
                            if (!context.authService) return;
                            const menu = [
                                {
                                    id: 'client',
                                    title: 'Clienti',
                                    subtitle: 'Gestisci i tuoi clienti',
                                    type: 'group',
                                    roles: null,
                                    icon: 'heroicons_outline:user-group',
                                    children: [
                                        {
                                            id: 'client.list',
                                            title: 'Clienti',
                                            type: 'basic',
                                            icon: 'heroicons_outline:users',
                                            link: '/beauty/client/list'
                                        },
                                        {
                                            id: 'client.appointment',
                                            title: 'Appuntamenti',
                                            type: 'basic',
                                            icon: 'heroicons_outline:calendar',
                                            link: '/beauty/client/appointment'
                                        },
                                    ]
                                }
                            ];

                            // filter menu and sub-items if a specific role is defined in the map
                            const userRoles = context.authService.getCurrentLoggedUser().roles.map((role) => role.hasRole[0].name);

                            const userMenu = menu.map((menuSection) => {
                                if (!menuSection.roles || userRoles.some((userRole) => menuSection.roles.includes(userRole))) {
                                    return {
                                        ...menuSection,
                                        children: menuSection.children ? menuSection.children.filter((menuItem: MenuItem) => menuItem.roles ? menuItem.roles.some((menuItemRole) => menuItemRole.includes(userRoles)) : true) : []
                                    }
                                }
                            });

                            element.config.navigation = userMenu.filter((menuSection) => menuSection != undefined);
                        }
                    ],
                    navigation: [],
                },
                {
                    name: 'MainElement',
                    type: 'DynamicFlexContainer',
                    layout: 'col',
                    width: 'full',
                    height: 'full',
                    elements: [
                        {
                            name: 'Header',
                            type: 'TopHeaderForVerticalLayoutElement',
                            disableFlex: true
                        },
                        {
                            name: 'mainRouterOutlet',
                            type: 'RouterOutletElement',
                            height: 'full',
                            width: 'full'
                        }
                    ]
                }
            ]
        },
        {
            id: 'gruulsHome',
            name: 'Context',
            type: 'ContextElement',
            disableFlex: true,
            width: 'full',
            elements: [
                {
                    name: 'MainElement',
                    type: 'DynamicFlexContainer',
                    layout: 'col',
                    width: 'full',
                    height: 'full',
                    elements: [
                        {
                            name: 'Header',
                            type: 'TopHeaderForVerticalLayoutElement'
                        },
                        {
                            name: 'mainRouterOutlet',
                            type: 'RouterOutletElement',
                            height: 'full',
                            width: 'full'
                        }
                    ]
                }
            ]
        },
        {
            id: 'gruulsAppHome',
            name: 'Context',
            type: 'ContextElement',
            disableFlex: true,
            width: 'full',
            elements: [
                {
                    name: 'Navigation',
                    type: 'VerticalNavigationElement',
                    height: 'full',
                    customClasses: 'dark bg-gray-900 print:hidden',
                    navigation: [
                        {
                            id: 'app.config',
                            title: 'App Config',
                            type: 'basic',
                            icon: 'heroicons_outline:users',
                            link: '/app/config'
                        },
                        {
                            id: 'app.pages',
                            title: 'Pages',
                            type: 'basic',
                            icon: 'heroicons_outline:users',
                            link: '/app/pages'
                        },
                        {
                            id: 'app.vars',
                            title: 'Variables',
                            type: 'basic',
                            icon: 'heroicons_outline:users',
                            link: '/app/vars'
                        },
                        {
                            id: 'app.templates',
                            title: 'Templates',
                            type: 'basic',
                            icon: 'heroicons_outline:users',
                            link: '/app/templates'
                        },
                        {
                            id: 'app.data-source',
                            title: 'DataSources',
                            type: 'basic',
                            icon: 'heroicons_outline:users',
                            link: '/app/data-sources'
                        },
                        {
                            id: 'app.users',
                            title: 'Users',
                            type: 'basic',
                            icon: 'heroicons_outline:users',
                            link: '/app/users'
                        },
                        {
                            id: 'app.roles',
                            title: 'Roles',
                            type: 'basic',
                            icon: 'heroicons_outline:users',
                            link: '/app/roles'
                        },
                        {
                            id: 'app.orgenizations',
                            title: 'Organizations',
                            type: 'basic',
                            icon: 'heroicons_outline:users',
                            link: '/app/organizations'
                        },
                        {
                            id: 'app.services',
                            title: 'Services',
                            type: 'basic',
                            icon: 'heroicons_outline:users',
                            link: '/app/services'
                        },
                        {
                            id: 'app.menus',
                            title: 'Menus',
                            type: 'basic',
                            icon: 'heroicons_outline:users',
                            link: '/app/menus'
                        },
                        {
                            id: 'app.login',
                            title: 'Login',
                            type: 'basic',
                            icon: 'heroicons_outline:users',
                            link: '/app/login'
                        },
                        {
                            id: 'app.domains',
                            title: 'Domains',
                            type: 'basic',
                            icon: 'heroicons_outline:users',
                            link: '/app/domains'
                        },
                        {
                            id: 'app.folders',
                            title: 'Folders',
                            type: 'basic',
                            icon: 'heroicons_outline:users',
                            link: '/app/folders'
                        },
                        {
                            id: 'app.api',
                            title: 'Api',
                            type: 'basic',
                            icon: 'heroicons_outline:users',
                            link: '/app/api'
                        },
                        {
                            id: 'app.modules',
                            title: 'Modules',
                            type: 'basic',
                            icon: 'heroicons_outline:users',
                            link: '/app/modules'
                        },
                        {
                            id: 'app.plugins',
                            title: 'Plugins',
                            type: 'basic',
                            icon: 'heroicons_outline:users',
                            link: '/app/plugins'
                        },
                        {
                            id: 'app.environments',
                            title: 'Environments',
                            type: 'basic',
                            icon: 'heroicons_outline:users',
                            link: '/app/environments'
                        },
                        {
                            id: 'app.test',
                            title: 'Test',
                            type: 'basic',
                            icon: 'heroicons_outline:users',
                            link: '/app/test'
                        },
                        {
                            id: 'app.custom-elements',
                            title: 'Custom Elements',
                            type: 'basic',
                            icon: 'heroicons_outline:users',
                            link: '/app/custom-elements'
                        }
                    ]
                },
                {
                    name: 'MainElement',
                    type: 'DynamicFlexContainer',
                    layout: 'col',
                    width: 'full',
                    height: 'full',
                    elements: [
                        {
                            name: 'Header',
                            type: 'TopHeaderForVerticalLayoutElement'
                        },
                        {
                            name: 'mainRouterOutlet',
                            type: 'RouterOutletElement',
                            height: 'full',
                            width: 'full'
                        }
                    ]
                }
            ]
        },
        {
            id: 'exampleNew',
            name: 'ExampleNew',
            type: 'ContextElement',
            width: 'screen',
            height: 'screen',
            overflow: 'hidden',
            elements: [
                {
                    name: 'MainPage',
                    type: 'DynamicFlexContainer',
                    layout: 'row',
                    logging: false,
                    width: 'full',
                    height: 'full',
                    elements: [
                        {
                            name: 'name',
                            type: 'BaseElement',
                            chainElementConfigs: [
                                'Hola',
                                {
                                    type: 'function',
                                    value: 'return msg.value + msg.value;'
                                }
                            ]
                        },
                        {
                            name: 'surname',
                            type: 'BaseElement',
                            chainElementConfigs: [
                                'Abi'
                            ]
                        },
                        {
                            name: 'form',
                            type: 'DynamicFlexContainer',
                            layout: 'col',
                            elements: [
                                {
                                    name: 'name',
                                    type: 'InputElement',
                                    label: 'name'
                                },
                                {
                                    name: 'surname',
                                    type: 'InputElement',
                                    label: 'surname'
                                },
                            ]
                        },
                        {
                            name: 'button',
                            label: 'passThrough',
                            type: 'ButtonElement',
                            inStreamElementIds: ['MainPage.form'],
                        },
                        {
                            name: 'custom1',
                            type: 'JsonElement',
                            inStreamElementIds: ['MainPage.button'],
                        }
                    ]
                }
            ]
        },
        {
            id: '0011100',
            name: 'Context',
            type: 'ContextElement',
            editMode: false,
            logging: false,
            width: 'screen',
            height: 'screen',
            overflow: 'hidden',
            elements: [
                {
                    name: 'MainPage',
                    type: 'DynamicFlexContainer',
                    layout: 'row',
                    logging: false,
                    width: 'full',
                    height: 'full',
                    elements: [
                        {
                            name: 'Catalog',
                            type: 'ElementCatalogList',
                            layout: 'col',
                            itemFillSpace: 'initial',
                            enableDragDrop: true,
                            dropSortEnabled: false,
                            dragEnabled: true,
                            elements: [
                                {
                                    name: 'Text',
                                    label: 'Text',
                                    type: 'ElementCatalog',
                                    factoryType: 'BaseElement',
                                    icon: 'mat_outline:short_text',
                                    itemFillSpace: 'initial',
                                    itemDisposition: 'middle-left'
                                } as ElementCatalogConfigInterface,
                                {
                                    name: 'Container',
                                    label: 'Container',
                                    type: 'ElementCatalog',
                                    factoryType: {
                                        type: 'DynamicFlexContainer',
                                        enableDragDrop: true,
                                        dropSortEnabled: true,
                                        dragEnabled: true
                                    },
                                    icon: 'mat_outline:auto_awesome_mosaic',
                                    itemFillSpace: 'initial',
                                    itemDisposition: 'middle-left'
                                } as ElementCatalogConfigInterface,
                                {
                                    name: 'Input',
                                    label: 'Input',
                                    type: 'ElementCatalog',
                                    factoryType: {
                                        type: 'InputElement',
                                        enableDragDrop: true,
                                        dropSortEnabled: true,
                                        dragEnabled: true,
                                        label: 'MyLabel'
                                    },
                                    icon: 'mat_outline:input',
                                    itemFillSpace: 'initial',
                                    itemDisposition: 'middle-left'
                                } as ElementCatalogConfigInterface,
                                {
                                    name: 'Textarea',
                                    label: 'Textarea',
                                    type: 'ElementCatalog',
                                    factoryType: {
                                        type: 'TextareaElement',
                                        enableDragDrop: true,
                                        dropSortEnabled: true,
                                        dragEnabled: true,
                                        label: 'MyTextarea'
                                    },
                                    icon: 'mat_outline:article',
                                    itemFillSpace: 'initial',
                                    itemDisposition: 'middle-left'
                                } as ElementCatalogConfigInterface,
                                {
                                    name: 'Radio',
                                    label: 'Radio',
                                    type: 'ElementCatalog',
                                    factoryType: {
                                        type: 'RadioElement',
                                        enableDragDrop: true,
                                        dropSortEnabled: true,
                                        dragEnabled: true,
                                        radioType: 'radio',
                                        radioValues: [
                                            {
                                                label: 'left',
                                                value: 'left'
                                            },
                                            {
                                                label: 'middle',
                                                value: 'middle'
                                            },
                                            {
                                                label: 'right',
                                                value: 'right'
                                            }
                                        ]
                                    },
                                    icon: 'mat_outline:radio_button_checked',
                                    itemFillSpace: 'initial',
                                    itemDisposition: 'middle-left'
                                } as ElementCatalogConfigInterface,
                                {
                                    name: 'Select',
                                    label: 'Select',
                                    type: 'ElementCatalog',
                                    factoryType: {
                                        type: 'SelectElement',
                                        enableDragDrop: true,
                                        dropSortEnabled: true,
                                        dragEnabled: true,
                                        width: 'full',
                                        label: 'MySelect',
                                        itemFillSpace: 'initial',
                                        values: [
                                            'online', 'offline', 'sleeping', 'paused',
                                            {
                                                value: 'loading',
                                                disabled: true
                                            },
                                            {
                                                value: 'toInitialize',
                                                disabled: true
                                            },
                                            {
                                                value: 'error',
                                                disabled: true
                                            },
                                            {
                                                value: 'initializing',
                                                disabled: true
                                            }]
                                    },
                                    icon: 'mat_outline:arrow_drop_down',
                                    itemFillSpace: 'initial',
                                    itemDisposition: 'middle-left'
                                } as ElementCatalogConfigInterface,
                                {
                                    name: 'Toggle',
                                    label: 'Toggle',
                                    type: 'ElementCatalog',
                                    factoryType: {
                                        type: 'ToggleElement',
                                        enableDragDrop: true,
                                        dropSortEnabled: true,
                                        dragEnabled: true,
                                        label: 'MyToggle'
                                    },
                                    icon: 'mat_outline:toggle_off',
                                    itemFillSpace: 'initial',
                                    itemDisposition: 'middle-left'
                                } as ElementCatalogConfigInterface,
                                {
                                    name: 'ColorPicker',
                                    label: 'ColorPicker',
                                    type: 'ElementCatalog',
                                    factoryType: {
                                        type: 'ColorPickerElement',
                                        enableDragDrop: true,
                                        dropSortEnabled: true,
                                        dragEnabled: true,
                                        itemDisposition: 'top-middle',
                                        chainElementConfigs: [{
                                            value: '#00ff00'
                                        }]
                                    },
                                    icon: 'mat_outline:colorize',
                                    itemFillSpace: 'initial',
                                    itemDisposition: 'middle-left'
                                } as ElementCatalogConfigInterface,
                                {
                                    name: 'Button',
                                    label: 'Button',
                                    type: 'ElementCatalog',
                                    factoryType: {
                                        type: 'ButtonElement',
                                        enableDragDrop: true,
                                        dropSortEnabled: true,
                                        dragEnabled: true,
                                        buttonType: 'raised',
                                        label: 'MyButton'
                                    },
                                    icon: 'mat_outline:smart_button',
                                    itemFillSpace: 'initial',
                                    itemDisposition: 'middle-left'
                                } as ElementCatalogConfigInterface,
                                {
                                    name: 'Image',
                                    label: 'Image',
                                    type: 'ElementCatalog',
                                    factoryType: {
                                        type: 'ImageElement',
                                        enableDragDrop: true,
                                        dropSortEnabled: true,
                                        dragEnabled: true,
                                        chainElementConfigs: [
                                            {
                                                order: 0,
                                                value: 'https://www.idmore.it/wp-content/uploads/2016/12/ef3-placeholder-image.jpg'
                                            }
                                        ]
                                    },
                                    icon: 'mat_outline:image',
                                    itemFillSpace: 'initial',
                                    itemDisposition: 'middle-left'
                                }
                            ]
                        },
                        {
                            name: 'PageContext',
                            type: 'ContextElement',
                            layout: 'col',
                            logging: false,
                            editMode: true,
                            enableDragDrop: true,
                            dropSortEnabled: true,
                            dragEnabled: true,
                            maxHeight: 'full',
                            overflow: 'y-auto',
                            elements: [
                                {
                                    name: 'Title',
                                    itemDisposition: 'middle-middle',
                                    height: 20,
                                    itemFillSpace: 'initial',
                                    fontFamily: 'mono',
                                    fontSize: '6xl',
                                    fontWeight: 'semibold'
                                },
                                {
                                    name: 'Form Elements',
                                    type: 'DynamicFlexContainer',
                                    layout: 'row',
                                    enableDragDrop: true,
                                    dropSortEnabled: true,
                                    dragEnabled: true,
                                    elements: [
                                        {
                                            name: 'FormElementsTitle',
                                            chainElementConfigs: [
                                                {
                                                    value: 'You can compose forms as you prefer, Just choose your form element and place it on the page'
                                                }],
                                            itemDisposition: 'middle-middle',
                                            width: '1/3',
                                            backgroundColor: 'rgba(192, 61, 61, 0.8)',
                                            borderRadiusTopLeft: '3xl',
                                            borderRadiusBottomRight: '3xl',
                                            marginLeft: 10,
                                            marginRight: 10,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            paddingLeft: 5,
                                            paddingRight: 5
                                        },
                                        {
                                            name: 'Form Elements',
                                            type: 'DynamicFlexContainer',
                                            layout: 'col',
                                            enableDragDrop: true,
                                            dropSortEnabled: true,
                                            dragEnabled: true,
                                            width: '1/3',
                                            logging: true,
                                            defaultValue: { address: { 'street': 'via vai', 'civic Number': 33, 'city': 'milano' } },
                                            elements: [
                                                {
                                                    name: 'titolone',
                                                    type: 'DynamicFlexContainer',
                                                    itemDisposition: 'top-middle',
                                                    itemFillSpace: 'initial',
                                                    layout: 'col',
                                                    width: 'full',
                                                    elements: [
                                                        {
                                                            name: 'titolo',
                                                            type: 'BaseElement',
                                                            itemDisposition: 'top-middle',
                                                            itemFillSpace: 'initial',
                                                            width: 'full',
                                                            chainElementConfigs: [
                                                                {
                                                                    value: 'MyTitle'
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            name: 'sottotitolo',
                                                            type: 'BaseElement',
                                                            itemDisposition: 'top-middle',
                                                            itemFillSpace: 'initial',
                                                            width: 'full',
                                                            chainElementConfigs: [
                                                                {
                                                                    value: 'MySubTitle'
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    name: 'name',
                                                    type: 'InputElement',
                                                    itemDisposition: 'top-middle',
                                                    itemFillSpace: 'initial',
                                                    width: 'full',
                                                    label: 'Name',
                                                    logging: false,
                                                    outputChainElementConfigs: [
                                                        {
                                                            name: 'NameValidator',
                                                            type: 'BaseElement',
                                                            chainElementConfigs: [{
                                                                type: 'function',
                                                                value: 'if (msg.value.length < 3) {' +
                                                                    '   return {value: msg.value, error: {errorCode: \'internal\', errorDescription: \' errore\'}}' +
                                                                    '}else{' +
                                                                    '   return msg;' +
                                                                    '}'
                                                            }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    name: 'note',
                                                    type: 'TextareaElement',
                                                    itemDisposition: 'top-middle',
                                                    itemFillSpace: 'initial',
                                                    width: 'full',
                                                    label: 'Notes'
                                                },
                                                {
                                                    name: 'address',
                                                    type: 'DynamicFlexContainer',
                                                    itemDisposition: 'top-middle',
                                                    itemFillSpace: 'initial',
                                                    layout: 'col',
                                                    width: 'full',
                                                    logging: true,
                                                    elements: [
                                                        {
                                                            name: 'street',
                                                            type: 'InputElement',
                                                            itemDisposition: 'top-middle',
                                                            itemFillSpace: 'initial',
                                                            width: 'full',
                                                            label: 'Street'
                                                        },
                                                        {
                                                            name: 'civic Number',
                                                            type: 'InputElement',
                                                            itemDisposition: 'top-middle',
                                                            itemFillSpace: 'initial',
                                                            width: 'full',
                                                            label: 'Civic Number'
                                                        },
                                                        {
                                                            name: 'city',
                                                            type: 'InputElement',
                                                            itemDisposition: 'top-middle',
                                                            itemFillSpace: 'initial',
                                                            width: 'full',
                                                            label: 'City'
                                                        },
                                                    ]
                                                },
                                                {
                                                    name: 'position1',
                                                    type: 'RadioElement',
                                                    itemsDisposition: 'top-middle',
                                                    itemFillSpace: 'auto',
                                                    width: 'full',
                                                    radioType: 'toggleButtonIcon',
                                                    paddingBottom: 5,
                                                    placeContent: 'between',
                                                    radioValues: [
                                                        {
                                                            icon: 'format_align_left',
                                                            value: 'left'
                                                        },
                                                        {
                                                            icon: 'format_align_center',
                                                            value: 'middle'
                                                        },
                                                        {
                                                            icon: 'format_align_right',
                                                            value: 'right'
                                                        }
                                                    ],
                                                    // chainElementConfigs: [{
                                                    //     order: -200,
                                                    //     value: 'left'
                                                    // }]
                                                },
                                                {
                                                    name: 'position2',
                                                    type: 'RadioElement',
                                                    itemsDisposition: 'top-middle',
                                                    containerItemFillSpace: 'initial',
                                                    itemFillSpace: 'initial',
                                                    width: 'full',
                                                    radioType: 'radio',
                                                    paddingBottom: 5,
                                                    layout: 'col',
                                                    // chainElementConfigs: [{
                                                    //     order: -200,
                                                    //     value: 'left'
                                                    // }],
                                                    radioValues: [
                                                        {
                                                            label: 'left',
                                                            value: 'left'
                                                        },
                                                        {
                                                            label: 'middle',
                                                            value: 'middle'
                                                        },
                                                        {
                                                            label: 'right',
                                                            value: 'right'
                                                        }
                                                    ],
                                                },
                                                {
                                                    name: 'position3',
                                                    type: 'RadioElement',
                                                    itemsDisposition: 'top-middle',
                                                    containerItemFillSpace: 'initial',
                                                    itemFillSpace: 'initial',
                                                    width: 'full',
                                                    radioType: 'toggleButton',
                                                    paddingBottom: 5,
                                                    radioValues: [
                                                        {
                                                            label: 'left',
                                                            value: 'left'
                                                        },
                                                        {
                                                            label: 'middle',
                                                            value: 'middle'
                                                        },
                                                        {
                                                            label: 'right',
                                                            value: 'right'
                                                        }
                                                    ],
                                                },
                                                {
                                                    name: 'status',
                                                    type: 'SelectElement',
                                                    itemDisposition: 'top-middle',
                                                    width: 'full',
                                                    label: 'Status',
                                                    itemFillSpace: 'initial',
                                                    defaultValue: 'paused',
                                                    // chainElementConfigs: [{
                                                    //     order: -200,
                                                    //     value: 'online'
                                                    // }],
                                                    values: [
                                                        'online', 'offline', 'sleeping', 'paused',
                                                        {
                                                            value: 'loading',
                                                            disabled: true
                                                        },
                                                        {
                                                            value: 'toInitialize',
                                                            disabled: true
                                                        },
                                                        {
                                                            value: 'error',
                                                            disabled: true
                                                        },
                                                        {
                                                            value: 'initializing',
                                                            disabled: true
                                                        }]
                                                },
                                                {
                                                    name: 'hidden',
                                                    type: 'ToggleElement',
                                                    itemDisposition: 'top-middle',
                                                    containerItemsDisposition: 'top-middle',
                                                    itemsDisposition: 'top-middle',
                                                    width: 'full',

                                                    // containerItemFillSpace : 'initial',
                                                    // itemFillSpace : 'initial',
                                                    label: 'Hidden',
                                                    paddingBottom: 5,
                                                },
                                                {
                                                    name: 'color',
                                                    type: 'ColorPickerElement',
                                                    itemDisposition: 'top-middle',
                                                    width: 'full',
                                                    chainElementConfigs: [{
                                                        order: 0,
                                                        value: '#00ff00'
                                                    }]
                                                }
                                            ]
                                        },
                                        {
                                            name: 'JsonForm',
                                            type: 'JsonElement',
                                            enableDragDrop: true,
                                            dropSortEnabled: true,
                                            dragEnabled: true,
                                            width: '1/3',
                                            inStreamElementIds: ['["Form Elements"]["Form Elements"]']
                                        }
                                    ]
                                },
                                {
                                    name: 'Element01_02',
                                    type: 'BaseElement',
                                    backgroundColor: 'rgba(192, 61, 61, 0.8)',
                                    defaultValue: '${Math.round(Math.random() * 100)}',
                                },
                                {
                                    name: 'Element01_03',
                                    type: 'ListElement',
                                    itemDisposition: 'top-middle',
                                    itemFillSpace: 'auto',
                                    autoUpdateMs: 10000,
                                    pauseAutoUpdate: false,
                                    defaultValue: '${Math.round(Math.random() * 100)}',
                                    useLastInValueWhenAutoUpdate: false,
                                    width: 'full',
                                    layout: 'row',
                                    appendElements: true,
                                    containerFlexWrap: 'wrap',
                                    flexWrap: 'wrap',
                                    elements: {
                                        name: 'Element01_02_ListElement',
                                        type: 'BaseElement',
                                        backgroundColor: 'rgba(192, 61, 61, 0.2)',
                                        paddingRight: '3',
                                        itemFillSpace: 'none'
                                    }
                                },
                                {
                                    name: 'Element01_04-Container',
                                    type: 'DynamicFlexContainer',
                                    layout: 'row',
                                    logging: false,
                                    enableDragDrop: true,
                                    dropSortEnabled: true,
                                    dragEnabled: true,
                                    elements: [
                                        {
                                            name: 'ButtonElementBasic',
                                            type: 'ButtonElement',
                                            itemDisposition: 'top-middle',
                                            buttonType: 'basic',
                                            label: 'Basic'
                                        },
                                        {
                                            name: 'ButtonElementRaised',
                                            type: 'ButtonElement',
                                            itemDisposition: 'top-middle',
                                            buttonType: 'raised',
                                            label: 'Raised'
                                        },
                                        {
                                            name: 'ButtonElementStroked',
                                            type: 'ButtonElement',
                                            itemDisposition: 'top-middle',
                                            buttonType: 'stroked',
                                            label: 'Stroked'
                                        },
                                        {
                                            name: 'ButtonElementFlat',
                                            type: 'ButtonElement',
                                            itemDisposition: 'top-middle',
                                            buttonType: 'flat',
                                            label: 'Flat'
                                        },
                                        {
                                            name: 'ButtonElementToggle',
                                            type: 'ButtonElement',
                                            itemDisposition: 'top-middle',
                                            buttonType: 'toggle',
                                            label: 'Toggle'
                                        }
                                        // | 'icon' | 'fab' | 'miniFab' | 'iconToggle';
                                    ]
                                },
                                {
                                    name: 'Element01_05-Container',
                                    type: 'DynamicFlexContainer',
                                    layout: 'row',
                                    logging: false,
                                    enableDragDrop: true,
                                    dropSortEnabled: true,
                                    dragEnabled: true,
                                    elements: [
                                        {
                                            name: 'ButtonElementIcon',
                                            type: 'ButtonElement',
                                            itemDisposition: 'top-middle',
                                            buttonType: 'icon',
                                            icon: 'home'
                                        },
                                        {
                                            name: 'ButtonElementFab',
                                            type: 'ButtonElement',
                                            itemDisposition: 'top-middle',
                                            buttonType: 'fab',
                                            icon: 'home'
                                        },
                                        {
                                            name: 'ButtonElementMiniFab',
                                            type: 'ButtonElement',
                                            itemDisposition: 'top-middle',
                                            buttonType: 'miniFab',
                                            icon: 'home'
                                        },
                                        {
                                            name: 'ButtonElementIconToggle',
                                            type: 'ButtonElement',
                                            itemDisposition: 'top-middle',
                                            buttonType: 'iconToggle',
                                            icon: 'home'
                                        },
                                    ]
                                },
                                {
                                    name: 'ImageElement_01-06',
                                    type: 'ImageElement',
                                    chainElementConfigs: [
                                        {
                                            value: 'https://images.unsplash.com/photo-1614761064736-ce11c09670f8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80'
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            name: 'ElementConfigPanel',
                            type: 'DynamicFlexContainer',
                            width: '80',
                            containerMaxHeight: 'zzz',
                            maxHeight: 'full',
                            containerOverflow: 'zzz',
                            overflow: 'y-auto',
                            itemFillSpace: 'none',
                            logging: false
                        }
                    ]
                }
            ]
        },
        {
            id: '0040400',
            name: 'Context',
            type: 'ContextElement',
            editMode: false,
            logging: false,
            width: 'screen',
            height: 'screen',
            overflow: 'hidden',
            elements: [
                {
                    name: 'MainPage',
                    chainElementConfigs: [{
                        value: '404 not found'
                    }],
                    // itemFillSpace : 'initial',
                    itemDisposition: 'middle-middle'
                }
            ]
        },
        {
            id: '00router00',
            name: 'Context',
            type: 'ContextElement',
            editMode: false,
            logging: false,
            width: 'screen',
            height: 'screen',
            overflow: 'hidden',
            elements: [
                {
                    name: 'left',
                    chainElementConfigs: [{
                        value: 'leftMenu'
                    }],
                    borderWidthRight: '2',
                    borderStyle: 'solid',
                    borderColor: 'black',
                    width: '50',
                    // itemFillSpace : 'initial',
                },
                {
                    name: 'right',
                    type: 'DynamicFlexContainer',
                    layout: 'col',
                    logging: false,
                    width: 'full',
                    height: 'full',
                    elements: [
                        {
                            name: 'top',
                            chainElementConfigs: [{
                                value: 'topMenu'
                            }],
                            borderWidthBottom: '2',
                            borderStyle: 'solid',
                            borderColor: 'black',
                            height: '20',
                            width: 'full',
                            itemFillSpace: 'initial'
                        },
                        {
                            name: 'mainRouterOutler',
                            type: 'RouterOutletElement',
                            height: 'full',
                            width: 'full',
                            itemFillSpace: 'initial'
                        },
                    ]
                }
            ]
        },
        {
            id: 'subRouteA',
            name: 'mainContent',
            chainElementConfigs: [{
                value: 'SUBROUTE A'
            }],
            // itemFillSpace : 'initial',
            itemDisposition: 'middle-middle',
            eventsHook: {
                onClick: {
                    type: 'function',
                    value: 'context.routingService.goToInternalRouteByUrl("example-router/sub-route-b")'
                }
            }
        },
        {
            id: 'subRouteB',
            name: 'mainContent',
            chainElementConfigs: [{
                value: 'SUBROUTE B'
            }],
            // itemFillSpace : 'initial',
            itemDisposition: 'middle-middle',
            eventsHook: {
                onClick: {
                    type: 'function',
                    value: 'context.routingService.goToInternalRouteByUrl("example-router/sub-route-a")'
                }
            }
        },
        {
            id: 'admin000',
            name: 'Admin',
            type: 'ContextElement',
            editMode: false,
            logging: false,
            width: 'screen',
            height: 'screen',
            overflow: 'hidden',
            elements: [
                {
                    name: 'left',
                    chainElementConfigs: [{
                        value: 'leftMenu'
                    }],
                    borderWidthRight: '2',
                    borderStyle: 'solid',
                    borderColor: 'black',
                    width: '50',
                    // itemFillSpace : 'initial',
                },
                {
                    name: 'right',
                    type: 'DynamicFlexContainer',
                    layout: 'col',
                    logging: false,
                    width: 'full',
                    height: 'full',
                    elements: [
                        {
                            name: 'top',
                            chainElementConfigs: [{
                                value: 'topMenu'
                            }],
                            borderWidthBottom: '2',
                            borderStyle: 'solid',
                            borderColor: 'black',
                            height: '20',
                            width: 'full',
                            itemFillSpace: 'initial'
                        },
                        {
                            name: 'mainRouterOutler',
                            type: 'RouterOutletElement',
                            height: 'full',
                            width: 'full',
                            itemFillSpace: 'initial'
                        },
                    ]
                }
            ]
        },
        {
            id: 'adminHome000',
            name: 'adminHomePage',
            type: 'ListElement',
            layout: 'row',
            width: 'full',
            height: 'full',
            itemDisposition: 'top-middle',
            itemFillSpace: 'auto',
            //placeContent: 'evenly',
            containerFlexWrap: 'wrap',
            flexWrap: 'wrap',
            inputChainElements: [
                {
                    type: 'HttpElement',
                    method: 'POST',
                    url: '/mock/api/executor/query',
                    body: {
                        type: 'function',
                        value: ' msg = msg || {}; \n' +
                            'msg.value = {' +
                            ' contextName: \'Core\',' +
                            ' domainName: \'App\',' +
                            ' queryName: \'FIND_ALL\',' +
                            ' url: context.routingService.getUrlDomain()' +
                            '}; \n ' +
                            'return msg;'
                    }
                },
                {
                    type: 'CoerceBooleanElement',
                }
            ],
            elements: {
                type: 'DynamicFlexContainer',
                layout: 'col',
                itemFillSpace: 'initial',
                width: '50',
                height: '50',
                borderRadiusTopLeft: 'xl',
                borderRadiusTopRight: 'xl',
                borderRadiusBottomLeft: 'xl',
                borderRadiusBottomRight: 'xl',
                marginRight: '5',
                marginLeft: '5',
                marginTop: '5',
                marginBottom: '5',
                elements: [
                    {
                        name: 'image',
                        type: 'ImageElement',
                        chainElementConfigs: [{
                            value: '${msg.value.appImage || "https://www.idmore.it/wp-content/uploads/2016/12/ef3-placeholder-image.jpg"}'
                        }],
                        //selectValue: 'favicon',
                        height: '40',
                        width: 'full'
                    },
                    {
                        name: 'bottom',
                        type: 'DynamicFlexContainer',
                        layout: 'row',
                        height: '10',
                        width: 'full',
                        elements: [
                            {
                                name: 'appImage',
                                type: 'ImageElement',
                                selectValue: 'favicon',
                                height: 'full',
                                width: '10'
                            },
                            {
                                name: 'appName',
                                type: 'BaseElement',
                                selectValue: 'name',
                                containerItemDisposition: 'middle-left',
                                itemDisposition: 'middle-left',
                                height: 'full',
                                width: 'full'
                            },
                        ]
                    }
                ]
            }
        },
    ];


}
