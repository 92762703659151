<div class="flex flex-col flex-auto min-w-0">

    <!-- Header -->
    <div
        class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent">
        <div class="flex-1 min-w-0">
            <!-- Title -->
            <div class="mt-2">
                <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                    {{store?.name}}
                </h2>
                <div class="ml-0.5 font-medium text-secondary">
                    {{store?.address}}
                </div>
            </div>
        </div>
    </div>

    <!-- Main -->
    <div class="flex-auto pt-6 sm:px-4 sm:pb-4">

        <!-- Tabs -->
        <mat-tab-group [animationDuration]="'0'">

            <!-- Tab #1 -->
            <mat-tab *ngIf="0" label="{{translateStrings.client.plural}}">

                <!-- TAB CONTENT GOES HERE -->
                <div class="flex-col sm:flex-row md:flex-col flex-auto justify-between py-8 px-6 md:px-8 border-b">

                    <!-- Header -->
                    <div
                        class="flex flex-col sm:flex-row md:flex-col flex-auto justify-between py-8 px-6 md:px-8 border-b">

                        <!-- Title -->
                        <div>
                            <div class="text-4xl font-extrabold tracking-tight leading-none">
                                {{translateStrings.client.plural}}</div>
                            <div class="ml-0.5 font-medium text-secondary">
                                <ng-container *ngIf="filteredContacts?.length">
                                    {{filteredContacts.length}}
                                </ng-container>
                                {{filteredContacts?.length | i18nPlural: {
                                '=0': translateStrings.client.noClients,
                                '=1': translateStrings.client.singular,
                                'other': translateStrings.client.plural
                                } }}
                            </div>
                        </div>

                        <!-- Main actions -->
                        <div class="flex items-center mt-4 sm:mt-0 md:mt-4">
                            <!-- Search -->
                            <div class="flex-auto">
                                <mat-form-field
                                    class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
                                    <mat-icon class="icon-size-5" matPrefix
                                        [svgIcon]="'heroicons_solid:search'"></mat-icon>
                                    <input matInput [formControl]="searchInputControl" [autocomplete]="'off'"
                                        [placeholder]="translateStrings.client.search">
                                </mat-form-field>
                            </div>
                            <!-- Add contact button -->
                            <button class="ml-4" mat-flat-button [color]="'primary'"
                                (click)="this.selectedUser = undefined; openModal()">
                                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                                <span class="ml-2 mr-1">{{ translateStrings.client.addNew }}</span>
                            </button>
                        </div>
                    </div>

                    <!-- Contacts list -->
                    <div class="relative">
                        <ng-container *ngIf="filteredContacts?.length; else noContacts">
                            <ng-container *ngFor="let contact of filteredContacts; let i = index; trackBy: trackByFn">
                                <!-- Group -->
                                <ng-container
                                    *ngIf="i === 0 || contact?.firstName?.charAt(0) !== filteredContacts[i - 1].firstName?.charAt(0)">
                                    <div
                                        class="z-10 sticky top-0 -mt-px px-6 py-1 md:px-8 border-t border-b font-medium uppercase text-white bg-accent bg-opacity-50 dark:bg-gray-900">
                                        {{contact?.firstName?.charAt(0)}}
                                    </div>
                                </ng-container>
                                <!-- Contact -->
                                <a class="z-20 flex items-center px-6 py-4 md:px-8 cursor-pointer border-b justify-between hover:bg-accent hover:bg-opacity-10 dark:hover:bg-hover"
                                    [routerLink]="['../../../client/detail/', contact?.personId]">
                                    <div class="flex items-center">
                                        <div
                                            class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                                            <ng-container *ngIf="contact?.avatar">
                                                <img class="object-cover w-full h-full" [src]="contact?.avatar"
                                                    alt="Contact avatar" />
                                            </ng-container>
                                            <ng-container *ngIf="!contact?.avatar">
                                                <div
                                                    class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase  bg-accent bg-opacity-70 text-white dark:bg-gray-700 dark:text-gray-200">
                                                    {{contact?.firstName?.charAt(0)}}
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div class="min-w-0 ml-4">
                                            <div class="font-medium leading-5 truncate">{{contact?.firstName}}
                                                {{contact?.lastName}}</div>
                                            <div class="leading-5 truncate text-secondary"></div>
                                        </div>
                                    </div>
                                    <mat-icon class="pl-3 icon-size-8" [svgIcon]="'heroicons_solid:pencil'"
                                        (click)="$event.stopPropagation(); $event.preventDefault(); selectedUser = contact; openModal();"></mat-icon>
                                </a>
                            </ng-container>
                        </ng-container>

                        <!-- No contacts -->
                        <ng-template #noContacts>
                            <div class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">
                                {{translateStrings.client.noSearchResults}}</div>
                        </ng-template>

                    </div>
                </div>

            </mat-tab>

            <mat-tab label="{{translateStrings.cabin.plural}}">
                <!-- Add contact button -->
                <div class="flex justify-end mt-4 sm:mt-0 md:mt-4">
                    <button class="ml-4" mat-flat-button [color]="'primary'"
                        (click)="this.selectedCabin = undefined; openCabinModal()">
                        <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                        <span class="ml-2 mr-1">{{ translateStrings.cabin.addNew }}</span>
                    </button>
                </div>

                <!-- Cabin list -->
                <div class="relative">
                    <ng-container *ngIf="cabins?.length; else noCabins">
                        <ng-container *ngFor="let cabin of cabins; let i = index; trackBy: trackByFn">
                            <!-- Cabin -->
                            <a (click)="selectedCabin = cabin; openCabinModal()">
                                <div
                                    class="z-20 flex items-center px-6 py-4 md:px-8 cursor-pointer border-b justify-between">
                                    <div class="flex items-center">
                                        <div
                                            class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                                            <mat-icon class="text-primary-500 mx-2"
                                                [svgIcon]="'mat_solid:door_front'"></mat-icon>
                                        </div>
                                        <div class="min-w-0 ml-4">
                                            <div class="font-medium leading-5 truncate">
                                                {{cabin?.name}}
                                            </div>
                                            <div class="leading-5 truncate text-secondary">{{cabin.type}}</div>
                                        </div>
                                    </div>
                                    <mat-icon class="pl-3 icon-size-8" svgIcon="mat_outline:delete"
                                        (click)="$event.stopPropagation(); $event.preventDefault(); selectedCabin = cabin; deleteCabin(cabin);"></mat-icon>
                                </div>
                            </a>
                        </ng-container>
                    </ng-container>

                    <!-- No Cabins -->
                    <ng-template #noCabins>
                        <div class="p-8 sm:p-16 text-4xl font-semibold tracking-tight text-center">
                            {{translateStrings.cabin.noSearchResults}}</div>
                    </ng-template>

                </div>


            </mat-tab>
        </mat-tab-group>

    </div>
</div>


<!-- Main modal -->
<ng-template #userModal>
    <h3 mat-dialog-title>{{ translateStrings.client.addNew }}</h3>
    <mat-dialog-content>
        <form [formGroup]="addNewUserForm" (ngSubmit)="userFormSubmit()" #newUserForm="ngForm">
            <div class="flex flex-col gt-xs:flex-row p-7">

                <mat-form-field class="flex-auto gt-xs:pr-3">
                    <mat-label>{{translateStrings.user.firstName}}</mat-label>
                    <input matInput formControlName="firstName">
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                </mat-form-field>

                <mat-form-field class="flex-auto gt-xs:pl-3">
                    <mat-label>{{translateStrings.user.lastName}}</mat-label>
                    <input matInput formControlName="lastName">
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                </mat-form-field>

                <mat-form-field class="fuse-mat-textarea flex-auto">
                    <mat-label>{{translateStrings.user.address}}</mat-label>
                    <textarea matInput [rows]="3" formControlName="address" matTextareaAutosize></textarea>
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:location-marker'"></mat-icon>
                </mat-form-field>

                <mat-form-field class="flex-auto gt-xs:pl-3">
                    <mat-label>{{translateStrings.user.sex}}</mat-label>
                    <mat-select formControlName="sex">
                        <mat-option value="M">{{translateStrings.user.male}}</mat-option>
                        <mat-option value="F">{{translateStrings.user.female}}</mat-option>
                        <mat-option value="ND">{{translateStrings.user.ND}}</mat-option>
                    </mat-select>
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                </mat-form-field>

                <mat-form-field class="flex-auto">
                    <mat-label>{{translateStrings.user.birthdate}}</mat-label>
                    <input matInput formControlName="birthdate" [matDatepicker]="picker1">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>

            </div>

            <!-- Modal footer -->
            <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button type="submit" mat-flat-button [color]="'primary'" class="rounded-lg"
                    [disabled]="!addNewUserForm.valid">{{translateStrings.generic.send}}</button>
                <button mat-button mat-dialog-close type="button"
                    class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">{{translateStrings.generic.close}}</button>
            </div>

        </form>
    </mat-dialog-content>
</ng-template>

<ng-template #cabinModal>
    <h3 mat-dialog-title>{{ translateStrings.cabin.addNew }}</h3>
    <mat-dialog-content>
        <form [formGroup]="addNewCabinForm" (ngSubmit)="cabinFormSubmit()" #newUserForm="ngForm">
            <div class="flex flex-col gt-xs:flex-row px-2">

                <mat-form-field class="flex-auto gt-xs:pr-3">
                    <mat-label>{{translateStrings.cabin.name}}</mat-label>
                    <input matInput formControlName="name">
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'mat_solid:door_front'"></mat-icon>
                </mat-form-field>

                <mat-form-field class="flex-auto gt-xs:pl-3">
                    <mat-label>{{translateStrings.cabin.type}}</mat-label>
                    <input matInput formControlName="type">
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:star'"></mat-icon>
                </mat-form-field>

                <!-- Modal footer -->
                <div
                    class="flex items-center py-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600 justify-between">
                    <button mat-button mat-dialog-close type="button"
                        class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">{{translateStrings.generic.cancel}}</button>

                    <button type="submit" mat-flat-button [color]="'primary'" class="rounded-lg"
                        [disabled]="!addNewCabinForm.valid || isSpecificLoading['saveCabin']">
                        <span *ngIf="!isSpecificLoading['saveCabin']">{{translateStrings.generic.save}}</span>
                        <mat-progress-spinner *ngIf="isSpecificLoading['saveCabin']" [diameter]="24"
                            mode="indeterminate"></mat-progress-spinner>
                    </button>
                </div>
            </div>
        </form>
    </mat-dialog-content>
</ng-template>