import { GruulsMockDomain, GruulsMockHttpRequest } from '../../gruuls-mock-domain';
import { HttpClient } from '@angular/common/http';

export class BeautyTreatmentCatalogMock extends GruulsMockDomain {

  constructor(httpClient: HttpClient) {
    super(httpClient);


    this.entities = this.TREATMENTS.map((treatment) => ({
      ...treatment,
      advisedProducts: this.ADVIDED_PRODUCTS[treatment.code]
    })
    );
  }

  TREATMENTS = [
    {
      uuid: "1234-1234-1234-1230",
      code: "generic",
      color: "#461220",
      name: "Generico",
      description: "Appuntamento generico",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      requiredCabis: [],
    },
    {
      uuid: "1234-1234-1234-1234",
      name: "Idratante",
      description: "Trattamento finalizzato a contrastare la disidratazione superficiale e profonda, ripristinando il microbiota e la barriera cutanea. A base di vitamina C, acido ialuronico e altri attivi ad azione antiossidante, idratante e di riequilibrio del pH isoepidermico.",
      code: "IBD_idratz",
      color: "#8A2E37",
      defaultPriceCent: 3000,
      defaultDurationInMin: 30,
      target: "face",
      requiredCabis: [],
      isPublic: true,
      isBeautycians: false,
    },
    {
      uuid: "1234-1234-1234-1235",
      code: "IBD_elast",
      color: "#A93744",
      name: "Elasticità",
      description: "Trattamento che favorisce la compattezza cutanea attraverso al stimolazione dell’anabolismo e del tono tissutale. A base di estratto glicerico di Mirtillo, D-Pantenolo , Bisabololo ed estratto di Centella.",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "face",
    },
    {
      uuid: "1234-1234-1234-1236",
      code: "IBD_color_cutn",
      color: "#C54459",
      name: "Colorito",
      description: "Trattamento finalizzato al riequilibrio dei processi del metabolismo mitocondriale favorendo l’ossigenazione dei tessui con conseguente restituzione di un colorito roseo e uniforme alla cute. A base di α-tocoferolo,Acido Mandelico ed estratto di Rodiola Rosea.",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "face",
    },
    {
      uuid: "1234-1234-1234-1237",
      code: "IBD_rughe_superficiali",
      color: "#C65866",
      name: "Rughe superficiali",
      description: "Trattamento finalizzato a contrastare la disidratazione profonda, superficiale e di barriera. Stimola l’attività dei fibroblasti e favorisce un processo di healty aging contrastando  le rughe grazie ad attivi specifici come il Bisabololo, il Resveratrolo e il Glutatione ridotto.",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "face",
    },
    {
      uuid: "1234-1234-1234-1238",
      code: "IBD_rughe_profonde",
      color: "#C65866",
      name: "Rughe profonde",
      description: "Favorisce la stimolazione cellulare e l’idratazione profonda. Riduce visibilmente le rughe stimolando l’ossigenazione tissutale e l’attività dei fibroblasti oltre a ripristinare l'equilibrio tra antiossidanti e radicali liberi riducendo lo stress ossidativo. A base di o.e di Limone, Insaponificabile d'oliva e altri attvi ad azione antiossidante e antiradicalica.",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "face",
    },
    {
      uuid: "1234-1234-1234-1239",
      code: "IBD_presz_crom",
      color: "#DC93A2",
      name: "Ipercromie",
      description: "Trattamento illuminante e destrutturante finalizzato a ridurre visibilmente le discromie causate da alterazione della distribuzione della melanina. Grazie all'attività sinergica di un pool di acidi, tra cui l' Acido citrico , glicolico, mandelico salicilico e biosuccinico controlla l'attivita dell'enzima tirosinasi e restituisce un incarnato omegeneo.",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "face",
    },
    {
      uuid: "1234-1234-1234-1240",
      code: "IBD_presez_impur",
      color: "#DC93A2",
      name: "Impurità",
      description: "Trattamento finalizzato alla stimolazione del microcircolo favorendo la detossinazione dei tessuti cutanei e il riequilibrio della produzione sebacea. A base di Spirulina, Olio essenziale di Limone, acido Ximeninico.",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "face",
    },
    {
      uuid: "1234-1234-1234-1241",
      code: "IBD_presenza_pori_dil",
      color: "#DC93A2",
      name: "Pori dilatati",
      description: "Trattamento finalizzato al controllo del'attività della ghiandola sebacea e al riequilibrio del processo di cheratinizzazione all'interno del follicolo ristabilendone la corretta dimensione. A base di aloe vera, acido salicilico e altri attivi ad azione seboequilibrante e di ripristino del pH isoepidermico.",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "face",
    },
    {
      uuid: "1234-1234-1234-1242",
      code: "IBD_presez_acne",
      color: "#DC93A2",
      name: "Acne",
      description: "Trattamento fiinalizzato al riequilibrio idrogenionico e sebaceo. Aiuta a contrastare l’infiammazione, l'iperproduzione di sebo da parte delle ghiandol esebacee e il ripristino del microbiota cutaneo. A base di attivi disinfiammanti e sebo equilibrianti come la Curcuma, il Caolino e Acido Glicerretico.",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "face",
    },
    {
      uuid: "1234-1234-1234-1243",
      code: "IBD_presz_infmz",
      color: "#C46E78",
      name: "Infiammazione",
      description: "Contrasta le infiammazioni e gli arrossamenti riducendone visibilmente i segni. Rafforza la naturale barriera protettiva della pelle e favorisce la protezione vasale. A base di attivi come Aoe Vera, Estratto glicerico di vite rossa e olio essenziale di arancio.",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "face",
    },
    {
      uuid: "1234-1234-1234-1244",
      code: "IBD_sensibilita",
      color: "#C46E78",
      name: "Sensibilità",
      description: "Riduce l’irritazione contrastando l’inflammaging e agendo sulla riduzione del carico ossidativo e ripristinando gli equilibri isoepidermici. A base di estrratto glicerico di Mirtillo, Aloe, Acido glicerretico, Bisabololo e altri attivi ad azione antiossidante, lenitiva e di ripristino dell'equilibrio allostatico.",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "face",
    },
    {
      uuid: "1234-1234-1234-1245",
      code: "IBD_presenz_cuper",
      color: "#C46E78",
      name: "Couperose",
      description: "Trattamento fializzato alla stimolazione della motilità del microcircolo, con forte attività vasoprotettiva, lenitiva e calmante. Riduce la visibilità delle teleangectasie,lenisce e nutre i tessuti ripristinando l'equilibrio allostatico. A base di resveratrolo, acido lipoico, vitamina E, bisabololo, camomilla e aloe.",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "face",
    },
    {
      uuid: "1234-1234-1234-1246",
      code: "IBD_stat_circ_gen",
      color: "#CB7C85",
      name: "Stato Circolatorio Generale",
      description: "Trattamento finalizzato al miglioramento e al mantenimento di una stato circolatorio efficente ed efficace. Facilita il riassorbimento dei liquidi interstiziali e la circolazione di ritorno. A base di Estratto di feccia d’uva liofilizzato , Acido ximeninico, Olio essenziale di Menta e Fango termale organico liofilizzato (Heilmoor extract).",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "body",
    }, {
      uuid: "1234-1234-1234-1247",
      code: "IBD_presenz_cellu_fibr",
      color: "#CB7C85",
      name: "Cellulite Fibrosa",
      description: "Trattamento finalizzato alla riduzione della cellulite favorendo l’eliminazione dei liquidi e delle scorie in eccesso, stimolando l’attività micro circolatoria e di riattivazione. Riduce la presenza di micro e macronoduli restituendo alla cute un aspetto più tonico, disteso e un colorito omogeneo. A base di Rusco, Caffeina, Fucus, Cannella e altri attivi dalla forte attività di stimolazione dei processi energetici e di drenaggio.",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "body",
    }, {
      uuid: "1234-1234-1234-1248",
      code: "IBD_presenz_cell_edem",
      color: "#CB7C85",
      name: "Cellulite Edematosa",
      description: "Favorisce il drenaggio e la detossinazione con forte azione anti-edemigena riducendo gli inestetismi legati alla cellulite edematosa. Favorisce il deflusso dei liquidi interstiziali e delle tossine riducendo l'edema. A base di ingredienti drenanti e protettivi del microcircolo cutaneo quali: l’acido ximeninico, il mirtillo, il frutto della passione e il rusco.",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "body",
    }, {
      uuid: "1234-1234-1234-1249",
      code: "IBD_presenz_cell_adip",
      color: "#CB7C85",
      name: "Cellulite Adiposa",
      description: "Trattamento finalizzato alla riduzione della cellulite adiposa agendo contemporaneamente sul drenaggio e sull’attivazione della lipolisi con conseguente riequilibrio delle strutture adipocitarie e ripristino del sistema dermo-epidermico. A base di Caspico, Centella, Zenzero, Fucus e Cacao.",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "body",
    }, {
      uuid: "1234-1234-1234-1250",
      code: "IBD_presenz_pann_adp",
      color: "#CB7C85",
      name: "Pannicolo adiposo",
      description: "Trattamento specifico per la riduzione dell'adipe localizzato. Favorisce l'attività di lipolisi controllando simultaneamente l'attività della lipogenesi lavorando quindi sull'adipe presente e in modo preventivo. A base di Caffeina, Guaranà, Tisochrysis lutea e altri attivi liporiducenti.",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "body",
    }, {
      uuid: "1234-1234-1234-1251",
      code: "IBD_presenz_adp_diff",
      color: "#CB7C85",
      name: "Adiposità diffuse",
      description: "Trattamento finalizzato a contrastare le adiposità diffuse grazie alla forte azione lipolitica, stimolante e modellante. Favorisce la lipolisi e controlla la la lipogenesi. A base di Caffeina, Fucus, Polysiphonia brodiei, Olio essenziale di Pompelmo e altri attivi stimolanti e liporiducenti. ",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "body",
    }, {
      uuid: "1234-1234-1234-1252",
      code: "IBD_presenz_edema",
      color: "#E6B2BC",
      name: "Edema",
      description: "Trattamento finalizzato al miglioramento e al mantenimento di una stato circolatorio efficente ed efficace. Facilita il riassorbimento dei liquidi interstiziali e la circolazione di ritorno con consegunete riduzione dell'edema diffuso. A base di Estratto di feccia d’uva liofilizzato , Acido ximeninico, Olio essenziale di Menta .",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "body",
    }, {
      uuid: "1234-1234-1234-1253",
      code: "IBD_senz_pesant_art_inf",
      color: "#E6B2BC",
      name: "Pesantezza arti inferiori",
      description: "Trattamento finalizzato a restituire comfort e leggerezza agli arti inferiori grazie almiglioramento e alla protezione del microcircolo. A base di Estratto di feccia d’uva liofilizzato , Acido  ximeninico, Olio essenziale di Menta .",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "body",
    }, {
      uuid: "1234-1234-1234-1254",
      code: "IBD_senz_gonf_add",
      color: "#E6B2BC",
      name: "Gonfiore addominale",
      description: "",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "body",
    }, {
      uuid: "1234-1234-1234-1255",
      code: "IBD_fovea",
      color: "#E6B2BC",
      name: "Fovea",
      description: "Trattamento finalizzato al miglioramento e al mantenimento di una stato circolatorio efficente ed efficace. Facilita il riassorbimento dei liquidi interstiziali e la circolazione di ritorno con consegunete riduzione dell'edema diffuso. A base di Estratto di feccia d’uva liofilizzato , Acido ximeninico, Olio essenziale di Menta .",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "body",
    }, {
      uuid: "1234-1234-1234-1256",
      code: "detox",
      color: "#E6B2BC",
      name: "Detox",
      description: "Finalizzato al drenaggio, detossinazione e detossificazione dei tessuti svolge un importante azione remineralizzante e di rinnovamento tissutale. A base di Olio essenziale di Cipresso, Arancio, Limone, Fosfatidilcolina  e estratto glicerico di Mirtillo.",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "body",
    },
    {
      uuid: "1234-1234-1234-1257",
      code: "atonia",
      color: "#461220",
      name: "Atonia",
      description: "Trattamento finalizzato all’incremento dell’elasticità e della compattezza dei tessuti attraverso un’importante attività rigenerante e di stimolazione. A base di estratto glicerico di centella, caffeina, alga Fucus e cannella.",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "body"

    }, {
      uuid: "1234-1234-1234-1258",
      code: "prep",
      color: "#461220",
      name: "Preparazione",
      description: "Trattamento dalla forte azione stimolante, esfoliante e restitutiva è finalizzato alla preparazione dei tessuti prima di qualsiasi trattamento specifico. a base di Acido mandelico, tartarico, Olio di Vinaccioli, estratto secco di Feccia d'uva e altri attivi stimolati e nutrienti.",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "body",
    }, {
      uuid: "1234-1234-1234-1259",
      code: "detox_fullbody",
      color: "#E6B2BC",
      name: "Detox Fullbody",
      description: "Finalizzato al drenaggio, detossinazione e detossificazione dei tessuti svolge un importante azione remineralizzante e di rinnovamento tissutale. A base di Olio essenziale di Cipresso, Arancio, Limone, Fosfatidilcolina  e estratto glicerico di Mirtillo.",
      defaultPrice: 30,
      defaultDurationInMin: 30,
      target: "fullbody",
    }
  ];

  ADVIDED_PRODUCTS = {
    IBD_idratz: ["MILKY", "pH Rebalancer", "Eye Lips Cream", "TOTAL Exfo", "HH+ Cream", "OX Cream", "L Serum", "AB Serum", "OX Serum", "AB Nutraceutic", "µC Nutraceutic", "AB 3-OME", "I-OS Repair"],
    IBD_elast: ["MILKY", "pH Rebalancer", "Eye Lips Cream", "TOTAL Exfo", "HH+ Cream", "OX Cream", "L Serum", "AB Serum", "OX Serum", "AB Nutraceutic", "µC Nutraceutic", "AB 3-OME", "I-OS Repair"],
    IBD_color_cutn: ["MILKY", "pH Rebalancer", "Eye Lips Cream", "TOTAL Exfo", "HH+ Cream", "OX Cream", "AB Serum", "OX Serum", "AB Nutraceutic", "µC Nutraceutic", "OX Light", "AB 3-OME", "I-OS Repair"],
    IBD_inest_cont_occhi: ["MILKY", "pH Rebalancer", "Eye Lips Cream", "TOTAL Exfo", "HH+ Cream", "OX Cream", "AB Serum", "OX Serum", "AB Nutraceutic", "µC Nutraceutic"],
    IBD_rughe: ["MILKY", "pH Rebalancer", "Eye Lips Cream", "TOTAL Exfo", "OX Cream", "L Serum", "OX Serum", "AB Nutraceutic", "µC Nutraceutic", "RedOx Nutraceutic", "AB 3-OME", "I-OS Repair"],
    IBD_rughe_profonde: ["MILKY", "pH Rebalancer", "Eye Lips Cream", "TOTAL Exfo", "OX Cream", "L Serum", "OX Serum", "AB Nutraceutic", "µC Nutraceutic", "RedOx Nutraceutic", "AB 3-OME", "I-OS Repair"],
    IBD_rughe_superficiali: ["MILKY", "pH Rebalancer", "Eye Lips Cream", "TOTAL Exfo", "OX Cream", "L Serum", "OX Serum", "AB Nutraceutic", "µC Nutraceutic", "RedOx Nutraceutic", "AB 3-OME", "I-OS Repair"],
    IBD_presz_crom: ["MILKY", "pH Rebalancer", "Eye Lips Cream", "TOTAL Exfo", "HH+ Cream", "OX Cream", "L Serum", "AB Serum", "OX Serum", "AB Nutraceutic", "µC Nutraceutic", "OX Light", "AB 3-OME", "I-OS Repair"],
    IBD_presez_impur: ["MILKY", "pH Rebalancer", "Eye Lips Cream", "TOTAL Exfo", "HH+ Cream", "AB Serum", "AB Nutraceutic", "µC Nutraceutic", "RED Pure", "AB 3-OME", "I-OS Repair"],
    IBD_presenza_pori_dil: ["MILKY", "pH Rebalancer", "Eye Lips Cream", "TOTAL Exfo", "HH+ Cream", "AB Serum", "AB Nutraceutic", "µC Nutraceutic", "AB 3-OME", "I-OS Repair"],
    IBD_presez_acne: ["MILKY", "pH Rebalancer", "Eye Lips Cream", "TOTAL Exfo", "I-OS Cream", "µC Serum", "AB Nutraceutic", "µC Nutraceutic", "RED Pure", "AB 3-OME", "I-OS Repair"],
    IBD_presz_infmz: ["MILKY", "pH Rebalancer", "Eye Lips Cream", "TOTAL Exfo", "I-OS Cream", "µC Serum", "AB Nutraceutic", "µC Nutraceutic", "AB 3-OME", "I-OS Repair"],
    IBD_sensibilita: ["MILKY", "pH Rebalancer", "Eye Lips Cream", "TOTAL Exfo", "I-OS Cream", "L Serum", "µC Serum", "AB Nutraceutic", "µC Nutraceutic", "AB 3-OME", "I-OS Repair"],
    IBD_presenz_cuper: ["MILKY", "pH Rebalancer", "Eye Lips Cream", "TOTAL Exfo", "I-OS Cream", "L Serum", "µC Serum", "AB Nutraceutic", "µC Nutraceutic", "AB 3-OME", "I-OS Repair"],
    IBD_stat_circ_gen: ["TOTAL Exfo", "µc Cream", "µC Serum", "AB Nutraceutic", "µC Nutraceutic", "AB Cleanser", "I-OS Repair"],
    IBD_presenz_cellu_loc: ["TOTAL Exfo", "µc Cream", "µC Serum", "RED Serum", "AB Nutraceutic", "µC Nutraceutic", "AB Cleanser", "I-OS Repair"],
    IBD_presenz_cellu_diff: ["TOTAL Exfo", "µc Cream", "µC Serum", "RED Serum", "AB Nutraceutic", "µC Nutraceutic", "AB Cleanser", "I-OS Repair"],
    IBD_presenz_pann_adp: ["TOTAL Exfo", "RED Cream", "RED Serum", "AB Nutraceutic", "µC Nutraceutic", "AB Cleanser", "I-OS Repair"],
    IBD_presenz_adp_diff: ["TOTAL Exfo", "RED Cream", "RED Serum", "AB Nutraceutic", "µC Nutraceutic", "RedOx Nutraceutic", "AB Cleanser", "I-OS Repair"],
    IBD_presenz_edema: ["TOTAL Exfo", "µc Cream", "µC Serum", "AB Nutraceutic", "µC Nutraceutic", "RedOx Nutraceutic", "AB Cleanser", "I-OS Repair"],
    IBD_senz_pesant_art_inf: ["TOTAL Exfo", "µc Cream", "µC Serum", "AB Nutraceutic", "µC Nutraceutic", "AB Cleanser", "I-OS Repair"],
    IBD_senz_gonf_add: ["TOTAL Exfo", "µc Cream", "µC Serum", "AB Nutraceutic", "µC Nutraceutic", "AB Cleanser", "I-OS Repair"],
    IBD_fovea: ["TOTAL Exfo", "µc Cream", "µC Serum", "AB Nutraceutic", "µC Nutraceutic", "AB Cleanser", "I-OS Repair"],
    IBD_condz_tess_musc: ["TOTAL Exfo", "RED Cream", "RED Serum", "OX Serum", "AB Nutraceutic", "µC Nutraceutic", "RedOx Nutraceutic", "AB Cleanser", "I-OS Repair"],
    IBD_cond_tess_conn: ["TOTAL Exfo", "RED Cream", "RED Serum", "OX Serum", "AB Nutraceutic", "µC Nutraceutic", "RedOx Nutraceutic", "AB Cleanser", "I-OS Repair"],
  };
}
