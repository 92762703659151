<div class="flex flex-col flex-auto items-center sm:justify-center min-w-0 mt-8">
    <div class="w-full sm:w-auto py-8 px-4 sm:p-12 sm:rounded-2xl sm:shadow sm:bg-card text-white" [ngClass]="{'bg-accent-500': action=='confirm', 'bg-amber-800': action!='confirm'}">
        <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
            <!-- Logo -->
            <div class="w-12">
                <img src="assets/images/logo/logo_white.svg">
            </div>

            <!-- Title -->
            <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">
                {{ action=='confirm' ? translateStrings.appointment.seeyouTomorrow : translateStrings.appointment.dontseeyou }}
            </div>
            <div class="mt-0.5">
                {{ action=='confirm' ? translateStrings.appointment.thanksForConfirming : translateStrings.appointment.thanksForCancelling }}
            </div>

        </div>
    </div>
    <p *ngIf="action=='confirm'" class="pt-4 text-primary-900 cursor-pointer hover:underline"><a [routerLink]="['/appointment/cancel', appointmentId]">Non riuscirò a venire</a></p>
</div>

<ng-template #loadingerror>
    <img src="/assets/images/ui/beautycians/error_503.jpg" alt="Error" class="object-contain h-48 w-96 mx-auto">
    <div class="text-2xl font-semibold tracking-tight text-center w-4/6 sm:w-full mx-auto">
        {{translateStrings.generic.error503}}</div>
</ng-template>