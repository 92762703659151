import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    Inject,
    OnDestroy,
    OnInit, TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormBuilder, NgForm, Validators } from '@angular/forms';
import { forkJoin, fromEvent, mergeMap, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, filter, map, takeUntil, tap } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { GruulsConstants } from '../../../../../@gruuls-core/utils/gruuls-constants';
import { GruulsAngularHttpProxyService } from '../../../../../@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { GruulsAngularTranslateService } from '../../../../../@gruuls-fe/services/gruuls-angular-translate.service';
import { MatDialog } from '@angular/material/dialog';
import { GruulsAuthService } from '../../../../../@gruuls-fe/services/gruuls-auth.service';
import { Utils } from '@gruuls-core/utils/Utils';
import _, { take } from 'lodash';
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { Store } from 'app/beautycians/utils/dataTypes';
import { GruulsAngularStoreService } from '@gruuls-fe/services/gruuls-angular-stores-service';
import { GruulsAngularCollaboratorService } from '@gruuls-fe/services/gruuls-angular-collaborator-service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Person } from '@gruuls-core/types/person.type';

@Component({
    selector: 'operator-list',
    templateUrl: './operator-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OperatorListComponent implements OnInit, OnDestroy {
    @ViewChild('newUserForm') newUserForm: NgForm;
    @ViewChild('userModal') userModal: TemplateRef<any>;

    roles: any[] = [];
    isLoading: boolean = false;
    drawerMode: 'side' | 'over';
    searchInputControl: FormControl = new FormControl();
    filteredContacts: any[];
    private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private _contacts: any[];

    entities: any[];
    translateStrings: any = { operator: {}, user: {}, generic: {} };
    selectedOperator: Person;
    storeList: Store[];
    isHQ: boolean = false;
    accountLevels: any[];

    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private _document: any,
        private _activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseConfirmationService: FuseConfirmationService,
        private _httpClient: GruulsAngularHttpProxyService,
        private _translate: GruulsAngularTranslateService,
        private _fb: FormBuilder,
        private dialog: MatDialog,
        private _authService: GruulsAuthService,
        private _storesService: GruulsAngularStoreService,
        private _collaboratorsService: GruulsAngularCollaboratorService,
    ) {

    }

    @HostBinding('class') get classList(): any {
        return {
            'w-full': true
        };
    }

    addNewUserForm = this._fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', Validators.required],
        defaultpassword: [''],
        address: [''],
        sex: [''],
        grantAdmin: ['0'],
        // birthdate: [''],
        store: ['']
    });

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.isLoading = true;

        // Get store list
        forkJoin({
            accounts: this._collaboratorsService.refreshAccounts(),
            storeList: this._storesService.refreshStores().pipe(
                takeUntil(this._unsubscribeAll),
            ),
            roleList: this._apiCaller.getRoles().pipe(
                tap(
                    (res) => { this.roles = res; }
                )
            )
        }).subscribe({
            next: (res) => {
                this.isLoading = false;
                this._changeDetectorRef.markForCheck();
            },
            error: (err) => {
                console.log("Error while loading operatos: " + err);
                this.isLoading = false;
                this._changeDetectorRef.markForCheck();
            }
        })

        this.isHQ = Utils.hasRole(this._authService.getCurrentLoggedUser().roles, ['Beautycians-HQ']);

        this._collaboratorsService.accounts$.pipe(
            takeUntil(this._unsubscribeAll),
            tap((accounts) => {
                this._contacts = accounts;
                const _filteredContacts = Utils.searchStringByKeys(accounts, this.searchInputControl?.value, ['firstName', 'lastName'])
                this.filteredContacts = Utils.sortByKeys(_filteredContacts, ['firstName', 'lastName']);
                this._changeDetectorRef.markForCheck();
            })
        ).subscribe();

        this._storesService.stores$.pipe(
            takeUntil(this._unsubscribeAll),
            tap((responseStores) => {
                this.storeList = responseStores;
            })
        ).subscribe();

        // Subscribe to search input field value changes
        this.searchInputControl.valueChanges.pipe(
            takeUntil(this._unsubscribeAll),
            debounceTime(300),
            map(query => Utils.searchStringByKeys(this._contacts, query, ['firstName', 'lastName'])),
            map(queryResult => Utils.sortByKeys(queryResult, ['firstName', 'lastName'])),
            tap(filteredContacts => this.filteredContacts = filteredContacts)
        ).subscribe(() => this._changeDetectorRef.markForCheck());

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Set the drawerMode if the given breakpoint is active
                if (matchingAliases.includes('lg')) {
                    this.drawerMode = 'side';
                } else {
                    this.drawerMode = 'over';
                }

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Listen for shortcuts
        fromEvent(this._document, 'keydown')
            .pipe(
                takeUntil(this._unsubscribeAll),
                filter<KeyboardEvent>(event =>
                    (event.ctrlKey === true || event.metaKey) // Ctrl or Cmd
                    && (event.key === '/') // '/'
                )
            )
            .subscribe(() => {
                // this.createContact();
            });

        this.dialog.afterOpened.pipe(
            takeUntil(this._unsubscribeAll),
            delay(0)
        ).subscribe(() => {
            this.addNewUserForm.controls.defaultpassword.setValidators(Validators.compose([Validators.pattern(/\d/), Validators.pattern(/[A-Z]/), Validators.pattern(/[a-z]/), Validators.minLength(8)]));
            if (this.selectedOperator) {
                const adminRole = this.accountLevels.findIndex(level => 
                    Utils.hasRole(this.selectedOperator.roles, [level.roleLabel])
                );
                this.addNewUserForm.controls['firstName'].setValue(this.selectedOperator.firstName);
                this.addNewUserForm.controls['lastName'].setValue(this.selectedOperator.lastName);
                this.addNewUserForm.controls['sex'].setValue(this.selectedOperator.sex);
                this.addNewUserForm.controls['email'].setValue(this.selectedOperator.account.email);
                this.addNewUserForm.controls['grantAdmin'].setValue(adminRole);
                this.addNewUserForm.controls['email'].disable();
                // this.addNewUserForm.controls['grantAdmin'].disable();
                // this.addNewUserForm.controls['birthdate'].setValue(this.selectedOperator.birthdate ? moment(this.selectedOperator.birthdate) : undefined);
            } else if (this.newUserForm) {
                this.newUserForm.resetForm();
                this.addNewUserForm.controls['email'].enable();
                this.addNewUserForm.controls['grantAdmin'].enable();
                this.addNewUserForm.controls['grantAdmin'].setValue("0");
                this.addNewUserForm.controls['store'].setValue(this.storeList[0].storeId);
            }
            this._changeDetectorRef.markForCheck();
        });

        // Translations
        // TODO: Chiedere a Marco per quale motivo ritorna una stringa e non un Observable, anche se il tipo richiesto è l'Obs
        const operatorTranslations = ['plural', 'singular', 'no', 'search', 'addNew', 'noSearchResults', 'grantAdmin', 'hq', 'hqSales', 'deleteWarning', 'deleteWarningMessage', 'StoreOperator', 'StoreOwner'];
        operatorTranslations.forEach((translation) => {
            this.translateStrings['operator'][translation] = this._translate.translate('operators.' + translation);
        });

        const userTranslations = ['firstName', 'lastName', 'address', 'sex', 'male', 'female', 'ND', 'birthdate', 'pictureUrl', 'roles', 'email', 'defaultpassword'];
        userTranslations.forEach((translation) => {
            this.translateStrings['user'][translation] = this._translate.translate('users.' + translation);
        });

        const genericTranslations = ['send', 'close', 'save', 'saveAndSendWelcome', 'yes', 'no', 'edit', 'delete', 'passwordLength', 'upperLowerNumeric'];
        genericTranslations.forEach((translation) => {
            this.translateStrings['generic'][translation] = this._translate.translate('generic.' + translation);
        });

        this.accountLevels = [
            {
                value: 0,
                hqOnly: false,
                dropDownLabel: this.translateStrings.operator.StoreOperator,
                roleLabel: 'Beautycians-Store-Operator'
            },
            {
                value: 1,
                hqOnly: false,
                dropDownLabel: this.translateStrings.operator.StoreOwner,
                roleLabel: 'Beautycians-Stores-Owner'
            },
            {
                value: 2,
                hqOnly: true,
                dropDownLabel: this.translateStrings.operator.hq,
                roleLabel: 'Beautycians-HQ'
            },
            {
                value: 3,
                hqOnly: true,
                dropDownLabel: this.translateStrings.operator.hqSales,
                roleLabel: 'Beautycians-HQ-Sales'
            }
        ]
    
    }

    hasRole(person: Person, role: string): boolean {
        return Utils.hasRole(person.roles, [role], this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId);
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    sendWelcomeHandler(): void {
        if (!this.selectedOperator) {
            return;
        }

        this._authService.sendWelcomeEmail(this.selectedOperator.account.email).subscribe({
            next: (res) => {
                this.dialog.closeAll();
                this._changeDetectorRef.markForCheck();
            },
            error: (err) => {
                this._changeDetectorRef.markForCheck();
            }
        });
    }

    userFormSubmit(submitId): void {
        if (this.addNewUserForm.invalid)
            return;

        this.addNewUserForm.disable();

        let roleTemplate;
        // switch (this.addNewUserForm.controls['grantAdmin'].value) {
        //     case "1":
        //         roleTemplate = this.roles.filter((item) => item.name == 'Beautycians-Stores-Owner')[0];
        //         break;
        //     case "2":
        //         roleTemplate = this.roles.filter((item) => item.name == 'Beautycians-HQ')[0];
        //         break;
        //     case "3":
        //         roleTemplate = this.roles.filter((item) => item.name == 'Beautycians-HQ-Sales')[0];
        //         break;
        //     default:
        //         roleTemplate = this.roles.filter((item) => item.name == 'Beautycians-Store-Operator')[0];
        // }

        roleTemplate = this.roles.filter((item) => item.name == this.accountLevels[this.addNewUserForm.controls['grantAdmin'].value]?.roleLabel)[0] || 
                   this.roles.filter((item) => item.name == 'Beautycians-Store-Operator')[0];

        let pwd = {};
        if (this.addNewUserForm.value["defaultpassword"] && this.addNewUserForm.value["defaultpassword"] != '') {
            pwd = {
                password: this.addNewUserForm.value["defaultpassword"],
                passwordVerify: this.addNewUserForm.value["defaultpassword"]
            }
        }
        const accountDetails: Person = {
            firstName: this.addNewUserForm.value["firstName"],
            lastName: this.addNewUserForm.value["lastName"],
            account: {
                email: this.addNewUserForm.value["email"],
                username: this.addNewUserForm.value["email"],
                ...pwd
            }
        };

        let operator$: Observable<Person>;
        if (this.selectedOperator) {
            accountDetails['personId'] = this.selectedOperator.personId;
            delete accountDetails.account.email;
            delete accountDetails.account.username;
            let roleTemplateId = null;
            if (!Utils.hasRole(this.selectedOperator.roles, roleTemplate.name, this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId)) {
                roleTemplateId = roleTemplate.uuid;
            }
            operator$ = this._collaboratorsService.updateAccount(accountDetails, roleTemplateId)
        }
        else {
            operator$ = this._collaboratorsService.createAccount(accountDetails, roleTemplate.uuid)
        }
        operator$.pipe(
            mergeMap((account) => {
                if (!this.selectedOperator && this.addNewUserForm.controls['store'].value) {
                    return this.relatePersonToStore(account.personId, this.addNewUserForm.controls['store'].value);
                }
                return of(account);
            }),
            mergeMap((account) => {
                if (submitId == 'saveAndSendWelcome') {
                    // Send Welcome Mail
                    return this._authService.sendWelcomeEmail(this.addNewUserForm.controls['email'].value);
                } else {
                    return of(account);
                }
            })
        ).subscribe({
            next: (res) => {
                this.newUserForm.resetForm();
                this.addNewUserForm.enable();
                this._changeDetectorRef.markForCheck();
                this.dialog.closeAll();
            },
            error: (err) => {
                this.addNewUserForm.enable();
                this._changeDetectorRef.markForCheck();
            }
        });
    }

    deleteHandler(): void {
        if (!this.selectedOperator) {
            return;
        }

        const confirmation = this._fuseConfirmationService.open({
            title: this.translateStrings.operator.deleteWarning + ' ' + this.selectedOperator.firstName + ' ' + this.selectedOperator.lastName,
            message: this.translateStrings.operator.deleteWarningMessage,
            actions: {
                confirm: {
                    label: this.translateStrings.generic.delete
                }
            }
        });

        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this._collaboratorsService.deleteAccount(this.selectedOperator).subscribe();
            }
        });
    }

    /**
     * On backdrop clicked
     */
    onBackdropClicked(): void {
        // Go back to the list
        this._router.navigate(['./'], { relativeTo: this._activatedRoute });

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    openModal(): void {
        this.dialog.open(this.userModal, {
            panelClass: ['md:w-160', 'w-full']
        });
    }

    /**
     * opens a modal to add a new element
     */
    // addElement(): void{

    //     const modalElementConfig = {
    //         type: ModalElementConfig.type,
    //         height: 'full',
    //         width: 'full',
    //         elements: [
    //             {
    //                 chainElementConfigs:[
    //                     'HELLO'
    //                 ]
    //             }
    //         ]
    //     };

    //     const context = new ContextElement({}, undefined, undefined, undefined, undefined);

    //     const modalElementInstance = new ModalElement(modalElementConfig);

    //     modalElementInstance.init(context)
    //         .pipe(
    //             tap( (el) => {
    //                 this.dialogRef = this.dialog.open(GruulsModalElementComponent, {
    //                     height: '400px',
    //                     width: '600px',
    //                     data: {
    //                         element: modalElementInstance
    //                     }
    //                 });
    //                 this.dialogRef.afterClosed().pipe(map(el1 => ({result: el1})));
    //             })
    //         )
    //         .subscribe();

    // }

    relatePersonToStore(personId, storeId): Observable<any> {
        const query = {
            contextName: 'Beautycians',
            domainName: 'Store',
            commandName: 'RELATE_CLIENT_PERSON',
            body: {
                store: {
                    storeId: storeId,
                    personId: personId
                },
                assembleAs: {
                    active: true,
                    address: true,
                    zip: true,
                    name: true
                }
            }
        };

        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_ENDPOINT,
            body: query
        });
    }
}