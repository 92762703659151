import { Injectable } from "@angular/core";
import { GruulsApp } from "app/beautycians/utils/dataTypes";
import { catchError, map, mergeMap, Observable, of } from "rxjs";
import { GruulsBaseApiCallerService } from "./base-api-caller-service";
import { v4 as uuidv4 } from 'uuid';
import { Organization } from "@gruuls-core/types/organization.type";
import { GruulsConstants } from "@gruuls-core/utils/gruuls-constants";
import { ACCOUNT_ASSEMBLE, PERSON_MD_ASSEMBLE, PERSON_XL_ASSEMBLE, Person } from "@gruuls-core/types/person.type";
import { ComplexFilters, Utils } from "@gruuls-core/utils/Utils";
import moment_ from 'moment';
import _ from "lodash";

@Injectable()
export class GruulsApiCallerService extends GruulsBaseApiCallerService {

  /**
   * Get the application configuration
   * @returns {Observable<any>} - The application configuration
   */
  getAppConfiguration(): Observable<GruulsApp> {
    const query = {
      contextName: 'Core',
      domainName: 'App',
      queryName: 'FIND_BY_URL',
      url: window.location.href
    };
    return this.getFirstHitFromQuery(query);
  }

  getMenu(): Observable<any> {
    const query = {
      contextName: 'Core',
      domainName: 'Menu',
      queryName: 'FIND_ALL',
    };
    return this.getQuery(query);
  }

  getOrganizations(filters: {} = {}): Observable<any> {

    const query = {
      contextName: 'Core',
      domainName: 'Organization',
      queryName: 'FIND_ALL',
      assembleAs: {
        organizationId: true,
        name: true,
        vatNumber: true,
        extra: true,
      },
    };

    const where = {};
    Object.entries(filters).forEach(([key, value]) => {
      where[key.toString()] = value;
      //Utils.exactMatchExpression(key, value.toString());
    });
    if (Object.keys(where).length > 0)
      query['where'] = where

    return this.getQuery(query).pipe(
      map((response) => response.hits)
    );

  }

  createOrganization(organization: Organization, parentOrganizationId: string): Observable<any> {
    const aggregate = {
      ...organization,
      parent: {
        organizationId: parentOrganizationId,
      },
    };

    let body: any = {
      aggregate,
      assembleAs: {
        name: true,
        extra: true,
      }
    };

    const command = {
      contextName: 'Core',
      domainName: 'Organization',
      commandName: 'CREATE',
      commandType: 'CREATE_AGGREGATE_COMMAND',
      commandId: uuidv4(),
      body: body
    };

    return this.getFirstHitFromCommand(command);

  }

  updateOrganization(organization: Organization): Observable<any> {

    let body: any = {
      aggregate: organization,
      assembleAs: {
        name: true,
        extra: true,
      },
      where: {
        organizationId: organization.organizationId
      }
    };

    const command = {
      contextName: 'Core',
      domainName: 'Organization',
      commandName: 'UPDATE',
      commandType: 'UPDATE_AGGREGATE_COMMAND',
      commandId: uuidv4(),
      body: body
    };

    return this.getFirstHitFromCommand(command);

  }

  deleteOrganization(uuid: string): Observable<boolean> {
    if (!uuid)
      return;

    let body: any = {
      where: {
        organizationId: uuid
      },
      assembleAs: {
        organizationId: true,
      }
    };

    const command = {
      contextName: 'Core',
      domainName: 'Organization',
      commandName: 'LOGICAL_DELETE',
      commandType: 'NORMAL_COMMAND',
      body
    };

    return this._httpClient.doPost({
      url: GruulsConstants.COMMAND_API_ENDPOINT,
      body: command
    }).pipe(
      map((res) => {
        if (res && res.length > 0 && res[0].organizationId === uuid)
          return true;
        else return false;
      })
    )
  }

  relatePersonToOrganization(personId: string, _referenceOrganizationId: string): Observable<any> {
    const command = {
      contextName: 'Core',
      domainName: 'Person',
      commandName: 'CHANGE_REFERENCE_ORGANIZATION_ID',
      body: {
        aggregate: {
          _referenceOrganizationId: _referenceOrganizationId,
          personId: personId
        },
        assembleAs: {
          personId: true
        }
      }
    };

    return this.getFirstHitFromCommand(command);
  }

  createAccount(organizationId: string, details: {}, email: string, roleTemplateId: string, defaultPwd: string = null): Observable<any> {
    let pwd: any = {};
    if (defaultPwd && defaultPwd != '') {
      pwd = {
        password: defaultPwd,
        passwordVerify: defaultPwd
      }
    }

    const aggregate = {
      ...details,
      account: {
        username: email,
        email: email,
        ...pwd
      },
      organizationId: organizationId,
      roles: {
        inOrganization: {
          organizationId: organizationId
        },
        hasRole: [{
          roleTemplateId: roleTemplateId
        }]
      }
    };

    let body: any = {
      aggregate,
      assembleAs: ACCOUNT_ASSEMBLE,
    };

    const command = {
      contextName: 'Core',
      domainName: 'Person',
      commandName: 'CREATE',
      commandType: 'CREATE_AGGREGATE_COMMAND',
      commandId: uuidv4(),
      body
    };

    return this.getFirstHitFromCommand(command).pipe(
      mergeMap((resPerson) => {
        if (!resPerson._referenceOrganizationId || resPerson._referenceOrganizationId != organizationId) {
          return this.relatePersonToOrganization(resPerson.personId, organizationId).
            pipe(map((res) => resPerson));
        } else {
          return of(resPerson);
        }
      })
    );
  }

  updateAccount(accountDeatails: Person, organizationId: string, roleTemplateId: string = null): Observable<any> {
    let roleUpdate = {};

    if (!accountDeatails.personId)
      return;

    if (roleTemplateId && organizationId) {
      roleUpdate = {
        roles: {
          inOrganization: {
            organizationId: organizationId
          },
          hasRole: [{
            roleTemplateId: roleTemplateId
          }]
        }
      }
    }

    const command = {
      contextName: 'Core',
      domainName: 'Person',
      commandName: 'UPDATE',
      commandType: 'UPDATE_AGGREGATE_COMMAND',
      commandId: uuidv4(),
      body: {
        aggregate: {
          ...accountDeatails,
          ...roleUpdate
        },
        where: {
          personId: accountDeatails.personId
        },
        assembleAs: ACCOUNT_ASSEMBLE,
      },
    };
    return this.getFirstHitFromCommand(command);
  }

  impersonate(username: string): Observable<any> {
    const command = {
      contextName: 'Core',
      domainName: 'Person',
      commandName: 'IMPERSONATE',
      commandType: 'NORMAL_COMMAND',
      commandId: uuidv4(),
      body: {
        username
      },
    };

    return this.getFirstHitFromCommand(command);
  }

  createPerson(client: Person, organizationId: string, roleTemplateId: string): Observable<any> {
    const command = {
      contextName: 'Core',
      domainName: 'Person',
      commandName: 'CREATE',
      commandType: 'CREATE_AGGREGATE_COMMAND',
      commandId: uuidv4(),
      body: {
        aggregate: {
          ...client,
          roles: {
            inOrganization: {
              organizationId: organizationId
            },
            hasRole: [{
              roleTemplateId: roleTemplateId
            }]
          }
        },
        assembleAs: PERSON_XL_ASSEMBLE,
      },
    };

    return this.getFirstHitFromCommand(command);
  }

  updatePerson(uuid: string, client: {}): Observable<any> {
    const command = {
      contextName: 'Core',
      domainName: 'Person',
      commandName: 'UPDATE',
      commandType: 'UPDATE_AGGREGATE_COMMAND',
      commandId: uuidv4(),
      body: {
        aggregate: {
          ...client
        },
        where: {
          personId: uuid
        },
        assembleAs: PERSON_XL_ASSEMBLE,
      },
    };

    return this.getFirstHitFromCommand(command);
  }

  /**
       * Get Persons
       * @description
       * This method is used to return all the available roles
       * @param {} filters - Filters to be applied to the query
       * @returns {Observable<any>} - A parsed list of treatments
       * 
       **/
  getPersons(filters: {} = {}, assembleAs: {} = ACCOUNT_ASSEMBLE, sort: string[] | {}[] = [], page: number = null): Observable<any> {
    const query = {
      contextName: 'Core',
      domainName: 'Person',
      queryName: 'FIND_ALL',
      assembleAs,
      sort
    };
    if (page != null) {
      query['pagination'] = this.paginationObj(page);
    }

    const where = {};
    Object.entries(filters).forEach(([key, value]) => {
      where[key.toString()] = value;
      //Utils.exactMatchExpression(key, value.toString());
    });
    if (Object.keys(where).length > 0) {
      query['where'] = where
    }
    return this.getQuery(query).pipe(
      map((response) => response.hits),
      map((persons) => {
        return persons.map((person) => {
          person.age = person.birthdate ? moment_().diff(moment_(person.birthdate, "YYYY-MM-DD"), 'years') : null;
          return person;
        })
      })
    );
  }

  getPerson(uuid: string, assembleAs: any = PERSON_MD_ASSEMBLE): Observable<any> {
    return this.getPersons({ 'personId': uuid }, assembleAs).pipe(
      map((personResponse) => personResponse[0])
    );
  }

  getPersonsByRoleTemplateName(roleTemplateNames: string[], filters: {} = {}, assembleAs: {} = PERSON_MD_ASSEMBLE, page: number = null): Observable<{ hits, hasNextPage }> {
    const sort = [];
    let f: ComplexFilters[] = [];

    let where = {};
    where['roleTemplateName'] = roleTemplateNames;
    Object.entries(filters).forEach(([key, value]) => {
      const field = key.toString().startsWith('not_') ? key.toString().substring(4) : key.toString();
      f.push({ field, value: value.toString(), operator: 'match_phrase', affirmative: !key.toString().startsWith('not_') });
    });
    where = _.merge(where, Utils.buildWhereExpression(f));
    if (Object.keys(filters).length === 0) {
      console.warn("No filters provided for GET_BY_ROLE query while looking for templates: " + roleTemplateNames.toString());
    }

    const query = {
      contextName: 'Core',
      domainName: 'Person',
      queryName: 'GET_BY_ROLE',
      assembleAs,
      where,
      // sort
    };

    if (page != null) {
      query['pagination'] = this.paginationObj(page);
    }

    return this._httpClient.doPost({
      url: GruulsConstants.QUERY_API_ENDPOINT,
      body: query
    }).pipe(
      map((res) => {
        return {
          hits: res.hits,
          hasNextPage: res.next_page
        }
      }),
      catchError((err) => {
        console.log(err);
        return of({ hits: [], hasNextPage: false, error: true });
      }
      ));
  }
}
