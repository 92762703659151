import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit, TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { GruulsAngularHttpProxyService } from '../../../../../@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { GruulsAngularTranslateService } from '../../../../../@gruuls-fe/services/gruuls-angular-translate.service';
import { MatDialog } from '@angular/material/dialog';
import { GruulsAuthService } from "../../../../../@gruuls-fe/services/gruuls-auth.service";
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { GruulsAngularStoreService } from '@gruuls-fe/services/gruuls-angular-stores-service';
import { Subject, takeUntil, tap } from 'rxjs';
import { Store } from 'app/beautycians/utils/dataTypes';

@Component({
  selector: 'appointment',
  templateUrl: './appointment.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppointmentComponent {
  // events: any[] = [];
  isLoading: boolean = false;
  activeStore: Store;
  translateStrings: any = { appointment: { }, store: { } };
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private _document: any,
    private _router: Router,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _httpClient: GruulsAngularHttpProxyService,
    private _translate: GruulsAngularTranslateService,
    private dialog: MatDialog,
    private _authService: GruulsAuthService,
    private storeService: GruulsAngularStoreService
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.storeService.activeStore$.pipe(
      takeUntil(this._unsubscribeAll),
      tap((store) => {
        this.activeStore = store;
        this.isLoading = false;
        this._changeDetectorRef.markForCheck();
      })
    ).subscribe();

    const storeTranslations = ['create'];
    storeTranslations.forEach((translation) => {
        this.translateStrings['store'][translation] = this._translate.translate('stores.' + translation);
    });

    const appointementsTranslations = ['noStore'];
    appointementsTranslations.forEach((translation) => {
        this.translateStrings['appointment'][translation] = this._translate.translate('appointment.' + translation);
    });



  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

}