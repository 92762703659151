/**
 * Created by marcoabi on 11/02/18.
 */
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap, take } from 'rxjs/operators';
import { GruulsLogin } from '@gruuls-core/login/gruuls-login';
import { GruulsAuthServiceInterface } from '@gruuls-core/service-interfaces/gruuls-auth-service-interface';
import { GruulsUrlRoutingInterface } from '@gruuls-core/service-interfaces/gruuls-url-routing-interface';
import { CurrentUser } from '../../@gruuls-core/models/user/current-user';
import { GruulsLocalStorageInterface } from '../../@gruuls-core/service-interfaces/gruuls-local-storage-interface';
import { GruulsLocalStorageService } from '../../@gruuls-core/infrastructure/gruuls-local-storage-service';
import { GruulsCookieInterface } from '../../@gruuls-core/service-interfaces/gruuls-cookie-interface';
import { GruulsCookie } from '../../@gruuls-core/infrastructure/gruuls-cookie';
import { GruulsAngularHttpProxyService } from './gruuls-angular-http-proxy.service';
import { MyOrganization } from '../../@gruuls-core/models/organization/my-organization';
import { FuseConfigService } from '../../@fuse/services/config';
import { GruulsAngularRoutingService } from './gruuls-angular-routing.service';


@Injectable({
    providedIn: 'root'
})
export class GruulsAuthService implements GruulsAuthServiceInterface {

    private loginService$: GruulsLogin;
    selectedOrganizationSubscription: Subscription;

    private http: HttpClient;
    private router$: Router;
    private activatedRoute$: GruulsUrlRoutingInterface;

    constructor(
        private injector: Injector
    ) {


    }

    private static getParameterByName(name): string {
        const url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    public tryLoadUser(token?: string): Observable<CurrentUser | undefined> {
        return this.loginService.tryLoadUser(token);
        // return new Promise<void>((resolve, reject) => {
        //   const llt = AuthService.getParameterByName('llt');
        //   const longLivingToken = llt ? llt : undefined;
        //   this.loginService.tryLoadUser(longLivingToken).subscribe(() => resolve(), (err) => reject(err));

        // this.initSub = this.activatedRoute
        //  .queryParams
        //  .subscribe((p) => {
        //    this.startInitTimeout(p, resolve, reject);
        //  });
        // });
    }

    get router(): Router {
        if (!this.router$) {
            this.router$ = this.injector.get(Router);
        }
        return this.router$;
    }

    get activatedRoute(): GruulsUrlRoutingInterface {
        if (!this.activatedRoute$) {
            const activatedRoute = this.injector.get(ActivatedRoute);
            const router = this.injector.get(Router);
            this.activatedRoute$ = new GruulsAngularRoutingService(router, activatedRoute);
        }
        return this.activatedRoute$;
    }

    get loginService(): GruulsLogin {
        if (!this.loginService$) {
            this.http = this.injector.get(HttpClient);
            const localStorageImpl: GruulsLocalStorageInterface = new GruulsLocalStorageService();
            const cookieImpl: GruulsCookieInterface = new GruulsCookie();
            const proxy = new GruulsAngularHttpProxyService(this.http);
            this.loginService$ = new GruulsLogin(proxy, localStorageImpl, cookieImpl, this.activatedRoute);
        }

        return this.loginService$;

    }

    public sendWelcomeEmail(email: string): Observable<any> {
        return this.loginService.sendWelcomeEmail(email);
    }

    public forgotPassword(email: string): Observable<any> {
        return this.loginService.forgotPassword(email);
    }

    public resetPassword(password: string, token: string): Observable<any> {
        return this.loginService.resetPassword(password, token);
    }

    public impersonate(username: string) {
        return this.loginService.impersonate(username);
    }

    public getImpersonatingUpdates(): Observable<any> {
        return this.loginService.getImpersonatingUpdates();
    }

    public stopImpersonate() {
        return this.loginService.stopImpersonate(this.getMainUserAccessToken());
    }

    public getAccessToken(): string | undefined {
        let token = localStorage.getItem('jwtImpersonate');
        if (!token)
            token = this.getMainUserAccessToken();
        return token;
    }

    private getMainUserAccessToken(): string | undefined {
        let token;
        token = GruulsAuthService.getParameterByName('llt');
        if (!token) {
            token = GruulsAuthService.getParameterByName('jwt');
        }
        if (!token) {
            token = localStorage.getItem('jwt');
        }
        return token;

    }

    public getUnderlyingLoginModel(): GruulsLogin {
        return this.loginService;
    }

    public isAuthenticated(): Observable<CurrentUser> {
        return this.loginService.isAuthenticated();
    }

    public currentUserObservable(): Observable<CurrentUser> {
        return this.loginService.currentUserObservable();
    }

    login(username: string, password: string): Observable<CurrentUser> {
        return this.loginService.login(username, password)
            .pipe(
                map((user: CurrentUser) => {
                    this.subscribeOrganizationChange(user);
                    return user;
                })
            );
    }

    public userAvailable(): boolean {
        const ua = this.loginService.userAvailable();
        if (ua) {
            this.subscribeOrganizationChange(this.loginService.getCurrentLoggedUser());
        }
        return ua;
    }

    public getCurrentLoggedUser(): CurrentUser | undefined {
        const user = this.loginService.getCurrentLoggedUser();
        if (user) {
            this.subscribeOrganizationChange(user);
        }
        return user;
    }

    public getCurrentLoggedUserObservable(): Observable<CurrentUser> {
        return this.loginService.currentUserObservable();
    }

    private subscribeOrganizationChange(currentUser: CurrentUser): void {
        if (!this.selectedOrganizationSubscription) {
            this.selectedOrganizationSubscription = currentUser.observeSelectedOrganizationChange()
                .subscribe((selectedOrg: MyOrganization) => {
                    const i = 0;
                    // this.router.navigateByUrl(CommonPaths.HOME);
                });
        }
    }

    logout(): Observable<any> {
        return this.loginService.logout()
            .pipe(
                mergeMap((res) => {
                    const _fuseConfigService = this.injector.get(FuseConfigService);
                    return _fuseConfigService.config$;
                }),
                take(1),
                map((config) => {
                    config.routesLoaded = false;
                    config.hasBeenAlreadyLoaded = false;
                    return config;
                })
            );
    }

}
