import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, tap, map, throwError, take, switchMap, filter } from 'rxjs';
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { GruulsAngularHttpProxyService } from '@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { GruulsAuthService } from '@gruuls-fe/services/gruuls-auth.service';
import { Person } from '@gruuls-core/types/person.type';
import { Utils } from '@gruuls-core/utils/Utils';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  private _client: BehaviorSubject<Person | null> = new BehaviorSubject(null);
  private _clients: BehaviorSubject<Person[] | null> = new BehaviorSubject(null);
  private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);
  private _lastSubscriptionTime: number = null;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: GruulsAngularHttpProxyService,
    private _authService: GruulsAuthService
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for client
   */
  get client$(): Observable<Person> {
    return this._client.asObservable();
  }

  /**
   * Getter for clients
   */
  get clients$(): Observable<Person[]> {
    return this._clients.asObservable();
  }

  /**
   * Getter for medicalHistories
   */
  //   get medicalHistories$(): Observable<MedicalHistory[]> {
  //     return this._medicalHistories.asObservable();
  //   }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get clients
   */
  getClients(page: number = null): Observable<Person[]> {
    const paginate = null;
    return this._apiCaller.getPersonsByRoleTemplateName(['Client'], { '_referenceOrganizationId': this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId }).pipe(
      map((clients) => clients.hits),
      map((clients) => Utils.sortByKeys(clients, ['firstName', 'lastName'])),
      tap((clients: Person[]) => {
        this._lastSubscriptionTime = Date.now();
        this._clients.next(clients);
      })
    );
  }

  refreshClients(page: number = null, query: string = ""): Observable<Person[]> {
    const now = Date.now();
    const UPDATE_DELTA = 15 * 60 * 1000; // 15 min.

    if (this._lastSubscriptionTime == null || (now - this._lastSubscriptionTime) > UPDATE_DELTA)
      return this.getClients(page);
    else
      return of(this._clients.getValue());
  }

  getClientById(id: string): Observable<Person> {
    return this._clients.pipe(
      take(1),
      map((clients) => {

        // Find the client
        const client = clients.find(item => item.personId === id) || null;

        // Update the client
        this._client.next(client);

        // Return the client
        return client;
      }),
      switchMap((client) => {
        if (!client) {
          throwError(() => 'Could not found client with id of ' + id + '!');
        }
        return of(client);
      })
    );
  }

  /**
   * Create client
   */
  createClient(client: Person, storeId: string = null): Observable<Person> {
    return this.clients$.pipe(
      take(1),
      switchMap(clients => this._apiCaller.createBeautyClient(client, this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId, storeId).pipe(
        map((newClient) => {
          // TODO: improve UPDATE by getting the correct data
          // Update the products with the new product
          const updatedClientList = [...clients, { ...client, personId: newClient.personId }];
          this._clients.next(Utils.sortByKeys(updatedClientList, ['firstName', 'lastName']));

          // Return the new product
          return { ...client, personId: newClient.personId };
        })
      ))
    );
  }

  /**
   * Update client
   *
   * @param id
   * @param client
   */
  updateClient(client: Person): Observable<Person> {
    if (!client.personId)
      throwError(() => 'Could not found personId!');

    return this.clients$.pipe(
      take(1),
      switchMap(clients => this._apiCaller.updatePerson(client.personId, client).pipe(
        map((updatedClient) => {

          // Find the index of the updated client
          const index = clients.findIndex(item => item.personId === client.personId);

          // Update the client
          // clients[index] = updatedClient;
          clients[index] = { ...client, personId: updatedClient.personId };

          // Update the clients
          this._clients.next(clients);

          // Return the updated client
          return updatedClient;
        }),
        // switchMap(updatedClient => this.client$.pipe(
        //   take(1),
        //   filter(item => (item && item.personId === client.personId)),
        //   tap(() => {

        //     // Update the client if it's selected
        //     this._client.next(updatedClient);

        //     // Return the updated client
        //     return updatedClient;
        //   })
        // ))
      ))
    );
  }

  /**
   * Delete the client
   *
   * @param id
   */
  deleteClient(id: string): Observable<boolean> {
    return this.clients$.pipe(
      take(1),
      switchMap(clients => this._apiCaller.deletePerson(id).pipe(
        map((isDeleted: boolean) => {

          // Find the index of the deleted client
          const index = clients.findIndex(item => item.personId === id);

          // Delete the client
          clients.splice(index, 1);

          // Update the clients
          this._clients.next(clients);

          // Return the deleted status
          return isDeleted;
        })
      ))
    );
  }


}
