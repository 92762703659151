import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, NgForm, Validators } from '@angular/forms';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { forkJoin, mergeMap, Observable, of, Subject } from 'rxjs';
import { delay, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Contact, Tag } from './store-details.types';
import { GruulsConstants } from '../../../../../@gruuls-core/utils/gruuls-constants';
import { GruulsAngularHttpProxyService } from '../../../../../@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { BeautyciansUtils } from '../../../utils/utils';
import { MatDialog } from '@angular/material/dialog';
import { GruulsAngularTranslateService } from '../../../../../@gruuls-fe/services/gruuls-angular-translate.service';
import moment from 'moment/moment';
import { GruulsAuthService } from '../../../../../@gruuls-fe/services/gruuls-auth.service';
import { GruulsAngularCabinService } from '@gruuls-fe/services/gruuls-angular-cabin-service';
import { Cabin } from 'app/beautycians/utils/dataTypes';
import { GruulsAngularStoreService } from '@gruuls-fe/services/gruuls-angular-stores-service';
import { fork } from 'child_process';
import { Utils } from '@gruuls-core/utils/Utils';

@Component({
    selector: 'store-details',
    templateUrl: './store-details.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoreDetailsComponent implements OnInit, OnDestroy {
    @ViewChild('newUserForm') newUserForm: NgForm;
    @ViewChild('newCabinForm') newCabinForm: NgForm;
    @ViewChild('userModal') userModal: TemplateRef<any>;
    @ViewChild('cabinModal') cabinModal: TemplateRef<any>;

    editMode: boolean = false;
    store: any;
    cabins: Cabin[] = [];
    searchInputControl: FormControl = new FormControl();
    filteredContacts: any[] = [];
    private _contacts: any[] = [];
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    addNewUserFormTitle: Observable<string>;

    translateStrings: any = { client: {}, user: {}, generic: {}, cabin: {} };
    selectedUser: any;
    selectedCabin: Cabin;
    isSpecificLoading: boolean[] = [];

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: FormBuilder,
        private _fuseConfirmationService: FuseConfirmationService,
        private _httpClient: GruulsAngularHttpProxyService,
        private _fb: FormBuilder,
        private dialog: MatDialog,
        private _translate: GruulsAngularTranslateService,
        private _authService: GruulsAuthService,
        private _cabinService: GruulsAngularCabinService,
        private _storeService: GruulsAngularStoreService
    ) {
    }

    @HostBinding('class') get classList(): any {
        return {
            'w-full': true
        };
    }

    addNewUserForm = this._fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        address: ['', Validators.required],
        sex: ['', Validators.required],
        birthdate: ['', Validators.required]
    });

    addNewCabinForm = this._fb.group({
        name: ['', Validators.required],
        type: ['']
    });

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        // Get route params
        this._activatedRoute.params.pipe(
            takeUntil(this._unsubscribeAll),
        ).subscribe((params) => {
            forkJoin([
                this._cabinService.getCabinsByStoreId(params.id),
                this._storeService.getStoreById(params.id),
            ]).subscribe(([cabins, store]) => {
                this._changeDetectorRef.markForCheck();
            });
        });

        // Subscribe to search input field value changes
        this.searchInputControl.valueChanges.pipe(
            takeUntil(this._unsubscribeAll),
            map(query => Utils.searchStringByKeys(this._contacts, query, ['firstName', 'lastName'])),
            map(queryResult => Utils.sortByKeys(queryResult, ['firstName', 'lastName'])),
            tap(filteredContacts => this.filteredContacts = filteredContacts)
        ).subscribe(() => this._changeDetectorRef.markForCheck());

        this.dialog.afterOpened.pipe(
            takeUntil(this._unsubscribeAll),
            delay(0)
        ).subscribe(() => {
            if (this.selectedCabin) {
                this.addNewCabinForm.patchValue(this.selectedCabin);
            } else {
                this.addNewCabinForm.reset();
                this._changeDetectorRef.markForCheck();
            }
        });

        this._storeService.store$.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe((store) => {
            this.store = store;
            this._changeDetectorRef.markForCheck();
        });

        this._cabinService.cabins$.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe((cabins) => {
            this.cabins = cabins;
            this._changeDetectorRef.markForCheck();
        });

        // Translations
        const clientTranslations = ['plural', 'singular', 'noClients', 'search', 'addNew', 'noSearchResults'];
        clientTranslations.forEach((translation) => {
            this.translateStrings['client'][translation] = this._translate.translate('clients.' + translation);
        });

        const userTranslations = ['firstName', 'lastName', 'address', 'sex', 'male', 'female', 'ND', 'birthdate', 'pictureUrl', 'roles'];
        userTranslations.forEach((translation) => {
            this.translateStrings['user'][translation] = this._translate.translate('users.' + translation);
        });

        const cabinTranslations = ['addNew', 'noSearchResults', 'search', 'plural', 'singular', 'name', 'type', 'deleteWarning', 'deleteWarningMessage'];
        cabinTranslations.forEach((translation) => {
            this.translateStrings['cabin'][translation] = this._translate.translate('cabins.' + translation);
        });

        const genericTranslations = ['save', 'send', 'close', 'cancel'];
        genericTranslations.forEach((translation) => {
            this.translateStrings['generic'][translation] = this._translate.translate('generic.' + translation);
        });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    userFormSubmit(): void {
    }

    cabinFormSubmit(): void {
        let cabinUpdateObservable: Observable<Cabin>;
        let cabin: Cabin = this.addNewCabinForm.value;
        cabin.store = { storeId: this.store.storeId };
        this.isSpecificLoading['saveCabin'] = true;
        if (this.selectedCabin) {
            cabin.cabinId = this.selectedCabin.cabinId;
            cabinUpdateObservable = this._cabinService.updateCabin(cabin);
        } else {
            cabinUpdateObservable = this._cabinService.createCabin(cabin);
        }
        cabinUpdateObservable.subscribe(() => {
            this.dialog.closeAll();
            this.isSpecificLoading['saveCabin'] = false;
        });
    }

    deleteCabin(cabin: Cabin): void {
        const confirmation = this._fuseConfirmationService.open({
            title: this.translateStrings.cabin.deleteWarning + " " + cabin.name,
            message: this.translateStrings.cabin.deleteWarningMessage,
            actions: {
                confirm: {
                    label: this.translateStrings.generic.delete
                }
            }
        });

        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this.isSpecificLoading['deleteCabin'] = true;
                this._cabinService.deleteCabin(cabin).subscribe({
                    next: () => {
                        this.isSpecificLoading['deleteCabin'] = false;
                    },
                    error: () => {
                        this.isSpecificLoading['deleteCabin'] = false;
                    }
                });
            }
        })
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    getStoreClients(storeId: number): Observable<Contact[]> {
        return of([]);
    }

    openModal(): void {
        this.dialog.open(this.userModal, {
            panelClass: ['md:w-160', 'w-full']
        });
    }

    openCabinModal(): void {
        this.dialog.open(this.cabinModal, {
            panelClass: ['md:w-160', 'w-full']
        });
    }
}
