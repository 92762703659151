import { Injectable } from "@angular/core";
import { GruulsAngularHttpProxyService } from "@gruuls-fe/services/gruuls-angular-http-proxy.service";
import { GruulsAuthService } from "@gruuls-fe/services/gruuls-auth.service";
import { GruulsConstants } from "@gruuls-core/utils/gruuls-constants";
import { map, Observable } from "rxjs";

export interface Command {
  contextName: string;
  domainName: string;
  commandName: string;
  commandId?: string;
  commandType?: string;
  body: {
      aggregate?: string;
      assembleAs?: string;
      where?: any;
  } | any;
}

export interface Query {
  contextName: string;
  domainName: string;
  queryId?: string;
  queryType?: string;
  where?: any;
  assembleAs?: any;
}

export interface GruulsQueryResponse {
  hits: any[];
  next_page: boolean;
  stats: any;
  info: {
    queryId: string;
  }
}

@Injectable()
export class GruulsBaseApiCallerService {

  constructor(
    protected _httpClient: GruulsAngularHttpProxyService,
    protected _authService: GruulsAuthService
  ) { }

  getFirstHitFromQuery(body: Query): Observable<any> {
    return this.getQuery(body).pipe(
      map((res) => res.hits[0])
    );
  }

  getQuery(body: Query): Observable<GruulsQueryResponse> {
    return this._httpClient.doPost({
      url: GruulsConstants.QUERY_API_ENDPOINT,
      body
    })
  }

  getFirstHitFromCommand(body: Command): Observable<any> {
    return this._httpClient.doPost({
      url: GruulsConstants.COMMAND_API_ENDPOINT,
      body
    }).pipe(
      map((res) => res.hits[0])
    );
  }

  paginationObj(page: number): {} {
    const pageSize = 50;

    return {
      skip: pageSize * page,
      limit: pageSize
    }
  }

}