import { StoreService } from "app/beautycians/services/storeService";
import { GruulsAngularHttpProxyService } from "./gruuls-angular-http-proxy.service";
import { GruulsAuthService } from "./gruuls-auth.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Store } from "app/beautycians/utils/dataTypes";
import { GruulsAngularBrowserCacheService } from "./gruuls-angular-browser-cache-service";
import { GruulsAngularCabinService } from "./gruuls-angular-cabin-service";

@Injectable()
export class GruulsAngularStoreService {
  storeService: StoreService;

  public constructor(
    private _httpClient: GruulsAngularHttpProxyService,
    private _authService: GruulsAuthService,
    private browserCacheService: GruulsAngularBrowserCacheService,
    private cabinService: GruulsAngularCabinService
  ) {
    this.storeService = new StoreService(this._httpClient, this._authService, this.browserCacheService, this.cabinService);
  }

  get activeStore(): Store {
    return this.storeService.activeStore;
  }

  get activeStore$(): Observable<Store> {
    return this.storeService.activeStore$;
  }

  get store$(): Observable<Store> {
    return this.storeService.store$;
  }

  get stores$(): Observable<Store[]> {
    return this.storeService.stores$;
  }

  public getStores(page: number = null): Observable<Store[]> {
    return this.storeService.getStores();
  }

  public refreshStores(page: number = null): Observable<Store[]> {
    return this.storeService.refreshStores();
  }

  public getStoreById(storeId: string): Observable<Store> {
    return this.storeService.getStoreById(storeId);
  }

  public getActiveStore(): Observable<Store> {
    return this.storeService.getActiveStore();
  }

  public setActiveStore(store: Store): Observable<Store> {
    return this.storeService.setActiveStore(store);
  }

  public updateStore(store: Store): Observable<Store> {
    return this.storeService.updateStore(store);
  }

  public createStore(store: Store): Observable<Store> {
    return this.storeService.createStore(store);
  }

  public deleteStore(storeId: string): Observable<boolean> {
    return this.storeService.deleteStore(storeId);
  }
}
