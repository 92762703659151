import { HttpClient } from "@angular/common/http";
import { GruulsMockDomain } from "../../gruuls-mock-domain";


export class GruulsCoreMenuMockDomain extends GruulsMockDomain {

  constructor(httpClient: HttpClient) {
    super(httpClient);

    // Currently this not used a part from testing for the tree component
    this.entities = [{
      id: 'client',
      title: 'Clienti',
      subtitle: 'Gestisci i tuoi clienti',
      type: 'group',
      icon: 'heroicons_outline:user-group',
      children: [
        {
          id: 'client.list',
          title: 'Clienti',
          type: 'basic',
          icon: 'heroicons_outline:users',
          link: '/beauty/client/list'
        },
        {
          id: 'client.appointment',
          title: 'Appuntamenti',
          type: 'basic',
          icon: 'heroicons_outline:calendar',
          link: '/beauty/client/appointment'
        },
      ]
    },
    {
      id: 'owner',
      title: 'Account',
      subtitle: 'Gestisci gli account della catena',
      roles: ['Beautycians-HQ', 'Beautycians-HQ-Sales'],
      type: 'group',
      icon: 'heroicons_outline:user-group',
      children: [
        {
          id: 'centri.list',
          title: 'Centri',
          type: 'basic',
          icon: 'heroicons_outline:building-storefront',
          link: '/beauty/centri/list'
        },
        {
          id: 'owner.list',
          title: 'Account',
          type: 'basic',
          icon: 'heroicons_outline:users',
          link: '/beauty/owner/list'
        },
        // {
        //     id: 'student.list',
        //     title: 'Studenti',
        //     type: 'basic',
        //     icon: 'heroicons_outline:academic-cap',
        //     link: '/beauty/student/list'
        // }
      ]
    },
    {
      id: 'store',
      title: 'Punti vendita',
      roles: ['Beautycians-Stores-Owner', 'Beautycians-Store-Manager', 'Beautycians-Stores-Manager', 'Beautycians-HQ'], subtitle: 'Gestisci i negozi della catena',
      type: 'group',
      children: [
        {
          id: 'store.list',
          title: 'Punti vendita',
          type: 'basic',
          icon: 'heroicons_outline:location-marker',
          link: '/beauty/store/list'
        },
        {
          id: 'operator.list',
          title: 'Collaboratori',
          type: 'basic',
          icon: 'heroicons_outline:briefcase',
          link: '/beauty/operator/list'

        }
      ]
    },
    {
      id: 'product',
      title: 'Prodotti',
      subtitle: 'Gestisci i tuoi prodotti',
      type: 'group',
      children: [
        {
          id: 'product.list',
          title: 'Prodotti',
          type: 'basic',
          icon: 'heroicons_outline:shopping-bag',
          link: '/beauty/product/'
        },
        {
          id: 'order.list',
          title: 'Ordini',
          type: 'basic',
          icon: 'heroicons_outline:document-text',
          link: '/beauty/order/list'
        },
        // {
        //     id: 'file-manager.list',
        //     title: 'Fatture',
        //     type: 'basic',
        //     icon: 'heroicons_outline:document-text',
        //     link: '/beauty/invoices/'
        // }
      ]
    }
    ];

  }
}