import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { GruulsAngularHttpProxyService } from '../../../../@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { GruulsAngularTranslateService } from '../../../../@gruuls-fe/services/gruuls-angular-translate.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GruulsAuthService } from "../../../../@gruuls-fe/services/gruuls-auth.service";
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction'; // for selectable
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import itLocale from '@fullcalendar/core/locales/it';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Calendar, CalendarDrawerMode, CalendarEvent, CalendarEventEditMode, CalendarEventPanelMode, CalendarSettings } from './mycalendar.types';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar as FullCalendar } from '@fullcalendar/core';
import momentPlugin from '@fullcalendar/moment';
import rrulePlugin from '@fullcalendar/rrule';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject, filter, map, of, startWith, switchMap, takeUntil, tap } from 'rxjs';
import moment, { Moment } from 'moment';
import RRule from 'rrule';
import { TemplatePortal } from '@angular/cdk/portal';
import { CalendarService } from './mycalendar.service';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { MatDrawer } from '@angular/material/sidenav';
import { cloneDeep } from 'lodash-es';
import { Person } from '@gruuls-core/types/person.type';
import { ClientsService } from 'app/beautycians/services/clientService';
import AllDaySplitter from '@fullcalendar/timegrid/AllDaySplitter';
import { Cabin, Store } from 'app/beautycians/utils/dataTypes';
import { _countGroupLabelsBeforeOption } from '@angular/material/core';
import { GruulsAngularStoreService } from '@gruuls-fe/services/gruuls-angular-stores-service';
import { AppointmentStatus } from 'app/beautycians/utils/appointmentStatus';
import { Utils } from '@gruuls-core/utils/Utils';
import { GruulsAngularAppointmentsService } from '@gruuls-fe/services/gruuls-angular-appointments-service';
import { GruulsAngularCollaboratorService } from '@gruuls-fe/services/gruuls-angular-collaborator-service';

const viewMapping = {
  'timeGridOneDay': 'resourceTimeGridDay',
  'resourceTimeGridDay': 'timeGridOneDay',
  'timeGridThreeDays': 'resourceTimeGridThreeDays',
  'resourceTimeGridThreeDays': 'timeGridThreeDays'
}
const viewResource: 'resourceTimeGridDay' | 'resourceTimeGridThreeDays' = 'resourceTimeGridDay';
const rightMenuResource = 'resourceTimeGridDay, resourceTimeGridThreeDays';
const view: 'timeGridWeek' | 'timeGridOneDay' | 'timeGridThreeDays' = 'timeGridOneDay';
const rightMenu = 'timeGridOneDay, timeGridThreeDays';
type AllowedView = typeof view[number];
type AllowedViewResource = typeof viewResource[number];

@Component({
  selector: 'mycalendar',
  templateUrl: './mycalendar.component.html',
  styleUrls: ['./mycalendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyCalendarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input('defaultEventColor') defaultEventColor: string = '#461220';
  @Input('personId') defaultPersonId: string;
  @Output() isLoadingEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('eventPanel') private _eventPanel: TemplateRef<any>;
  @ViewChild('fullCalendar') private _fullCalendar: FullCalendarComponent;
  @ViewChild('drawer') private _drawer: MatDrawer;

  cabins: Cabin[];
  calendars: Calendar[];
  calendarPlugins: any[] = [dayGridPlugin, interactionPlugin, listPlugin, momentPlugin, rrulePlugin, timeGridPlugin, resourceTimeGridPlugin];
  drawerMode: CalendarDrawerMode = 'side';
  drawerOpened: boolean = true;
  event: CalendarEvent;
  events: CalendarEvent[] = [];
  eventEditMode: CalendarEventEditMode = 'single';
  eventForm: FormGroup;
  eventTimeFormat: any;
  bindedClient: Person;
  isSpecificLoading: boolean[] = [];
  isLoadingError: boolean = false;
  appointmentPickerValue: Date;
  activeView: AllowedView | AllowedViewResource;
  defaultView: AllowedView | AllowedViewResource = view;
  header: {} = {
    left: 'prev,next today',
    center: 'title',
    right: rightMenu
  }
  panelMode: CalendarEventPanelMode = 'view';
  settings: CalendarSettings;
  resources: any = [];
  // groupingColumn: string = 'groupId';
  lic: string = 'CC-Attribution-' + 'NonCommercial-NoDerivatives';
  // view: 'dayGridMonth' | 'timeGridWeek' | 'timeGridThreeDays' | 'timeGridOneDay' | 'timeGridDay' | 'listYear' | 'resourceTimeGridDay' | 'resourceTimeGridThreeDays' = 'resourceTimeGridDay';
  views: any;
  viewTitle: string;
  errorDialog: any;

  private _eventPanelOverlayRef: OverlayRef;
  private _fullCalendarApi: FullCalendar;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  calendar: Calendar;
  translateStrings: any = { appointment: {}, calendar: {}, generic: {}, treatment: {} };

  clientControl: FormControl = new FormControl;
  clientFormOptions: Person[] = [];
  filteredOptions: Observable<Person[]>;

  viewStart: Moment;
  viewEnd: Moment;
  groupBy: 'cabin' | 'operator' = 'cabin';
  operators: Person[];
  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _calendarService: CalendarService,
    private _changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private _document: Document,
    private _fuseConfirmationService: FuseConfirmationService,
    private _formBuilder: FormBuilder,
    private _overlay: Overlay,
    private _snackBar: MatSnackBar,
    private _translate: GruulsAngularTranslateService,
    private _authService: GruulsAuthService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _viewContainerRef: ViewContainerRef,
    private _clientsService: ClientsService,
    private _storesService: GruulsAngularStoreService,
    private appointmentService: GruulsAngularAppointmentsService,
    private collaboratorsService: GruulsAngularCollaboratorService
  ) {
  }

  ngOnInit(): void {

    const appointmentTranslations = ['singular', 'notPlanned', 'note', 'reference', 'add', 'deleteWarning', 'deleteWarningMessage']
    appointmentTranslations.forEach((translation) => {
      this.translateStrings['appointment'][translation] = this._translate.translate('appointment.' + translation);
    });

    const calendarTranslations = ['confirmChangeTo', 'changedTo', 'changingTo', 'sizingTo', 'at', '3days', '1day'];
    calendarTranslations.forEach((translation) => {
      this.translateStrings['calendar'][translation] = this._translate.translate('calendar.' + translation);
    });

    const genericTranslations = ['warning', 'cancel', 'confirm', 'close', 'save', 'delete', 'somethingWentWrong', 'tryToReload'];
    genericTranslations.forEach((translation) => {
      this.translateStrings['generic'][translation] = this._translate.translate('generic.' + translation);
    });

    this.translateStrings['treatment']['singular'] = this._translate.translate('treatments.singular');

    this.eventForm = this._formBuilder.group({
      id: [''],
      calendarId: [''],
      // title: [''],
      description: [''],
      start: [null],
      end: [null],
      durationInMin: [null],
      // allDay: [true],
      recurringEventId: [null],
      recurrence: [null],
      range: [{}],
      extendedProps: this._formBuilder.group({
        clientId: [null],
        cabinId: [null],
        storeId: [null],
        status: [null],
        operatorId: [null],
      })
    });

    // Get calendars
    this._calendarService.calendars$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((calendars) => {

        // Store the calendars
        this.calendars = calendars;

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    // Get events
    this.isLoadingEmitter.emit(true);
    this._changeDetectorRef.markForCheck();

    // Store the settings
    this.settings = {
      dateFormat: 'll', // Aug 20, 2019
      timeFormat: '24', // 24-hour format
      startWeekOn: 1 // Monday
    };

    // Set the FullCalendar event time format based on the time format setting
    this.eventTimeFormat = {
      hour: this.settings.timeFormat === '12' ? 'numeric' : '2-digit',
      hour12: this.settings.timeFormat === '12',
      minute: '2-digit',
      meridiem: this.settings.timeFormat === '12' ? 'short' : false
    };
    const openingTime = '07:00:00';
    const closingTime = '21:00:00';
    const slotDuration = '00:15:00';

    // Build the view specific FullCalendar options
    this.views = {
      dayGridMonth: {
        eventLimit: 3,
        eventTimeFormat: this.eventTimeFormat,
        fixedWeekCount: false
      },
      timeGrid: {
        allDayText: '',
        columnHeaderFormat: {
          weekday: 'short',
          day: 'numeric',
          omitCommas: true
        },
        // columnHeaderHtml: (date): string => `<span class="fc-weekday">${moment(date).format('ddd')}</span>
        //                                          <span class="fc-date">${moment(date).format('D')}</span>`,
        slotDuration: slotDuration,
        slotLabelFormat: this.eventTimeFormat
      },
      timeGridWeek: {
        type: 'timeGrid',
        weekText: 'Week',
        nowIndicator: true,
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          hour12: false,
          meridiem: 'short'
        },
        allDaySlot: false,
        scrollTime: (new Date().getHours() + 3).toString() + ':00:00',
        minTime: openingTime,
        maxTime: closingTime,
        slotDuration: slotDuration,
        slotLabelInterval: '00:60:00',
      },
      timeGridThreeDays: {
        type: 'timeGrid',
        duration: { days: 3 },
        nowIndicator: true,
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          hour12: false,
          meridiem: 'short'
        },
        allDaySlot: false,
        scrollTime: (new Date().getHours() + 3).toString() + ':00:00',
        minTime: openingTime,
        maxTime: closingTime,
        slotDuration: slotDuration,
        slotLabelInterval: '00:60:00',
        buttonText: this.translateStrings['calendar']['3days']
      },
      timeGridOneDay: {
        type: 'timeGrid',
        duration: { days: 1 },
        nowIndicator: true,
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          hour12: false,
          meridiem: 'short'
        },
        allDaySlot: false,
        scrollTime: (new Date().getHours() + 3).toString() + ':00:00',
        minTime: openingTime,
        maxTime: closingTime,
        slotDuration: slotDuration,
        slotLabelInterval: '00:60:00',
        buttonText: this.translateStrings['calendar']['1day']
      },
      listYear: {
        allDayText: 'All day',
        eventTimeFormat: this.eventTimeFormat,
        listDayFormat: false,
        listDayAltFormat: false
      },
      resourceTimeGridDay: {
        type: 'resourceTimeGrid',
        duration: { days: 1 },
        nowIndicator: true,
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          hour12: false,
          meridiem: 'short'
        },
        allDaySlot: false,
        scrollTime: (new Date().getHours() + 3).toString() + ':00:00',
        minTime: openingTime,
        maxTime: closingTime,
        slotDuration: slotDuration,
        slotLabelInterval: '00:60:00',
        buttonText: this.translateStrings['calendar']['1day']
      },
      resourceTimeGridThreeDays: {
        type: 'resourceTimeGrid',
        duration: { days: 3 },
        nowIndicator: true,
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          hour12: false,
          meridiem: 'short'
        },
        allDaySlot: false,
        scrollTime: (new Date().getHours() + 3).toString() + ':00:00',
        minTime: openingTime,
        maxTime: closingTime,
        slotDuration: slotDuration,
        slotLabelInterval: '00:60:00',
        buttonText: this.translateStrings['calendar']['3days']
      }
    };

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {

        // Set the drawerMode and drawerOpened if the given breakpoint is active
        if (matchingAliases.includes('md')) {
          this.drawerMode = 'side';
          this.drawerOpened = true;
        }
        else {
          this.drawerMode = 'over';
          this.drawerOpened = false;
        }

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    // this._calendarService.getClients(this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId).subscribe();

    this._clientsService.clients$.pipe(
      takeUntil(this._unsubscribeAll),
      map(clients => Utils.sortByKeys(clients, ['firstName', 'lastName'])),
      tap((clients) => {
        if (clients && clients.length > 0) {
          this.clientFormOptions = clients;
          this.filteredOptions = this.clientControl.valueChanges.pipe(
            startWith(''),
            map(value => {
              const name = typeof value === 'string' ? value : this.displayFn(value);
              return this._filter(name as string).slice();
            }),
          );
          if (this.defaultPersonId && clients) {
            this.bindedClient = this.clientFormOptions.find((client: Person) => { return client.personId === this.defaultPersonId });
          }
          this._changeDetectorRef.markForCheck();
        }
      }),
    ).subscribe();

    this.collaboratorsService.accounts$.pipe(
      takeUntil(this._unsubscribeAll),
      map(collaborators => Utils.sortByKeys(collaborators, ['firstName', 'lastName'])),
      tap((collaborators) => {
        if (collaborators && collaborators.length > 0) {
          this.operators = collaborators;
          this._changeDetectorRef.markForCheck();
        }
      }),
    ).subscribe();

    this._calendarService.getCalendars().pipe(takeUntil(this._unsubscribeAll)).subscribe();
    this._clientsService.refreshClients().pipe(takeUntil(this._unsubscribeAll)).subscribe();
    this.collaboratorsService.refreshAccounts().pipe(takeUntil(this._unsubscribeAll)).subscribe();

  }

  getResources(): any[] {
    // if (this.resources)
    //     this._fullCalendarApi.addResource(this.resources);
    return this.resources ?? []; // TODO: understand why it doesnt' update
  }

  ngAfterViewInit(): void {
    // Get the full calendar API
    this._fullCalendarApi = this._fullCalendar.getApi();

    // Get the current view's title
    this.viewTitle = this._fullCalendarApi.view.title;
    this._fullCalendarApi.setOption('locale', itLocale);
    this._fullCalendarApi.setOption('selectable', true);
    this._fullCalendarApi.setOption('selectMirror', true);
    this._fullCalendarApi.setOption('unselectAuto', false);
    this._fullCalendarApi.setOption('editable', true);
    this._fullCalendarApi.addEventSource(this.eventsHandler.bind(this));

    this._storesService.activeStore$.pipe(
      takeUntil(this._unsubscribeAll),
      filter(store => store !== null),
      map(store => store.cabins),
      tap((cabins) => {
        if (cabins && cabins.length > 0) {
          this.cabins = cabins;
          this.header['right'] = rightMenuResource;
          this.resources = cabins.map(cabin => {
            return {
              id: cabin.cabinId,
              title: cabin.name,
              type: cabin.type
            }
          });
          this._fullCalendarApi.refetchResources();
          this._fullCalendarApi.rerenderResources();
          this._fullCalendarApi.setOption('header', this.header);

          this.changeView(viewMapping[this._fullCalendarApi.view.type]);
          this._changeDetectorRef.markForCheck();
        }
        if ((!cabins || cabins.length === 0) && this.resources && this.resources.length > 0) {
          this.cabins = [];
          this.header['right'] = rightMenu;
          this.resources = [];
          this._fullCalendarApi.refetchResources();
          this._fullCalendarApi.rerenderResources();
          this._fullCalendarApi.setOption('header', this.header);
          this.changeView(viewMapping[this._fullCalendarApi.view.type]);
          this._changeDetectorRef.markForCheck();
        }
      }),
      switchMap((store) => {
        return this._calendarService.events$
      }),
    ).subscribe({
      next: (events) => {
        const parsedEvents = events?.map(
          event => Object({
            ...event,
            backgroundColor: this.getColor(event).backgroundColor,
            borderColor: this.getColor(event).backgroundColor,
            textColor: this.getColor(event).textColor,
            // resourceId: (this.resources && this.resources.length > 0) ? this.resources[0].id : null   // TODO: get the correct resourceId
          })
        ) || [];
        this.events = parsedEvents; // used as a refernce for edit
        this._changeDetectorRef.markForCheck();
        this._fullCalendarApi.refetchEvents();
        this.isLoadingEmitter.emit(false);
      }
    });

    // Get the view's current start and end dates, add/subtract
    // 60 days to create a ~150 days period to fetch the data for

    // Get events
    //this._calendarService.getEvents(viewStart, viewEnd, true, this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId, this.activeStore?.storeId).subscribe();
    // this._appointmentService.getAppointments(viewStart, viewEnd).subscribe();
    this.viewStart = moment(this._fullCalendarApi.view.currentStart).subtract(7, 'days');
    this.viewEnd = moment(this._fullCalendarApi.view.currentEnd).add(14, 'days');

    this._storesService.getActiveStore().subscribe();
    this._calendarService.getEvents(this.viewStart, this.viewEnd, true, this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId).subscribe();
    this.appointmentService.getAppointments(this.viewStart.valueOf(), this.viewEnd.valueOf()).subscribe();

  }

  displayFn(client: Person): string {
    return client && client.firstName && client.lastName ? (client.firstName + ' ' + client.lastName) : '';
  }

  private _filter(name: string): Person[] {
    const filterValue = name.toLowerCase();
    if (filterValue)
      return this.clientFormOptions.filter(option => this.displayFn(option).toLowerCase().includes(filterValue));
    else
      return this.clientFormOptions;
  }

  openSnackBar(title: string): any {
    return this._snackBar.open(title, null, {
      duration: 2000,
      horizontalPosition: "left",
      verticalPosition: "bottom"
    });
  }

  eventsHandler(info, successCallback, failureCallback): void {
    successCallback(this.events);
  };

  handleEventClick(clickInfo) {
    const dialogRef = this._fuseConfirmationService.open({
      "title": clickInfo.event.title,
      "message": clickInfo.event.start.toLocaleDateString("it-IT", { weekday: "long" }) + " " + clickInfo.event.start.toLocaleTimeString("it-IT", { hour: '2-digit', minute: '2-digit' }),
      "icon": {
        "show": true,
        "name": "heroicons_outline:exclamation-triangle",
        "color": "primary"
      },
      "actions": {
        "confirm": {
          "show": true,
          "label": this.translateStrings['generic']['close'],
          "color": "primary"
        },
        "cancel": {
          "show": false,
          "label": this.translateStrings['generic']['cancel']
        }
      },
      "dismissible": true
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != "confirm")
        clickInfo.revert();
      else {
        // handle save
      }
    });
  }


  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(undefined);
    this._unsubscribeAll.complete();

    // Dispose the overlay
    if (this._eventPanelOverlayRef) {
      this._eventPanelOverlayRef.dispose();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle Drawer
   */
  toggleDrawer(): void {
    // Toggle the drawer
    this._drawer.toggle();
  }

  /**
   * Change the event panel mode between view and edit
   * mode while setting the event edit mode
   *
   * @param panelMode
   * @param eventEditMode
   */
  changeEventPanelMode(panelMode: CalendarEventPanelMode, eventEditMode: CalendarEventEditMode = 'single'): void {
    // Set the panel mode
    this.panelMode = panelMode;

    // Set the event edit mode
    this.eventEditMode = eventEditMode;

    // Update the panel position
    setTimeout(() => {
      this._eventPanelOverlayRef.updatePosition();
    }, 10);
  }

  /**
   * Get calendar by id
   *
   * @param id
   */
  getCalendar(id): Calendar {
    if (!id || !this.calendars) {
      return;
    }
    return this.calendars.find(calendar => calendar.id === id);
  }

  /**
   * Get the color for a particular event
   *
   * @param event
   */
  getColor(event: CalendarEvent): { backgroundColor, textColor } {
    if (this.defaultPersonId && event.extendedProps && ('clientId' in event.extendedProps) && event.extendedProps.clientId !== this.defaultPersonId)
      // return default color if a personId is available, but is not related to the specific event
      return {
        backgroundColor: this.defaultEventColor,
        textColor: '#FEFEFE'
      };
    else {
      // return calendar color
      // const cal = this.calendars.find(calendar => calendar.id === event.calendarId);
      // return (cal && cal?.color) ? cal.color : this.defaultEventColor;
      return AppointmentStatus.getStatusColor(event.extendedProps.status);
    }
  }

  setEventCabin(cabinId: string): void {
    this.event.extendedProps.cabinId = cabinId;
    this.eventForm.get('extendedProps.cabinId').setValue(cabinId);
    if (this.groupBy == 'cabin')
      this.event.resourceId = cabinId;
  }

  setEventOperator(operatorId: string): void {
    this.event.extendedProps.operatorId = operatorId;
    this.eventForm.get('extendedProps.operatorId').setValue(operatorId);
    if (this.groupBy == 'operator')
      this.event.resourceId = operatorId;
  }

  /**
   * Change the calendar view
   *
   * @param view
   */
  changeView(view: AllowedView | AllowedViewResource): void {
    // Store the view
    // this.view = view;

    // If the FullCalendar API is available...
    if (this._fullCalendarApi) {
      // Set the view
      this._fullCalendarApi.changeView(view);
      this.activeView = view;

      // Update the view title
      this.viewTitle = this._fullCalendarApi.view.title;
    }
  }

  /**
   * Moves the calendar one stop back
   */
  previous(): void {
    // Go to previous stop
    this._fullCalendarApi.prev();
    // Update the view title
    this.viewTitle = this._fullCalendarApi.view.title;
    // Get the view's current start date
    const start = moment(this._fullCalendarApi.view.currentStart);
    // Prefetch past events
    this._calendarService.prefetchPastEvents(start, this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId).subscribe();
  }

  /**
   * Moves the calendar to the current date
   */
  today(): void {
    // Go to today
    this._fullCalendarApi.today();
    // Update the view title
    this.viewTitle = this._fullCalendarApi.view.title;
    this._storesService.setActiveStore(this._storesService.activeStore);
  }

  /**
   * Moves the calendar one stop forward
   */
  next(): void {
    // Go to next stop
    this._fullCalendarApi.next();
    // Update the view title
    this.viewTitle = this._fullCalendarApi.view.title;
    // Get the view's current end date
    const end = moment(this._fullCalendarApi.view.currentEnd);
    // Prefetch future events
    this._calendarService.prefetchFutureEvents(end, this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId).subscribe();
  }

  getFirstAvailableSlot(dayDate: Date, startingHour: number = 7, slotDurationInMin: number = 60): Date {
    let firstFreeSlotOfTheDay: Date = new Date(dayDate.setHours(startingHour, 0, 0, 0));
    if (this.events) {
      const eventsForTheDay = this.events.filter((event) => { return moment(event.start).isSame(dayDate, 'day') });
      const sortedEvents = eventsForTheDay.sort((a, b) => {
        return a.end.getTime() - b.end.getTime();
      });
      sortedEvents.forEach((event) => {
        if ((firstFreeSlotOfTheDay.getTime() + (slotDurationInMin - 1) * 1000 * 60) > event.start.getTime()) {
          firstFreeSlotOfTheDay = event.end;
        }
      });
    }
    return firstFreeSlotOfTheDay;
  }

  createEvent(calendarEvent: CalendarEvent, calendarEventElement): void {
    this.event = calendarEvent;
    calendarEvent.calendarId = this.calendars ? this.calendars[0].id : '';
    if (this.operators && this.operators.length > 0)
      this.setEventOperator(this.operators[0].personId);

    // Reset the form and fill the event
    this.eventForm.reset();
    this.clientControl.reset();

    this.eventForm.patchValue(calendarEvent);
    this.eventForm.get('start').setValue(Utils.formatDate(calendarEvent.start), { emitEvent: false });
    this.eventForm.get('end').setValue(Utils.formatDate(calendarEvent.end), { emitEvent: false });

    if (this.defaultPersonId) {
      this.clientControl.setValue(this.clientFormOptions.find((client) => client.personId === calendarEvent.extendedProps.clientId));
      this.clientControl.disable();
      this._changeDetectorRef.markForCheck();
    }

    // Open the event panel
    this._openEventPanel(calendarEventElement);

    // Change the event panel mode
    this.changeEventPanelMode('add');

  }
  /**
   * On date click
   *
   * @param dateClickInfo
   */
  onDateClick(dateClickInfo): void {
    const defaultDurationInMin = 30;
    let assignedSlotStart: Date = dateClickInfo.date;
    let assignedSlotEnd: Date = new Date(assignedSlotStart.getTime() + defaultDurationInMin * 60 * 1000);;

    // Prepare the event
    let event: CalendarEvent = {
      id: null,
      calendarId: null,
      recurringEventId: null,
      resourceId: dateClickInfo?.resource?.id,
      isFirstInstance: false,
      title: '',
      description: '',
      start: assignedSlotStart,
      end: assignedSlotEnd,
      durationInMin: defaultDurationInMin,
      allDay: false,
      recurrence: null,
      extendedProps: {
        clientId: this.clientFormOptions?.find((client) => client.personId === this.defaultPersonId)?.personId,
        cabinId: (dateClickInfo?.resource?.id) ? this.cabins.find(cabin => cabin.cabinId === dateClickInfo.resource.id)?.cabinId : this.cabins[0]?.cabinId,
        storeId: this._storesService.activeStore.storeId
      }
    };
    event = this.updateEventResource(event, dateClickInfo?.resource?.id);

    this.createEvent(event, dateClickInfo.view.el);

  }

  onDateSelect(selectInfo): void {
    let assignedSlotStart: Date = selectInfo.start;
    let assignedSlotEnd: Date = selectInfo.end;

    const durationInMin = (assignedSlotEnd.getTime() - assignedSlotStart.getTime()) / (60 * 1000);

    // Prepare the event
    let event: CalendarEvent = {
      id: null,
      calendarId: null,
      resourceId: selectInfo?.resource?.id,
      recurringEventId: null,
      isFirstInstance: false,
      title: '',
      description: '',
      start: assignedSlotStart,
      end: assignedSlotEnd,
      durationInMin: durationInMin,
      allDay: false,
      recurrence: null,
      extendedProps: {
        clientId: this.clientFormOptions?.find((client) => client.personId === this.defaultPersonId)?.personId,
        cabinId: (selectInfo?.resource?.id) ? this.cabins.find(cabin => cabin.cabinId === selectInfo.resource.id)?.cabinId : null,
        storeId: this._storesService.activeStore.storeId
      }
    };
    event = this.updateEventResource(event, selectInfo?.resource?.id);

    this.createEvent(event, selectInfo.view.el);

  }

  changeEvent(calendarEvent: CalendarEvent, type: 'resize' | 'drop'): void {
    if (this._snackBar)
      this._snackBar.dismiss();

    let event: CalendarEvent = {
      id: calendarEvent.id,
      calendarId: calendarEvent.calendarId,
      isFirstInstance: calendarEvent.isFirstInstance,
      title: calendarEvent.title,
      description: calendarEvent.description,
      start: calendarEvent.start,
      end: calendarEvent.end,
      durationInMin: (new Date(calendarEvent.end).getTime() - new Date(calendarEvent.start).getTime()) / (60 * 1000),
      resourceId: calendarEvent.resourceId,
      extendedProps: {
        clientId: calendarEvent.extendedProps.clientId,
        cabinId: this.groupBy == 'cabin' ? calendarEvent.resourceId : calendarEvent.extendedProps.cabinId,
        operatorId: this.groupBy == 'operator' ? calendarEvent.resourceId : calendarEvent.extendedProps.operatorId,
        storeId: calendarEvent.extendedProps.storeId,
        status: calendarEvent.extendedProps.status,
      }
    }
    if (typeof (calendarEvent['getResources']) === 'function' && (calendarEvent.getResources().length > 0) && !calendarEvent.resourceId) {
      event = this.updateEventResource(event, calendarEvent.getResources()[0]?.id);
    }
    let snackBarText: string;

    if (type == 'resize') {
      snackBarText = this.translateStrings['calendar']['sizingTo'] + event.durationInMin.toString() + " min.";
    } else {
      snackBarText = this.translateStrings['calendar']['changingTo'] + event.start.toLocaleDateString("it-IT", { weekday: "long" }) + " " + this.translateStrings['calendar']['at'] + " " + calendarEvent.start.toLocaleTimeString("it-IT", { hour: '2-digit', minute: '2-digit' });
    }

    this.openSnackBar(snackBarText);

    let saveObservable: Observable<any> = of(null);
    saveObservable.subscribe(() => {
      this._calendarService.updateEvent(event).subscribe({
        next: (v) => {
          this.eventForm.enable();
          this._fullCalendarApi.refetchEvents();
          this.isSpecificLoading['saveEvent'] = false;
          // Close the event panel
          this.closeEventPanel();
        },
        error: (err) => {
          console.log('Error while updating: ', err);
          this.eventForm.enable();
          this.isSpecificLoading['saveEvent'] = false;
        },
      });

    });
  }

  onEventDrop(eventDropInfo): void {
    let calendarEvent: CalendarEvent = eventDropInfo.event;
    if (eventDropInfo.newResource && eventDropInfo.newResource.id) {
      if (this.groupBy == 'cabin')
        calendarEvent.resourceId = eventDropInfo.newResource.id;
    }
    this.changeEvent(calendarEvent, 'drop');

  }

  onEventResize(eventResizeInfo): void {
    const calendarEvent: CalendarEvent = eventResizeInfo.event;
    this.changeEvent(calendarEvent, 'resize');
  }

  /**
   * On event click
   *
   * @param eventClickInfo
   */
  onEventClick(eventClickInfo): void {
    const calendarEvent: CalendarEvent = eventClickInfo.event;

    // Find the event with the clicked event's id
    const event: CalendarEvent = cloneDeep(this.events.find(item => item.id === calendarEvent.id));

    // Set the event
    this.event = event;

    let end;
    if (event.recurringEventId) {
      end = moment(event.start).add(event.durationInMin, 'minutes').toISOString();
    } else {
      end = event.end;
    }

    // Set the range on the event
    // event.range = {
    //   start: event.start,
    //   end
    // };

    // Reset the form and fill the event
    this.eventForm.reset();
    this.eventForm.patchValue(event);

    // Open the event panel
    this._openEventPanel(eventClickInfo.el);

    this.clientControl.setValue(this.clientFormOptions.find((client) => { return client.personId === event.extendedProps.clientId }));
    this._changeDetectorRef.markForCheck();

  }

  /**
   * On event render
   *
   * @param calendarEvent
   */
  onEventRender(calendarEvent): void {
    if (!this.calendars)
      return;
    // Get event's calendar
    const calendar = this.calendars.find(item => item.id === calendarEvent.event.extendedProps.calendarId);

    // Return if the calendar doesn't exist...
    if (!calendar)
      return;

    calendarEvent.el.classList.add(calendar.color);

    // Set the event's title to '(No title)' if event title is not available
    if (!calendarEvent.event.title) {
      calendarEvent.el.querySelector('.fc-title').innerText = '(No title)';
    }

    // Set the event's visibility
    calendarEvent.el.style.display = calendar.visible ? 'flex' : 'none';
  }

  /**
   * On calendar updated
   *
   * @param calendar
   */
  onCalendarUpdated(calendar): void {
    // Re-render the events
    this._fullCalendarApi.rerenderEvents();
  }

  changeStartDate(event): void {
    this.eventForm.controls['start'].setValue(event);
    this.eventForm.controls['end'].setValue(event);
    // this.eventForm.controls['duration'].setValue((new Date(this.eventForm.controls.end.value).getTime() - new Date(this.eventForm.controls.start.value).getTime()) / (60*1000));
  }

  /**
   * Add event
   */
  addEvent(): void {
    if (this.eventForm.invalid)
      return;

    this.eventForm.disable();
    this.isSpecificLoading['saveEvent'] = true;

    // Get the clone of the event form value
    let newEvent: CalendarEvent = cloneDeep(this.eventForm.value);
    newEvent.start = new Date(newEvent.start);
    newEvent.end = new Date(newEvent.end);
    newEvent.durationInMin = (new Date(newEvent.end).getTime() - new Date(newEvent.start).getTime()) / (60 * 1000);

    // Set Client
    if (this.bindedClient || this.clientControl.value) {
      newEvent.extendedProps.clientId = this.bindedClient ? this.bindedClient.personId : this.clientControl.value.personId;
      let newEventTitle: string;
      if (this.bindedClient) {
        newEventTitle = this.bindedClient.firstName + ' ' + this.bindedClient.lastName;
      } else {
        if (typeof (this.clientControl.value) === 'string') {
          newEventTitle = this.clientControl.value;
        } else {
          newEventTitle = this.clientControl.value.firstName + ' ' + this.clientControl.value.lastName;
        }
      }
      newEvent.title = newEventTitle;
    }

    // If the event is a recurring event...
    // if (newEvent.recurrence) {
    //   newEvent.duration = moment(newEvent.range.end).diff(moment(newEvent.range.start), 'minutes');
    // }

    // Modify the event before sending it to the server
    // newEvent = omit(newEvent, ['range', 'recurringEventId']);

    // Add the event
    this._calendarService.addEvent(newEvent).subscribe({
      next: (v) => {

        // this._fullCalendarApi.refetchEvents();
        this.eventForm.enable();
        this.isSpecificLoading['saveEvent'] = false;

        // Reload events
        // TODO: is this necessary? I THINK NOT
        //this._calendarService.reloadEvents(this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId, this.activeStore.storeId).subscribe();

        this._fullCalendarApi.refetchEvents();

        // Close the event panel
        this.closeEventPanel();
      },
      error: (err) => {
        console.log('Error while updating: ', err);
        this.eventForm.enable();
        this.isSpecificLoading['saveEvent'] = false;
      },
    });
  }

  updateEventResource(event: any, resourceId: string): any {
    if (resourceId)
      event.resourceId = resourceId;

    this._fullCalendarApi.refetchEvents();

    // TODO: check what resource is and update the event
    return event;
  }

  /**
   * Update the event
   */
  updateEvent(): void {
    if (this.eventForm.invalid)
      return;

    if (this.bindedClient && this.bindedClient.personId !== this.event.extendedProps.clientId)
      return;

    this.isSpecificLoading['saveEvent'] = true;
    this.eventForm.disable();

    // Get the clone of the event form value
    let event: CalendarEvent = cloneDeep(this.eventForm.value);
    event.start = new Date(event.start);
    if (event.durationInMin && event.durationInMin > 0) {
      event.end = new Date(new Date(event.start).getTime() + event.durationInMin * 60 * 1000);
      event.durationInMin = (new Date(event.end).getTime() - new Date(event.start).getTime()) / (60 * 1000);
    }
    if (this.clientControl.value) {
      event.extendedProps.clientId = this.clientControl.value.personId;
      event.title = this.clientControl.value.firstName + ' ' + this.clientControl.value.lastName;
    }

    const {
      // range,
      ...eventWithoutRange
    } = event;

    // Get the original event
    const originalEvent = this.events.find(item => item.id === event.id);

    // Return if there are no changes made to the event
    // if (isEqual(eventWithoutRange, originalEvent)) {
    //   this.closeEventPanel();
    //   return;
    // }

    // If the event is a non-recurring event...
    if (!event.recurrence && !event.recurringEventId) {
      // Update the event on the server
      this._calendarService.updateEvent(event).subscribe({
        next: (v) => {
          this.eventForm.enable();
          this._fullCalendarApi.refetchEvents();
          this.isSpecificLoading['saveEvent'] = false;
          // Close the event panel
          this.closeEventPanel();
        },
        error: (err) => {
          console.log('Error while updating: ', err);
          this.eventForm.enable();
          this.isSpecificLoading['saveEvent'] = false;
        }
      });

      this._fullCalendarApi.refetchEvents();

      // Return
      return;
    }

  }

  /**
   * Delete the given event
   *
   * @param event
   * @param mode
   */
  deleteEvent(event, mode: CalendarEventEditMode = 'single'): void {
    // If the event is a recurring event...
    // Open the confirmation dialog
    const confirmation = this._fuseConfirmationService.open({
      title: this.translateStrings.appointment.deleteWarning,
      message: this.translateStrings.appointment.deleteWarningMessage,
      actions: {
        confirm: {
          label: this.translateStrings.generic.delete
        }
      }
    });

    // Subscribe to the confirmation dialog closed action
    confirmation.afterClosed().subscribe((result) => {

      // If the confirm button pressed...
      if (result === 'confirmed') {

        // Update the event on the server
        this._calendarService.deleteEvent(event.id).subscribe((isDeleted) => {
          // Close the event panel
          this._fullCalendarApi.refetchEvents();
          this.closeEventPanel();
        });
      }
    });
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create the event panel overlay
   *
   * @private
   */
  private _createEventPanelOverlay(positionStrategy): void {
    // Create the overlay
    this._eventPanelOverlayRef = this._overlay.create({
      panelClass: ['calendar-event-panel'],
      backdropClass: '',
      hasBackdrop: true,
      scrollStrategy: this._overlay.scrollStrategies.reposition(),
      positionStrategy
    });

    // Detach the overlay from the portal on backdrop click
    this._eventPanelOverlayRef.backdropClick().subscribe(() => {
      this.closeEventPanel();
    });
  }

  /**
   * Open the event panel
   *
   * @private
   */
  private _openEventPanel(calendarEventElement): void {
    const positionStrategy = this._overlay.position().flexibleConnectedTo(calendarEventElement).withFlexibleDimensions(false).withPositions([
      {
        originX: 'end',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
        offsetX: 8
      },
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'top',
        offsetX: 40
      },
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'bottom',
        offsetX: -8
      },
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'bottom',
        offsetX: 8
      }
    ]);

    // Create the overlay if it doesn't exist
    if (!this._eventPanelOverlayRef) {
      this._createEventPanelOverlay(positionStrategy);
    }
    // Otherwise, just update the position
    else {
      this._eventPanelOverlayRef.updatePositionStrategy(positionStrategy);
    }

    // Attach the portal to the overlay
    this._eventPanelOverlayRef.attach(new TemplatePortal(this._eventPanel, this._viewContainerRef));

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Close the event panel
   *
   * @public
   */
  public closeEventPanel(): void {
    // Detach the overlay from the portal
    if (this._eventPanelOverlayRef)
      this._eventPanelOverlayRef.detach();
    // Deselect selection
    this._fullCalendarApi.unselect();

    // Reset the panel and event edit modes
    this.panelMode = 'view';
    this.eventEditMode = 'single';

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Update the recurrence rule based on the event if needed
   *
   * @private
   */
  private _updateRecurrenceRule(): void {
    // Get the event
    const event = this.eventForm.value;

    // Return if this is a non-recurring event
    if (!event.recurrence) {
      return;
    }

    // Parse the recurrence rule
    const parsedRules = {};
    event.recurrence.split(';').forEach((rule) => {

      // Split the rule
      const parsedRule = rule.split('=');

      // Add the rule to the parsed rules
      parsedRules[parsedRule[0]] = parsedRule[1];
    });

    // If there is a BYDAY rule, split that as well
    if (parsedRules['BYDAY']) {
      parsedRules['BYDAY'] = parsedRules['BYDAY'].split(',');
    }

    // Do not update the recurrence rule if ...
    // ... the frequency is DAILY,
    // ... the frequency is WEEKLY and BYDAY has multiple values,
    // ... the frequency is MONTHLY and there isn't a BYDAY rule,
    // ... the frequency is YEARLY,
    if (parsedRules['FREQ'] === 'DAILY' ||
      (parsedRules['FREQ'] === 'WEEKLY' && parsedRules['BYDAY'].length > 1) ||
      (parsedRules['FREQ'] === 'MONTHLY' && !parsedRules['BYDAY']) ||
      parsedRules['FREQ'] === 'YEARLY') {
      return;
    }

    // If the frequency is WEEKLY, update the BYDAY value with the new one
    if (parsedRules['FREQ'] === 'WEEKLY') {
      parsedRules['BYDAY'] = [moment(event.start).format('dd').toUpperCase()];
    }

    // If the frequency is MONTHLY, update the BYDAY value with the new one
    if (parsedRules['FREQ'] === 'MONTHLY') {
      // Calculate the weekday
      const weekday = moment(event.start).format('dd').toUpperCase();

      // Calculate the nthWeekday
      let nthWeekdayNo = 1;
      while (moment(event.start).isSame(moment(event.start).subtract(nthWeekdayNo, 'week'), 'month')) {
        nthWeekdayNo++;
      }

      // Set the BYDAY
      parsedRules['BYDAY'] = [nthWeekdayNo + weekday];
    }

    // Generate the rule string from the parsed rules
    const rules = [];
    Object.keys(parsedRules).forEach((key) => {
      rules.push(key + '=' + (Array.isArray(parsedRules[key]) ? parsedRules[key].join(',') : parsedRules[key]));
    });
    const rrule = rules.join(';');

    // Update the recurrence rule
    this.eventForm.get('recurrence').setValue(rrule);
  }

  /**
   * Update the end value based on the recurrence and duration
   *
   * @private
   */
  private _updateEndValue(): void {
    // Get the event recurrence
    const recurrence = this.eventForm.get('recurrence').value;

    // Return if this is a non-recurring event
    if (!recurrence) {
      return;
    }

    // Parse the recurrence rule
    const parsedRules = {};
    recurrence.split(';').forEach((rule) => {

      // Split the rule
      const parsedRule = rule.split('=');

      // Add the rule to the parsed rules
      parsedRules[parsedRule[0]] = parsedRule[1];
    });

    // If there is an UNTIL rule...
    if (parsedRules['UNTIL']) {
      // Use that to set the end date
      this.eventForm.get('end').setValue(parsedRules['UNTIL']);

      // Return
      return;
    }

    // If there is a COUNT rule...
    if (parsedRules['COUNT']) {
      // Generate the RRule string
      const rrule = 'DTSTART=' + moment(this.eventForm.get('start').value).utc().format('YYYYMMDD[T]HHmmss[Z]') + '\nRRULE:' + recurrence;

      // Use RRule string to generate dates
      const dates = RRule.fromString(rrule).all();

      // Get the last date from dates array and set that as the end date
      this.eventForm.get('end').setValue(moment(dates[dates.length - 1]).toISOString());

      // Return
      return;
    }

    // If there are no UNTIL or COUNT, set the end date to a fixed value
    this.eventForm.get('end').setValue(moment().year(9999).endOf('year').toISOString());
  }


}
