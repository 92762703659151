import { duration } from "moment";
import { CalendarEvent } from "../modules/myCalendar/mycalendar.types";
import { Appointment } from "../modules/clients/client.types";
import { Store } from "./dataTypes";
import { MexalInvoice } from "../types/mexal-invoice";
import { Item } from "../modules/file-manager/file-manager.types";

export class BeautyciansUtils {

    /**
     * Converts an Appointment object to a CalendarEvent object.
     *
     * @param {Appointment} appointment - The appointment to convert.
     * @returns {CalendarEvent} The converted calendar event for being used in FullCaledar.
     */
    public static appointmentToEvent(appointment: Appointment, resourceGroupBy: 'cabin' | 'operator' = 'cabin'): CalendarEvent {
        const defaultDurationInMin = 30;
        return {
            id: appointment.appointmentId,
            calendarId: appointment.treatmentId,
            resourceId: resourceGroupBy == 'cabin' ? appointment.cabin?.cabinId : appointment.operator?.personId,
            recurringEventId: null,
            isFirstInstance: null,
            title: appointment.name,
            description: appointment.description,
            start: new Date(appointment.date),
            end: new Date(appointment.date + (appointment.durationMin ?? defaultDurationInMin) * 60 * 1000),
            durationInMin: appointment.durationMin ?? defaultDurationInMin,
            allDay: false,
            recurrence: '',
            extendedProps: {
                status: appointment.status,
                treatmentId: appointment.treatmentId,
                clientId: appointment.client?.personId,
                operatorId: appointment.operator?.personId,
                storeId: appointment.storeId?.storeId,
                cabinId: appointment.cabin?.cabinId,
            },
        }
    }

    public static eventToAppointment(event: CalendarEvent): Appointment {
        let appointment: Appointment = {};

        if (event.id) appointment['appointmentId'] = event.id;
        if (event.title) appointment['name'] = event.title;
        if (event.description) appointment['description'] = event.description;
        if (event.calendarId) appointment['treatmentId'] = event.calendarId;
        if (event.start) appointment['date'] = event.start.getTime();
        if (event.durationInMin) appointment['durationMin'] = event.durationInMin;
        if (event.resourceId) appointment['cabinId'] = event.resourceId;
        // durationMin: (event.end && event.start) ? Math.round((event.end.getTime() - event.start.getTime()) / (1000*60)) : 30,

        if (event.extendedProps) {
            if (event.extendedProps.clientId) appointment['client'] = { personId: event.extendedProps.clientId }
            if (event.extendedProps.operatorId) appointment['operator'] = { personId: event.extendedProps.operatorId }
            if (event.extendedProps.storeId) appointment['storeId'] = { storeId: event.extendedProps.storeId }
            if (event.extendedProps.cabinId) appointment['cabin'] = { cabinId: event.extendedProps.cabinId }
            if (event.extendedProps.status) appointment['status'] = event.extendedProps.status;
        }

       return appointment;
    }

    public static legalAddressFromClientInfo(mexalClientInfo: any): Store {
        let clientName: string;
        if (mexalClientInfo.ragione_sociale) {
            clientName = mexalClientInfo.ragione_sociale;
        } else {
            clientName = mexalClientInfo.nome + (mexalClientInfo.nome ? " " : "") + mexalClientInfo.cognome;
        }
        if (clientName && mexalClientInfo.indirizzo && mexalClientInfo.localita && mexalClientInfo.cap && mexalClientInfo.provincia) {
            const legalAddress = {
                name: clientName,
                address: mexalClientInfo.indirizzo,
                city: mexalClientInfo.localita,
                zip: mexalClientInfo.cap,
                province: mexalClientInfo.provincia
            }
            return legalAddress;
        } else {
            return null;
        }
    }

    public static mexalInvoicesToFileManagerObject(invoices: any, fromDate: Date ) {
        const invoicesFiles = invoices.map((invoice) => {
            const date = invoice.data_documento;
            // const folderId = date.substring(0, date.length - 2);
            const folderId = null;
            const type = "PDF";
            const id = invoice.numero.toString();
            const name = "Fattura " + id;
            const contents = "del " + new Date(date.substring(0, 4) + '-' + date.substring(4, 6) + '-' + date.substring(6, 8)).toLocaleDateString('it-IT', { day: 'numeric', month: 'long' });
            const createdBy = "Beautycians";
            const createdAt = new Date(date.substring(0, 4) + '-' + date.substring(4, 6) + '-' + date.substring(6, 8)).toLocaleDateString('it-IT');
            const original = invoice;
            return { ...invoice, id, name, contents, folderId, type, createdBy, createdAt, original };
        });
        invoicesFiles.sort((a, b) => b.numero - a.numero);

        const folders = [];
        const currentDate = new Date();
        let tempDate = new Date(fromDate);
        while (tempDate <= currentDate) {
            const month = tempDate.toLocaleString('default', { month: 'short' });
            const year = tempDate.getFullYear();
            const id = `${year}${(tempDate.getMonth() + 1).toString().padStart(2, '0')}`;
            folders.push({ name: `${month} ${year}`, id });
            tempDate.setMonth(tempDate.getMonth() + 1);
        }

        return {
            folders,
            files: invoicesFiles,
            path: []
        }
    }
}
