import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { map, tap, catchError, Observable, combineLatest, Subject, of, throwError, BehaviorSubject } from 'rxjs';
import { forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GruulsAngularHttpProxyService } from '../../../../../@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { GruulsAngularTranslateService } from '../../../../../@gruuls-fe/services/gruuls-angular-translate.service';
import { MatTabGroup } from '@angular/material/tabs';
import { GruulsAuthService } from '@gruuls-fe/services/gruuls-auth.service';
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { MedicalHistory } from '../client.types';
import { GruulsAngularAppointmentsService } from '@gruuls-fe/services/gruuls-angular-appointments-service';
import _ from 'lodash';
import { Person } from '@gruuls-core/types/person.type';
import { Store } from 'app/beautycians/utils/dataTypes';
import { GruulsAngularStoreService } from '@gruuls-fe/services/gruuls-angular-stores-service';

@Component({
    selector: 'client-details',
    templateUrl: './client-details.component.html',
    styleUrls: ['./client-details.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientDetailsComponent implements OnInit, OnDestroy {

    em: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    experimentalFeatures: boolean = true;
    isLoading: boolean = true;
    isChildLoading: any = {};
    editMode: boolean = false;
    isEditing: boolean = false;
    client: Person;
    contacts: Person[];
    events: any[] = [];
    activeStore: Store;

    treatments: any[] = [];
    surveyList: any[] = [];
    beautyPathList: any[] = [];
    menuItems: any[] = [{
        name: 'Anamnesi',
    },
    {
        name: 'Percorsi',
    },
    {
        name: 'Calendario',
    }];

    clientDetailsTabSelectedIndex: number = 0;
    selectedAnamnesi: any;
    autosaveAnamnesiId: string;
    translateStrings: any = { generic: {}, store: {}, appointment: {}, beautyPath: {} };
    personId: string;
    lastAnamnesi: MedicalHistory;
    private _tagsPanelOverlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    @ViewChild('anamnesiTab') anamnesiTab: MatTabGroup;
    @ViewChild('clientDetailsTab') clientDetailsTab: MatTabGroup;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _apiCaller: ApiCaller,
        private _authService: GruulsAuthService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _httpClient: GruulsAngularHttpProxyService,
        private _translate: GruulsAngularTranslateService,
        private _appointmentsService: GruulsAngularAppointmentsService,
        private storeService: GruulsAngularStoreService
    ) {
    }

    @HostBinding('class') get classList(): any {
        return {
            'w-full': true
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        const genericTranslations = ['send', 'close', 'save', 'edit', 'download', 'view', 'viewReport', 'clone', 'reset'];
        genericTranslations.forEach((translation) => {
            this.translateStrings['generic'][translation] = this._translate.translate('generic.' + translation);
        });
        const storeTranslations = ['create'];
        storeTranslations.forEach((translation) => {
            this.translateStrings['store'][translation] = this._translate.translate('stores.' + translation);
        });
        const appointementsTranslations = ['noStore'];
        appointementsTranslations.forEach((translation) => {
            this.translateStrings['appointment'][translation] = this._translate.translate('appointment.' + translation);
        });
        const beautyPathsTranslations = ['noStore'];
        beautyPathsTranslations.forEach((translation) => {
            this.translateStrings['beautyPath'][translation] = this._translate.translate('beautyPath.' + translation);
        });


        // Get Client details
        this._activatedRoute.params.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe((params) => {
            this.personId = params.id;
            forkJoin([
                // emptySurveyResult: this.compileEmptySurvey(),
                // anamnesiResult: this.getAllAnamnesi(this.personId),
                this._apiCaller.getPerson(this.personId).pipe(
                    tap((personResponse) => {
                        this.client = _.cloneDeep(personResponse);
                        this._changeDetectorRef.markForCheck();
                    })
                ),
                this._apiCaller.getTreatments().pipe(
                    map((treatmentsResponse) => {
                        return treatmentsResponse.map((treatment) => {
                            return {
                                ...treatment,
                                name: this._translate.translate('treatments.' + treatment.code)
                            }
                        });
                    }),
                    tap((treatmentsResponse) => {
                        this.treatments = treatmentsResponse;
                        this._changeDetectorRef.markForCheck();
                    }),
                )
                // beautyPathResult: this.apiCaller.getBeautyPaths(this.personId).pipe(
                //     map((res) => {
                //         this.beautyPathList = res;
                //     })
                // )
            ]).subscribe({
                next: (res) => {
                    this.isLoading = false;
                    this._changeDetectorRef.markForCheck();
                },
                error: (err) => {
                    this.isLoading = false;
                    this._changeDetectorRef.markForCheck();
                    console.error(err);
                }
            });
        });

        this.storeService.activeStore$.pipe(
            takeUntil(this._unsubscribeAll),
            tap((store) => {
                this.activeStore = store;
                this.isLoading = false;
                this._changeDetectorRef.markForCheck();
            })
        ).subscribe();

        /// ALTRO MODO, tutto in parallelo

        // // Get user's anamnesi
        // this.getAllAnamnesi(this.personId).subscribe();
        //
        // // Get user's clinical paths
        // this.getAllBeautyPaths(this.personId).subscribe();

    }

    setReferenceAnamnesi($event): void {
        this.lastAnamnesi = $event;
        this._changeDetectorRef.markForCheck();
    }

    childLoading(reference: string, $event: boolean): void {
        this.isChildLoading[reference] = $event;
        this._changeDetectorRef.markForCheck();
    }

    isGlobalLoading(): boolean {
        return !Object.values(this.isChildLoading).concat(this.isLoading).every((loading) => !loading);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();

        // Dispose the overlays if they are still on the DOM
        if (this._tagsPanelOverlayRef) {
            this._tagsPanelOverlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    setClientDetailsTab(tabId): void {
        this.clientDetailsTab.selectedIndex = tabId;
        this.clientDetailsTabSelectedIndex = tabId;
        window.dispatchEvent(new Event('resize')); // For FullCalendar to render properly
        this._changeDetectorRef.markForCheck();
    }


    // /**
    //  * Upload avatar
    //  *
    //  * @param fileList
    //  */
    //     uploadAvatar(fileList: FileList): void
    //     {
    //         // Return if canceled
    //         if ( !fileList.length )
    //         {
    //             return;
    //         }

    //         const allowedTypes = ['image/jpeg', 'image/png'];
    //         const file = fileList[0];

    //         // Return if the file is not allowed
    //         if ( !allowedTypes.includes(file.type) )
    //         {
    //             return;
    //         }

    //         // Upload the avatar
    //         //this._contactsService.uploadAvatar(this.contact.id, file).subscribe();
    //     }

    //     /**
    //      * Remove the avatar
    //      */
    //     removeAvatar(): void
    //     {
    //         // Get the form control for 'avatar'
    //         const avatarFormControl = this.contactForm.get('avatar');

    //         // Set the avatar as null
    //         avatarFormControl.setValue(null);

    //         // Set the file input value as null
    //         this._avatarFileInput.nativeElement.value = null;

    //         // Update the contact
    //         this.contact.avatar = null;
    //     }

}