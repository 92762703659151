<div class=" inset-0 flex flex-col min-w-0 overflow-hidden">
    <mat-drawer-container class="flex-auto h-full bg-card dark:bg-transparent" (backdropClick)="onBackdropClicked()">

        <!-- Drawer -->
        <mat-drawer class="w-full md:w-160 dark:bg-gray-900" [mode]="drawerMode" [opened]="false" [position]="'end'"
            [disableClose]="true" #matDrawer>
            <router-outlet></router-outlet>
        </mat-drawer>

        <mat-drawer-content class="flex flex-col">

            <!-- Main -->
            <div class="flex-auto">
                <!-- Header -->
                <div class="flex flex-col sm:flex-row md:flex-col flex-auto justify-between py-8 px-6 md:px-8 border-b">

                    <!-- Title -->
                    <div>
                        <div class="text-4xl font-extrabold tracking-tight leading-none">
                            {{translateStrings.owner.plural}}</div>
                        <div class="ml-0.5 font-medium text-secondary">
                            <ng-container *ngIf="accounts?.length">
                                {{accounts.length}}
                            </ng-container>
                            {{accounts?.length | i18nPlural: {
                            '=0' : translateStrings.owner.no,
                            '=1' : translateStrings.owner.singular,
                            'other': translateStrings.owner.plural
                            } }}
                        </div>

                    </div>

                    <!-- Main actions -->
                    <div class="flex items-center mt-4 sm:mt-0 md:mt-4">
                        <!-- Search -->
                        <div class="flex-auto">
                            <mat-form-field
                                class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                                <input matInput [formControl]="searchInputControl" [autocomplete]="'off'"
                                    [placeholder]="translateStrings.owner.search">
                            </mat-form-field>
                        </div>
                        <!-- Add contact button -->
                        <button class="ml-4" mat-flat-button [color]="'primary'"
                            (click)="this.selectedOwner = undefined; openModal()">
                            <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                            <span class="ml-2 mr-1">{{ translateStrings.owner.addNew }}</span>
                        </button>
                    </div>
                </div>
                <!-- Loader -->
                <div class="inset-x-0" *ngIf="isLoading()">
                    <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
                </div>

                <!-- list -->
                <div class="relative">
                    <ng-container *ngIf="(isLoading() || !isLoadingError); else loadingerror">
                        <ng-container *ngIf="filteredOrgAccountMatrix?.length; else noContacts">
                            <ng-container
                                *ngFor="let orgAccount of filteredOrgAccountMatrix; let i = index; trackBy: trackByFn">
                                <!-- Group -->
                                <div class="p-2">
                                    <div
                                        class="z-10 sticky top-0 -mt-px px-6 py-1 md:px-8 border-t border-b font-medium uppercase text-white bg-accent bg-opacity-50 dark:bg-gray-900">
                                        {{orgAccount.organization.name}}
                                    </div>
                                    <ng-container *ngFor="let account of orgAccount.accounts">

                                        <!-- Contact -->
                                        <a class="z-20 flex items-center px-6 py-2 md:px-8 cursor-pointer border-b justify-between hover:bg-accent hover:bg-opacity-10 dark:hover:bg-hover"
                                            (click)="selectedOwner = account; openModal()">
                                            <!-- [routerLink]="['../detail', account.personId]"> -->
                                            <div class="flex items-center">
                                                <div
                                                    class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                                                    <ng-container *ngIf="account.avatar">
                                                        <img class="object-cover w-full h-full" [src]="account.avatar"
                                                            alt="Contact avatar" />
                                                    </ng-container>
                                                    <ng-container *ngIf="!account.avatar">
                                                        <div
                                                            class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-accent bg-opacity-70 text-white dark:bg-gray-700 dark:text-gray-200">
                                                            {{account?.firstName?.charAt(0)}}
                                                        </div>
                                                    </ng-container>
                                                </div>
                                                <div class="min-w-0 ml-4 ">
                                                    <div class="font-medium leading-5 truncate">{{account.firstName}}
                                                        {{account.lastName}} <span
                                                            *ngIf="hasRole(account,'Beautycians-Stores-Owner',orgAccount.organization.organizationId)"
                                                            class="bg-red-100 text-red-800 text-xs font-medium mx-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">Owner</span>
                                                    </div>
                                                    <!-- <div class="leading-5 truncate text-secondary">
                                                        {{account.roles[0]?.hasRole[0]?.name.split("-")[2]}}</div> -->
                                                </div>
                                            </div>
                                            <button
                                                (click)="$event.stopPropagation();  $event.preventDefault(); selectedOwner = account;"
                                                mat-icon-button [matMenuTriggerFor]="menu" aria-label="Toggle menu">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">
                                                <!-- <button mat-menu-item (click)="openModal()" [routerLink]="['../detail', account.personId]">
                                        <mat-icon>edit</mat-icon>
                                        <span>Edit</span>
                                    </button> -->
                                                <button mat-menu-item (click)="impersonate()">
                                                    <mat-icon>supervised_user_circle</mat-icon>
                                                    <span>{{translateStrings.generic.impersonate}}</span>
                                                </button>
                                                <button mat-menu-item (click)="openModal()">
                                                    <mat-icon>edit</mat-icon>
                                                    <span>{{translateStrings.generic.edit}}</span>
                                                </button>
                                                <button mat-menu-item (click)="sendWelcomeHandler()">
                                                    <mat-icon>email</mat-icon>
                                                    <span>{{translateStrings.generic.sendWelcome}}</span>
                                                </button>
                                                <button mat-menu-item (click)="deleteHandler(account)"
                                                    class="text-red-400">
                                                    <mat-icon class="text-red-400">delete</mat-icon>
                                                    <span>{{translateStrings.generic.delete}}</span>
                                                </button>
                                            </mat-menu>
                                        </a>
                                    </ng-container>
                                </div>
                            </ng-container>

                            <!-- Load more -->
                            <div *ngIf="!reachedLimitSubject.value" class="mx-auto my-12 items-center w-64">
                                <button class="w-64" mat-flat-button color="accent"
                                    [disabled]="isSpecificLoading['loadMore']" #loadMoreButton>
                                    <span *ngIf="!isSpecificLoading['loadMore']" class="ml-2 mr-1">{{
                                        translateStrings.generic.loadMore}}</span>
                                    <mat-progress-spinner *ngIf="isSpecificLoading['loadMore']" [diameter]="24"
                                        [mode]="'indeterminate'"></mat-progress-spinner>
                                </button>
                                <div class="w-1 mx-auto">
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <!-- No contacts -->
                    <ng-template #noContacts>
                        <div *ngIf="!isLoading()"
                            class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">
                            {{translateStrings.owner.noSearchResults}}</div>
                    </ng-template>

                </div>
            </div>

        </mat-drawer-content>
    </mat-drawer-container>
</div>


<!-- Main modal -->
<ng-template #accountModal>
    <h3 mat-dialog-title>{{ selectedOwner ? translateStrings.owner.editAdmin :
        translateStrings.owner.addNew }}</h3>
    <mat-dialog-content>
        <form [formGroup]="accountFormGroup" (ngSubmit)="accountFormSubmit($event.submitter.id)" #accountForm="ngForm">
            <div class="flex flex-col gt-xs:flex-row p-7">

                <!-- <mat-tab-group (selectedIndexChange)="selectedAdminTab.setValue($event)"
                    [selectedIndex]="selectedOwner ? 1 : 0">
                    <mat-tab label="{{translateStrings.owner.orgNew}}" class="w-full" [disabled]="selectedOwner">
                        <mat-form-field class="flex-auto gt-xs:pr-3 w-full mt-6">
                            <mat-label>{{translateStrings.owner.accountName}}</mat-label>
                            <input matInput formControlName="accountName" #accountName (input)="accountList.value=null">
                            <mat-icon class="icon-size-5" matSuffix
                                [svgIcon]="'heroicons_solid:office-building'"></mat-icon>
                        </mat-form-field>
                  
                    </mat-tab>
                    <mat-tab label="{{translateStrings.owner.orgExisting}}" class="w-full">
                        ### Here place the account selection form field ###
                    </mat-tab>
                </mat-tab-group> -->

                <mat-form-field class="flex-auto gt-xs:pl-3 w-full">
                    <mat-label>{{translateStrings.owner.orgName}}</mat-label>
                    <!-- <mat-select [formControlName]="'accountList'" [disabled]="selectedOwner" #accountList
                        (selectionChange)="accountName.value=''"> -->
                        <mat-select [formControlName]="'accountList'" [disabled]="selectedOwner" #accountList>
                        <ng-container *ngFor="let org of orgList">
                            <mat-option [value]="org.organizationId">{{org.name}}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-icon class="icon-size-5" matSuffix
                        [svgIcon]="'heroicons_outline:office-building'"></mat-icon>
                </mat-form-field>

                <!-- <mat-form-field class="fuse-mat-textarea flex-auto">
                    <mat-label>{{translateStrings.owner.accountAddress}}</mat-label>
                    <input matInput [rows]="3" formControlName="accountAddress">
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:location-marker'"></mat-icon>
                    </mat-form-field> -->

                <div class="flex items-center">
                    <div class="flex-auto mt-px border-t"></div>
                    <!-- <div class="mx-2 text-secondary">{{(selectedAdminTab.value==0) ?
                        translateStrings.owner.accountAdminData : translateStrings.owner.accountManagerData}}</div> -->
                        <div class="mx-2 text-secondary">{{translateStrings.owner.accountData}}</div>
                        <div class="flex-auto mt-px border-t"></div>
                </div>

                <input matInput formControlName="accountOrganizationId" hidden="true">

                <mat-form-field class="flex-auto gt-xs:pr-3">
                    <mat-label>{{translateStrings.user.firstName}}</mat-label>
                    <input matInput formControlName="firstName">
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                </mat-form-field>

                <mat-form-field class="flex-auto gt-xs:pl-3">
                    <mat-label>{{translateStrings.user.lastName}}</mat-label>
                    <input matInput formControlName="lastName">
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                </mat-form-field>

                <mat-form-field class="flex-auto gt-xs:pl-3">
                    <mat-label>{{translateStrings.user.email}}</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                </mat-form-field>

                <mat-form-field class="flex-auto gt-xs:pr-3">
                    <mat-label>{{translateStrings.owner.defaultpassword}}</mat-label>
                    <input matInput type="password" formControlName="defaultpassword" #defaultpassword>
                    <button mat-icon-button type="button"
                        (click)="defaultpassword.type === 'password' ? defaultpassword.type = 'text' : defaultpassword.type = 'password'"
                        matSuffix>
                        <mat-icon class="icon-size-5" *ngIf="defaultpassword.type === 'password'"
                            [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                        <mat-icon class="icon-size-5" *ngIf="defaultpassword.type === 'text'"
                            [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
                    </button>
                    <mat-icon *ngIf="accountFormGroup.get('defaultpassword').hasError('pattern') && !accountFormGroup.get('defaultpassword').hasError('minlength')" class="icon-size-5 text-red-500" [svgIcon]="'mat_solid:info'" [matTooltip]="translateStrings.generic.upperLowerNumeric"></mat-icon>
                    <mat-icon *ngIf="accountFormGroup.get('defaultpassword').hasError('minlength')" class="icon-size-5 text-red-500" [svgIcon]="'mat_solid:info'" [matTooltip]="translateStrings.generic.passwordLength"></mat-icon>
                </mat-form-field>

            </div>

            <!-- Modal footer -->
            <div
                class="flex justify-between items-center py-6 mx-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                <div>
                    <button mat-button mat-dialog-close type="button"
                        class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">{{translateStrings.generic.close}}</button>
                </div>

                <div class="flex space-x-2">
                    <button id="save" type="submit" mat-flat-button [color]="'primary'" class="rounded-lg"
                        [disabled]="!accountFormGroup.valid">
                        <span *ngIf="!isSpecificLoading['save']">
                            {{translateStrings.generic.save}}
                        </span>
                        <mat-progress-spinner *ngIf="isSpecificLoading['save']" [diameter]="24"
                            [mode]="'indeterminate'"></mat-progress-spinner>
                    </button>
                    <button *ngIf="!selectedOwner" id="saveAndSendWelcome" type="submit" mat-flat-button
                        [color]="'primary'" class="rounded-lg" [disabled]="!accountFormGroup.valid">
                        <span *ngIf="!isSpecificLoading['saveAndSendWelcome']">
                            {{translateStrings.generic.saveAndSendWelcome}}
                        </span>
                        <mat-progress-spinner *ngIf="isSpecificLoading['saveAndSendWelcome']" [diameter]="24"
                            [mode]="'indeterminate'"></mat-progress-spinner>
                    </button>
                    <button id="delete">

                    </button>
                </div>
            </div>

        </form>
    </mat-dialog-content>
</ng-template>

<ng-template #loadingerror>
    <img src="/assets/images/ui/beautycians/error_503.jpg" alt="Error" class="w-2/6 h-1/6 mx-auto">
    <div class="text-2xl font-semibold tracking-tight text-center w-4/6 sm:w-full mx-auto">
        {{translateStrings.generic.error503}}</div>
</ng-template>