import { GruulsLocalStorageService } from "@gruuls-core/infrastructure/gruuls-local-storage-service";
import { BehaviorSubject, Observable } from "rxjs";
const LOCAL_ORDER_NAME = 'currentOrder';

export class CartService {
  // decided to duplicate this as an Angular service in order to use it as an Injectable

  cart: {} = {};
  updates: BehaviorSubject<any> = new BehaviorSubject<any>(this.cart);
  private _gruulsLocalStorage: GruulsLocalStorageService; // TODO: verify if Browser Cache could be useful here

  constructor(
  ) {
    this._gruulsLocalStorage = new GruulsLocalStorageService();
    this.cart = JSON.parse(this._gruulsLocalStorage.getItem(LOCAL_ORDER_NAME)) ?? {};
    this.updates.next(this.cart);
  }
  
  public setCart(cart: {}): {} {
    this.cart = cart;
    this._gruulsLocalStorage.setItem(LOCAL_ORDER_NAME, JSON.stringify(Object.assign({}, this.cart)));
    this.updates.next(this.cart);
    return this.cart;
  }
  
  public addProductToCart(productId: string): {} {
    if (productId in this.cart) {
      this.cart[productId] += 1;
    } else {
      this.cart[productId] = 1;
    }
    this._gruulsLocalStorage.setItem(LOCAL_ORDER_NAME, JSON.stringify(Object.assign({}, this.cart)));
    this.updates.next(this.cart);
    return this.cart;
  }

  public setProductToCart(productId: string, quantity: number): {} {
    if (quantity <= 0) {
      delete this.cart[productId];
    }
    this.cart[productId] = quantity;
    this._gruulsLocalStorage.setItem(LOCAL_ORDER_NAME, JSON.stringify(Object.assign({}, this.cart)));
    this.updates.next(this.cart);
    return this.cart
  }

  public removeProductFromCart(productId: string): {} {
      if (productId in this.cart) {
        this.cart[productId] -= 1;
          if (this.cart[productId] <= 0) {
              delete this.cart[productId];
          }
      }
      this._gruulsLocalStorage.setItem(LOCAL_ORDER_NAME, JSON.stringify(this.cart));
      this.updates.next(this.cart);
      return this.cart;
  }

  public resetCart(): {} {
    this._gruulsLocalStorage.removeItem(LOCAL_ORDER_NAME);
    this.cart = [];
    this.updates.next(this.cart);
    return this.cart;
  }

  public getCurrentCart(): {} {
    return this.cart;
  }

  public getCart(): Observable<any> {
    return this.updates;
  }

}