import { BehaviorSubject, Observable, map, of, switchMap, take, tap } from "rxjs";
import { Cabin } from "../utils/dataTypes";
import { ApiCaller } from "../utils/apiCaller";
import { GruulsAngularHttpProxyService } from "@gruuls-fe/services/gruuls-angular-http-proxy.service";
import { GruulsAuthService } from "@gruuls-fe/services/gruuls-auth.service";
import { Utils } from "@gruuls-core/utils/Utils";

export class CabinService {
    // decided to duplicate this as an Angular service in order to use it as an Injectable
    private _cabins: BehaviorSubject<Cabin[] | null> = new BehaviorSubject(null);
    private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);


    constructor(
        private _httpClient: GruulsAngularHttpProxyService,
        private _authService: GruulsAuthService
    ) {

    }

    get cabins$(): Observable<Cabin[]> {
        return this._cabins.asObservable();
    }

    getCabinsByStoreId(storeId: string): Observable<Cabin[]> {
        if (this._authService && this._authService.isAuthenticated()) {
            return this._apiCaller.getCabins({ store: storeId}).pipe(
                tap((cabins) => {
                    this._cabins.next(Utils.sortByKeys(cabins, ['name']));
                })
            );
        } else {
            this._cabins.next([]);
            return of([]);
        }
    }

    createCabin(cabin: Cabin): Observable<Cabin> {
        // cabin.organization.organizationId = this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId;
        return this.cabins$.pipe(
            take(1),
            switchMap(cabins => this._apiCaller.createCabin(cabin).pipe(
                map((newCabin: Cabin) => {

                    // Update the products with the new product
                    this._cabins.next([newCabin, ...cabins]);

                    // Return the new product
                    return newCabin;
                })
            ))
        );
    }


    updateCabin(cabin: Cabin): Observable<Cabin> {
        if (!cabin.cabinId) {
            throw new Error('Store id is required');
        }

        return this.cabins$.pipe(
            take(1),
            switchMap(cabins => this._apiCaller.updateCabin(cabin).pipe(
                map((updatedCabin) => {

                    // Find the index of the updated client
                    const index = cabins.findIndex(item => item.cabinId === cabin.cabinId);
                    cabins[index] = updatedCabin;
                    this._cabins.next(cabins);

                    // Return the updated client
                    return updatedCabin;
                }),
            )
            ))
    }

    deleteCabin(cabinId: string): Observable<boolean> {
        return this.cabins$.pipe(
            take(1),
            switchMap(cabins => this._apiCaller.deleteCabin(cabinId).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted client
                    const index = cabins.findIndex(item => item.cabinId === cabinId);

                    // Delete the client
                    cabins.splice(index, 1);

                    // Update the clients
                    this._cabins.next(cabins);

                    // Return the deleted status
                    return isDeleted;
                })
            ))
        );
    }
}