import { GruulsMockDomain, GruulsMockHttpRequest } from '../../gruuls-mock-domain';
import { HttpClient } from '@angular/common/http';

export class BeautyRoleTemplatesMock extends GruulsMockDomain {

  constructor(httpClient: HttpClient) {
    super(httpClient);

    this.entities = this.ROLE_TEMPLATES;
  }

  ROLE_TEMPLATES =
    [
      {
        uuid: 'f987f01d-95ed-42d5-bc0b-98609a6acd43',
        name: 'Beautycians-HQ',
        permissions: [
          {
            domain: "products",
            allow: ['VIEW', 'CREATE', 'EDIT', 'DELETE'],
          }
        ]
      },
      {
        uuid: 'e4b4854f-5768-48cf-a80e-01fc0526754c',
        name: 'Beautycians-Store-Operator',
      },
      {
        uuid: 'f5457ad1-a575-4147-8a9e-386b8b24f208',
        name: 'Beautycians-Store-Manager',
      },
      {
        uuid: 'a2d854a1-c1c1-4343-a8c4-91f8bc7ba1a1',
        name: 'Beautycians-Stores-Owner',
      },
      {
        uuid: 'c379f678-6150-4ffb-b1d6-5a1087cba729',
        name: 'Beautycians-Student',
      },
      {
        uuid: '420ff737-2cbf-49e6-b49e-1560dd4dcae7',
        name: 'Beautycians-Student-Intermediate',
      },
      {
        uuid: '420ff737-',
        name: 'Beautycians-Student-Advanced',
      },
      {
        uuid: 'b90f7f14-8d76-413c-878f-89ba0c1a980c',
        name: 'Beautycians-HQ-Sales',
      }
    ];
}
