import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, catchError, Observable, Subject, of, tap } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { GruulsAngularHttpProxyService } from '../../../../../@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { GruulsAngularTranslateService } from '../../../../../@gruuls-fe/services/gruuls-angular-translate.service';
import { FuseConfigService } from '@fuse/services/config';
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { GruulsAuthService } from '@gruuls-fe/services/gruuls-auth.service';

@Component({
    selector: 'appointment-confirmation',
    templateUrl: './appointment-confirmation.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentConfirmationComponent implements OnInit, OnDestroy {

    appointmentId: string;
    contact: any;
    newSurvey: any = [];
    translateStrings: any = { generic: {}, appointment: {} };
    seeyou: string = '';
    
    sent: boolean = false;
    specificLoading: boolean[] = [];
    isLoadingError: boolean = false;
    isLoading: boolean = false;
    action: 'confirm' | 'cancel';
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);


    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private route: ActivatedRoute,
        private _fuseConfirmationService: FuseConfirmationService,
        private _httpClient: GruulsAngularHttpProxyService,
        private _fuseConfigService: FuseConfigService,
        private _translate: GruulsAngularTranslateService,
        private _authService: GruulsAuthService,
    ) {
    }

    @HostBinding('class') get classList(): any {
        return {
            'w-full': true,
            'bg-primary-50': true
        };
    }

    anamnesiFormGroup: any;

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._activatedRoute.data.subscribe(data => {
            this.action = data.action as 'confirm' | 'cancel';
        })
  

        this.isLoading = true;

        this._fuseConfigService.config = { appConfig: { layout: 'empty' } };

        const genericTranslations = ['send', 'close', 'cancel', 'error', 'save', 'edit', 'download', 'view', 'clone', 'reset', 'noSession', 'thanks', 'error503'];
        genericTranslations.forEach((translation) => {
            this.translateStrings['generic'][translation] = this._translate.translate('generic.' + translation);
        });

        const appointmentTranslations = ['thanksForConfirming', 'seeyouTomorrow', 'seeyouLater', 'dontseeyou', 'thanksForCancelling'];
        appointmentTranslations.forEach((translation) => {
            this.translateStrings['appointment'][translation] = this._translate.translate('appointment.' + translation);
        });

        this._activatedRoute.params.pipe(
            takeUntil(this._unsubscribeAll),
            switchMap((params) => {
                this.appointmentId = params.id;
                return this.action == 'confirm' ? this.confirmAppointment(params.id) : this.cancelAppointment(params.id);
            })
        ).subscribe({
            next: () => {
                this.isLoading = false;
                this._changeDetectorRef.markForCheck();
            },
            error: (err) => {
                console.error("Error while loading: " + err);
                this.isLoading = false;
                this.isLoadingError = true;
                this._changeDetectorRef.markForCheck();
            }
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    confirmAppointment(appointmentId: string): Observable<any> {
        return this._apiCaller.confirmAppointment(appointmentId,'confirm');
    }

    cancelAppointment(appointmentId: string): Observable<any> {
        return this._apiCaller.confirmAppointment(appointmentId,'cancel');
    }

}