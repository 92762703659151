<ng-container *ngIf="isLoadingError">
  <div class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
    <span class="font-medium">{{translateStrings.generic.somethingWentWrong}}:</span>
    {{translateStrings.generic.tryToReload}}
  </div>
</ng-container>

<div>{{events.length}} eventi</div>
<full-calendar [defaultView]="defaultView" [eventColor]="defaultEventColor" [firstDay]="settings.startWeekOn"
  [handleWindowResize]="true" [header]="header" [height]="'parent'" [plugins]="calendarPlugins" [views]="views"
  (eventDrop)="onEventDrop($event)" (eventResize)="onEventResize($event)" (select)="onDateSelect($event)"
  (eventClick)="onEventClick($event)" (eventRender)="onEventRender($event)" [schedulerLicenseKey]="lic"
  [resources]="getResources()" datesAboveResources="true" #fullCalendar></full-calendar>

<!-- Event panel -->
<ng-template #eventPanel>

  <!-- Preview mode -->
  <ng-container *ngIf="panelMode === 'view'">
    <div class="flex-auto px-6 py-8">
      <!-- Info -->
      <div class="flex">
        <mat-icon [svgIcon]="'local_florist'"
          [ngStyle]="{'color': getCalendar(eventForm.get('calendarId')?.value)?.color}"></mat-icon>
        <div class="flex flex-auto justify-between ml-4">
          <!-- Info -->
          <div>
            <div class="text-3xl font-semibold tracking-tight leading-none mb-3">{{event.title || '(No title)'}}</div>
            <!-- <div class="mt-0.5">Store is: {{ event.extendedProps?.storeId }} and cabin is: {{
              event.extendedProps?.cabinId }}</div> -->
            <div class="mt-0.5">Status is: {{ event.extendedProps?.status }}</div>
            <div class="mt-0.5">{{event.start | date:'d MMMM hh:mm' }} <span *ngIf="event.durationInMin"
                class="text-accent-500 font-semibold ml-2">{{event.durationInMin}}min.</span></div>
            <div class="text-secondary">{{recurrenceStatus}}</div>
          </div>
          <!-- Actions -->
          <div class="flex -mt-2 -mr-2 ml-10">

            <!-- Non-recurring event -->
            <ng-container *ngIf="!event.recurrence">
              <!-- Edit -->
              <button mat-icon-button (click)="changeEventPanelMode('edit', 'single')">
                <mat-icon [svgIcon]="'heroicons_outline:pencil-alt'"></mat-icon>
              </button>
              <!-- Delete -->
              <button mat-icon-button (click)="deleteEvent(event)">
                <mat-icon [svgIcon]="'heroicons_outline:trash'"></mat-icon>
              </button>
              <mat-icon *ngIf="appointment?.status=='NOTIFIED'" svgIcon="done" class="my-2"></mat-icon>
              <mat-icon *ngIf="appointment?.status=='CONFIRMED'" svgIcon="done_all"
                class="text-green-600 my-2"></mat-icon>
              <mat-icon *ngIf="appointment?.status=='CANCELED'" svgIcon="remove_done"
                class="text-red-600 my-2"></mat-icon>
            </ng-container>

          </div>
        </div>
      </div>

      <!-- Calendar -->
      <div class="flex mt-1">
        <div class="flex flex-auto items-center ml-10">
          {{translateStrings.treatment.singular}}: <span
            class="font-semibold ml-2">{{getCalendar(event.calendarId)?.title}}</span>
        </div>
      </div>

      <!-- Description -->
      <div class="flex mt-3" *ngIf="event.description">
        <div class="flex-auto ml-10 text-secondary">{{event.description}}</div>
      </div>

    </div>
  </ng-container>

  <!-- Add / Edit mode -->
  <ng-container *ngIf="panelMode === 'add' || panelMode === 'edit'">
    <form class="flex flex-col w-full px-6 pt-8 sm:pt-10" [formGroup]="eventForm">

      <!-- Title -->
      <!-- <div class="flex items-center">
        <mat-form-field class="fuse-mat-no-subscript flex-auto">
          <input matInput [formControlName]="'title'" [placeholder]="translateStrings.appointment.reference">
        </mat-form-field>
      </div> -->

      <!-- Person -->
      <div class="flex items-center">
        <div *ngIf="defaultPersonId" class="text-xl font-semibold">
          {{clientControl.value?.firstName}} {{clientControl.value?.lastName}}
        </div>
        <mat-form-field class="fuse-mat-no-subscript flex-auto" [ngClass]="defaultPersonId ? 'hidden' : ''">
          <input type="text" matInput [formControl]="clientControl" [matAutocomplete]="auto"
            [placeholder]="translateStrings.appointment.reference">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{option.firstName}} {{option.lastName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <!-- <mat-form-field class="hidden fuse-mat-no-subscript w-full">
        <input matInput [formControlName]="'appointmentId'">
      </mat-form-field> -->
      <md-input-container formGroupName="extendedProps">
        <mat-form-field class="hidden">
          <input matInput formControlName="clientId">
        </mat-form-field>
      </md-input-container>

      <md-input-container formGroupName="extendedProps">
        <mat-form-field class="hidden">
          <input matInput formControlName="storeId">
        </mat-form-field>
      </md-input-container>

      <md-input-container formGroupName="extendedProps">
        <mat-form-field class="hidden">
          <input matInput formControlName="status">
        </mat-form-field>
      </md-input-container>

      <!-- Dates -->
      <div class="flex items-start mt-6">
        <!-- <mat-icon class="hidden sm:inline-flex mt-3 mr-6" [svgIcon]="'heroicons_outline:calendar'"></mat-icon> -->
        <div class="flex-auto">
          <mat-form-field class="hidden fuse-mat-no-subscript">
            <input matInput [formControlName]="'start'">
          </mat-form-field>
          <mat-form-field class="hidden fuse-mat-no-subscript">
            <input matInput [formControlName]="'durationInMin'">
          </mat-form-field>
          <mat-form-field class="hidden fuse-mat-no-subscript">
            <input matInput [formControlName]="'end'">
          </mat-form-field>
          <div class="mt-0.5">{{event.start | date:'d MMMM HH:mm' }} <span *ngIf="event.durationInMin"
              class="text-accent-500 font-semibold ml-2">{{event.durationInMin}}min.</span></div>
        </div>

        <!-- TODO: Cabin selection -->
        <div>
          <ng-container *ngFor="let cabin of cabins">
            <mat-icon *ngIf="event.extendedProps.cabinId==cabin.cabinId; else actionable" svgIcon="mat_solid:door_front"
              class="text-primary-500 mx-2" [matTooltip]="cabin.name"></mat-icon>
            <ng-template #actionable>
              <button mat-icon-button [color]="'primary'" [matTooltip]="cabin.name"
                (click)="setEventCabin(cabin.cabinId)">
                <mat-icon svgIcon="mat_outline:door_front"></mat-icon>
              </button>
            </ng-template>
          </ng-container>
        </div>
      </div>

      <div class="mt-6 flex items-center -space-x-1.5 ">
        <!-- <div class="cursor-pointer h-8 w-8 flex-0 rounded-full bg-primary-50 hover:bg-primary items-center text-white font-bold hover:ring-8 hover:ring-blue-500 flex justify-center align-center"><img class="object-cover rounded-full" src="assets/images/avatars/female-01.jpg"></div> -->
        <ng-container *ngFor="let operator of operators">
          <div *ngIf="operator.personId && event.extendedProps.operatorId==operator.personId; else actionableOperator" matTooltip="{{operator.firstName}} {{operator.lastName}}"
            class="cursor-pointer h-10 w-10 flex-0 rounded-full object-cover bg-primary items-center text-white font-bold flex justify-center align-center">
            {{operator.firstName.charAt(0)}}{{operator.lastName.charAt(0)}}</div>
          <ng-template #actionableOperator>
            <button (click)="setEventOperator(operator.personId)">
              <div matTooltip="{{operator.firstName}} {{operator.lastName}}"
                class="cursor-pointer h-10 w-10 flex-0 rounded-full object-cover bg-primary-50 hover:bg-primary items-center text-white font-bold flex justify-center align-center">
                {{operator.firstName.charAt(0)}}{{operator.lastName.charAt(0)}}</div>
            </button>
          </ng-template>
        </ng-container>
      </div>

      <!-- Calendar -->
      <div class="flex items-center mt-6">
        <mat-icon class="hidden sm:inline-flex mr-6" [svgIcon]="'local_florist'"></mat-icon>
        <mat-form-field class="fuse-mat-no-subscript flex-auto">
          <mat-select [formControlName]="'calendarId'" (change)="$event.stopImmediatePropagation()">
            <mat-select-trigger class="inline-flex items-center leading-none">
              <span class="w-3 h-3 rounded-full"
                [ngStyle]="{'background-color': getCalendar(eventForm.get('calendarId')?.value)?.color}"></span>
              <span class="ml-3">{{getCalendar(eventForm.get('calendarId')?.value)?.title}}</span>
            </mat-select-trigger>
            <ng-container *ngFor="let calendar of calendars">
              <mat-option [value]="calendar?.id">
                <div class="inline-flex items-center">
                  <span class="w-3 h-3 rounded-full" [ngStyle]="{'background-color': calendar.color}"></span>
                  <span class="ml-3">{{calendar.title}}</span>
                </div>
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Description -->
      <div class="flex items-start mt-6">
        <mat-icon class="hidden sm:inline-flex mr-6 mt-3" [svgIcon]="'heroicons_outline:menu-alt-2'"></mat-icon>
        <mat-form-field class="fuse-mat-textarea fuse-mat-no-subscript flex-auto">
          <textarea matInput matTextareaAutosize [matAutosizeMinRows]="1" [formControlName]="'description'"
            [placeholder]="translateStrings.appointment.note"></textarea>
        </mat-form-field>
      </div>

      <!-- Actions -->
      <div class="flex items-center mt-10 -mx-6 sm:-mx-6 py-4 px-6 border-t bg-gray-50 dark:bg-transparent">
        <button class="add" mat-flat-button type="button" [color]="warn" (click)="closeEventPanel()">
          {{translateStrings.generic.cancel}}
        </button>

        <button class="ml-auto add" *ngIf="panelMode === 'add'" mat-flat-button type="button" [color]="'primary'"
          [disabled]="!eventForm.valid" (click)="addEvent()">
          <span *ngIf="!isSpecificLoading['saveEvent']">{{translateStrings.appointment.add}}</span>
          <mat-progress-spinner *ngIf="isSpecificLoading['saveEvent']" [diameter]="24"
            [mode]="'indeterminate'"></mat-progress-spinner>
        </button>
        <button class="ml-auto save" *ngIf="panelMode === 'edit'" mat-flat-button type="button" [color]="'primary'"
          [disabled]="!eventForm.valid" (click)="updateEvent()">
          <span *ngIf="!isSpecificLoading['saveEvent']">{{translateStrings.generic.save}}</span>
          <mat-progress-spinner *ngIf="isSpecificLoading['saveEvent']" [diameter]="24"
            [mode]="'indeterminate'"></mat-progress-spinner>
        </button>
      </div>
    </form>
  </ng-container>

</ng-template>